import React, { Component, Fragment } from 'react';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/NumberFormat';
import Typography from '@material-ui/core/Typography/Typography';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { withStyles, fade } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
  chipPrice: {
    marginTop: 10,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '6px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
    position: 'relative',
    cursor: 'pointer',
    border: '2px solid #fff',
  },
  productName: {
    marginBottom: 7,
  },
  label: {
    width: 100,
    display: 'inline-block',
  },
  selected: {
    display: 'block',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: fade(theme.palette.primary.main, 0.2),
    border: `2px dashed ${theme.palette.primary.light}`,
  },
  checkCircleIcon: {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '50%',
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
});

class OrderTable extends Component {
  render() {
    const { items, classes, handleListItemSelect } = this.props;

    return (
      <Fragment>
        <List className={classes.list}>
          {items.map((item, index) => {
            return (
              <ListItem
                onClick={() => handleListItemSelect(index)}
                className={item.selected ? classes.selected : classes.listItem}
                key={item.id}
              >
                {item.selected && (
                  <CheckCircleIcon
                    className={classes.checkCircleIcon}
                    color="primary"
                  />
                )}
                <Typography className={classes.productName} variant="h6">
                  {item.productId} - {item.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <span className={classes.label}>Quantidade:</span>
                  {item.quantity}
                  {item.unit} ({numberFormat(item.secondQuantity, 2)}
                  {item.secondUnit})
                </Typography>
                {item.discount > 0 && (
                  <Typography variant="body2" color="textSecondary">
                    <span className={classes.label}>Desconto:</span>
                    {percentFormat(parseFloat(item.discount))}
                  </Typography>
                )}
                <Typography variant="body2" color="textSecondary">
                  <span className={classes.label}>Preço:</span>
                  {moneyFormat(item.price)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <span className={classes.label}>Comissão:</span>
                  {moneyFormat(item.vlCommission)} (
                  {percentFormat(item.commission)})
                </Typography>
                <Typography>
                  <span className={classes.label}>Total:</span>
                  <strong>{moneyFormat(item.total)}</strong>
                </Typography>
                <div className={classes.action} />
              </ListItem>
            );
          })}
        </List>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrderTable);
