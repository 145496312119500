import React, { Component, Fragment } from 'react';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MyAccountBasic from 'components/account/MyAccountBasic';
import MyAccountPassword from 'components/account/MyAccountPassword';
import SearchLoading from 'components/loading/SearchLoading';
import UserController from 'services/user/UserController';
import { withSnackbarContext } from 'components/messages/MySnackBar';
import { MyAccountAction } from './MyAccountAction';
import { compose } from 'recompose';
import { MyAccountTabs } from './MyAccountTabs';
import { withUserData } from '../user/UserData';

const styles = theme => ({
  paper: {
    width: '100%',
    margin: '0 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0px',
    },
  },
  tabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
  },
  appbar: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
});

let invalidPassword = false;

class MyAccount extends Component {
  state = {
    username: '',
    email: '',
    message: '',
    messageType: '',
    isSnackbarOpen: false,
    currentEmail: '',
    loading: false,
    tabvalue: 0,
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  handleCloseSnackbar = () => {
    this.setState({
      isSnackbarOpen: !this.state.isSnackbarOpen,
    });
  };

  handleSubmitUpdatePassword = async event => {
    if (this.state.loading) return false;

    event.preventDefault();

    this.setState({
      loading: true,
    });

    const response = await UserController.prototype.updatePassword(this.state);

    if (response.type === 'SUCCESS') {
      this.setState({
        message: 'Senha atualizada',
        messageType: response.type,
        isSnackbarOpen: true,
        loading: false,
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      });
    } else if (response.type === 'ERROR') {
      this.setState({
        message: response.message,
        messageType: response.type,
        isSnackbarOpen: true,
        loading: false,
      });
    }
  };

  handleSubmitUpdateEmail = async event => {
    event.preventDefault();

    if (this.state.loading) return false;

    this.setState({
      loading: true,
    });

    const response = await UserController.prototype.updateEmail(this.state);
    const { mySnackbarContext } = this.props;

    if (response.type === 'SUCCESS') {
      this.setState({
        loading: false,
      });

      mySnackbarContext.handleOpen('E-mail atualizado', response.type);

      localStorage.removeItem('token');
      const { history } = this.props;
      history.push('/login');
    } else if (response.type === 'ERROR') {
      this.setState({
        loading: false,
      });
      mySnackbarContext.handleOpen(response.type, response.type);
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleTabChange = (event, value) => {
    this.setState({
      tabvalue: value,
    });
  };

  componentDidMount() {
    const { userData } = this.props;
    this.setState({
      username: userData.username,
      currentEmail: userData.email.toLowerCase(),
      email: userData.email.toLowerCase(),
    });
  }

  render() {
    const { classes } = this.props;
    const { loading, newPassword, newPasswordConfirm, tabvalue } = this.state;

    invalidPassword =
      newPassword !== newPasswordConfirm &&
      (newPassword !== '' && newPasswordConfirm !== '');

    return (
      <Fragment>
        {loading && <SearchLoading />}
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar
              title={'Minha Conta'}
              appContext={context}
              ActionComponents={
                <MyAccountAction
                  handleSubmitUpdatePassword={this.handleSubmitUpdatePassword}
                  handleSubmitUpdateEmail={this.handleSubmitUpdateEmail}
                  invalidPassword={invalidPassword}
                  {...this.state}
                />
              }
              Tabs={
                <MyAccountTabs
                  tabvalue={tabvalue}
                  handleTabChange={this.handleTabChange}
                />
              }
            />
          )}
        </AppContextConsumer>
        <Grid container spacing={0}>
          <div className={classes.paper}>
            <div className={classes.content}>
              {this.state.tabvalue === 0 && (
                <MyAccountBasic
                  handleSubmitUpdateEmail={this.handleSubmitUpdateEmail}
                  handleChange={this.handleChange}
                  {...this.state}
                />
              )}
              {this.state.tabvalue === 1 && (
                <MyAccountPassword
                  invalidPassword={invalidPassword}
                  handleSubmitUpdatePassword={this.handleSubmitUpdatePassword}
                  handleChange={this.handleChange}
                  {...this.state}
                />
              )}
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withSnackbarContext,
  withUserData
)(MyAccount);
