import React, { Fragment } from 'react';
import VendasFilter from 'components/vendas/VendasFilter';
import TableNoData from 'components/template/table/TableNoData';
import VendasTableLoading from 'components/vendas/VendasTableLoading.js';
import VendasApi from 'services/VendasApi';
import VendasTable from 'components/vendas/VendasTable';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MyAppBar from 'components/template/appbar/MyAppBar';
import { AppContextConsumer } from 'App';
import { withRouter } from 'react-router-dom';
import SimpleModal from 'components/template/modal/SimpleModal';
import Filter from 'components/filter/Filter';
import { makeCancelable } from 'helpers/PromiseCancelable';
import { dateFormat } from 'helpers/DateFormat';
import { VendasAction } from './VendasAction';
import { compose } from 'recompose';
import { withSnackbarContext } from '../messages/MySnackBar';
import { VendasFilterAction } from './VendasFilterAction';
import VendasTotal from './VendasTotal';

const styles = theme => ({
  container: {
    padding: '0 15px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
});

let ordersRequest;

class Vendas extends React.Component {
  constructor(props) {
    super(props);

    document.title = 'Vendas';

    const date = new Date();
    const today = date.getDay();

    const toSaturday = 6 - today;
    const toMonday = today - 1;

    let initialDate = new Date(),
      finalDate = new Date();

    finalDate.setDate(finalDate.getDate() + toSaturday);
    initialDate.setDate(initialDate.getDate() - toMonday);

    let filter;

    if (localStorage.getItem('filter'))
      filter = JSON.parse(localStorage.getItem('filter'));
    else
      filter = [
        {
          name: 'dataInicial',
          value: initialDate,
          text: dateFormat(initialDate),
          type: 'date',
        },
        {
          name: 'dataFinal',
          value: finalDate,
          text: dateFormat(finalDate),
          type: 'date',
        },
        { name: 'situacao', value: 'T', text: '', type: 'normal' },
        { name: 'search', value: '', text: '', type: 'normal' },
      ];

    this.state = {
      loading: false,
      loaded: false,
      openModalSearch: false,
      data: [],
      filter: filter,
      showTotal: false,
    };
  }

  handleQuantityChange = async qtd => {
    await this.setState({
      quantity: qtd,
    });

    await this.getData();
  };

  handleInitialDate = initialDate => {
    let filter = this.state.filter;
    filter[0].value = initialDate;
    filter[0].text = dateFormat(initialDate);
    this.setState({
      filter: filter,
    });

    this.savefilterAtLocalStorage();
  };

  handleFinalDate = finalDate => {
    let filter = this.state.filter;
    filter[1].value = finalDate;
    filter[1].text = dateFormat(finalDate);
    this.setState({
      filter: filter,
    });

    this.savefilterAtLocalStorage();
  };

  handleFilterChange = type => async e => {
    let newfilter = this.state.filter.map(item => {
      let newItem = item;

      if (item.name === e.target.name) {
        newItem.value = e.target.value;
        if (type === 'select') {
          newItem.text = e.currentTarget.textContent;
        } else if (type === 'input') newItem.text = e.target.value;
      }

      return newItem;
    });

    await this.setState({ filter: newfilter });

    this.savefilterAtLocalStorage();
  };

  clearSelectedFilter = async name => {
    let newfilter = this.state.filter.map(item => {
      var newItem = item;

      if (item.name === name) {
        if (item.type === 'date') {
          newItem.value = null;
        } else if (item.name === 'situacao') newItem.value = 'T';
        else newItem.value = '';

        newItem.text = '';
      }

      return newItem;
    });

    await this.setState({ filter: newfilter });
    this.savefilterAtLocalStorage();
    await this.getData();
  };

  hasFilter = () => {
    let hasfilter = false;
    this.state.filter.forEach(item => {
      if (item.text !== '') hasfilter = true;
    });

    return hasfilter;
  };

  setInitialStateFilterValue = async () => {
    await this.setState({
      filter: [
        { name: 'dataInicial', value: null, text: '', type: 'date' },
        { name: 'dataFinal', value: null, text: '', type: 'date' },
        { name: 'situacao', value: 'T', text: '', type: 'normal' },
        { name: 'search', value: '', text: '', type: 'normal' },
      ],
    });

    this.savefilterAtLocalStorage();

    await this.getData();
  };

  savefilterAtLocalStorage = () => {
    localStorage.setItem('filter', JSON.stringify(this.state.filter));
  };

  prepareFilterToRequest = () => {
    return this.state.filter.map(item => {
      if (item.type === 'date') return { name: item.name, value: item.text };
      else return { name: item.name, value: item.value };
    });
  };

  getData = async () => {
    this.setState({
      loading: true,
    });

    const { mySnackbarContext } = this.props;

    ordersRequest = makeCancelable(
      VendasApi.prototype.get(this.prepareFilterToRequest())
    );

    const orders = await ordersRequest.promise;

    if (orders.type === 'SUCCESS') {
      if (orders.qtd > 0) {
        this.setState({
          loaded: true,
          data: orders.order.item,
          loading: false,
        });
      } else
        this.setState({
          loaded: false,
          loading: false,
        });
    } else if (orders.type === 'ERROR') {
      this.setState({
        loaded: false,
        loading: false,
      });

      mySnackbarContext.handleOpen(orders.message, orders.type);
    }
  };

  handleCloseModalSearch = () => {
    this.setState({
      openModalSearch: false,
    });
  };

  handleOpenModalSearch = () => {
    this.setState({
      openModalSearch: true,
    });
  };

  async componentDidMount() {
    await this.getData();
  }

  componentWillUnmount() {
    ordersRequest.cancel();
  }

  handleShowTotal = () => {
    this.setState({
      showTotal: !this.state.showTotal,
    });
  };

  render() {
    const {
      openModalSearch,
      filter,
      loaded,
      loading,
      data,
      showTotal,
    } = this.state;

    return (
      <Fragment>
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar
              title={'Vendas'}
              appContext={context}
              ActionComponents={
                <VendasAction
                  handleOpenModalSearch={this.handleOpenModalSearch}
                  handleShowTotal={this.handleShowTotal}
                />
              }
            />
          )}
        </AppContextConsumer>
        {showTotal && (
          <VendasTotal handleShowTotal={this.handleShowTotal} data={data} />
        )}
        <Grid container>
          {openModalSearch && (
            <SimpleModal
              displayTitle
              title={'Pesquisa'}
              handleModalState={this.handleCloseModalSearch}
              ActionComponent={
                <VendasFilterAction
                  handleCloseSearch={this.handleCloseModalSearch}
                  setInitialStateFilterValue={this.setInitialStateFilterValue}
                />
              }
            >
              <VendasFilter
                submit={this.getData}
                filter={filter}
                handleInitialDate={this.handleInitialDate}
                handleFinalDate={this.handleFinalDate}
                handleFilterChange={this.handleFilterChange}
                setInitialStateFilterValue={this.setInitialStateFilterValue}
                handleCloseModalSearch={this.handleCloseModalSearch}
              />
            </SimpleModal>
          )}
          <Grid item xs={12}>
            {this.hasFilter() && (
              <Filter filter={filter} clear={this.clearSelectedFilter} />
            )}
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <VendasTableLoading />
            ) : !loaded ? (
              <TableNoData />
            ) : (
              <VendasTable data={data} />
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  withSnackbarContext
)(Vendas);
