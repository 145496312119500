import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';

export const VendasAction = props => {
  const { handleOpenModalSearch, handleShowTotal } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={'Procurar'}>
        <IconButton onClick={handleOpenModalSearch} color={'inherit'}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Novo pedido'}>
        <IconButton
          component={Link}
          to={process.env.REACT_APP_ROOTPATH + 'orders/new'}
          color={'inherit'}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <IconButton
        onClick={event => setAnchorEl(event.currentTarget)}
        color={'inherit'}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={process.env.REACT_APP_ROOTPATH + 'weeklyclosing'}
          color={'inherit'}
          onClick={handleClose}
        >
          Fechamento
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleShowTotal();
            handleClose();
          }}
        >
          Ver total
        </MenuItem>
      </Menu>
    </div>
  );
};
