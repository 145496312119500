import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField } from '@material-ui/core';

const styles = {
  N: {
    backgroundColor: '#6bc720',
  },
  S: {
    backgroundColor: '#ff8100',
  },
  containerFields: {
    marginTop: 20,
  },
  chipRoot: {
    marginBottom: 10,
  },
};

class ClientBasic extends React.Component {
  render() {
    const { customer } = this.props;

    return (
      <Fragment>
        <Grid container item spacing={2}>
          <Grid item xl={2} lg={2} md={3} xs={12}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="Código"
              variant="standard"
              value={customer.id}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xl={2} lg={2} md={3} xs={12}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="CNPJ"
              variant="standard"
              value={customer.cnpj}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} lg={3} md={5} xl={3}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="Endereço"
              variant="standard"
              fullWidth
              value={customer.address}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} lg={2} md={4} xl={2}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="Bairro"
              variant="standard"
              fullWidth
              value={customer.neighborHood}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} lg={2} md={4} xl={2}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="Cidade"
              variant="standard"
              fullWidth
              value={customer.city}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} lg={2} md={4} xl={2}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="CEP"
              variant="standard"
              fullWidth
              value={customer.zipcode}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} lg={2} md={4} xl={2}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="Telefone"
              variant="standard"
              fullWidth
              value={customer.phone}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} lg={2} md={4} xl={2}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="E-mail"
              variant="standard"
              fullWidth
              value={customer.mail}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} lg={2} md={4} xl={2}>
            <TextField
              readOnly
              autoComplete={'off'}
              label="Tabela de Preço"
              variant="standard"
              fullWidth
              value={customer.tablePrice + ' - ' + customer.tablePriceName}
              margin="normal"
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ClientBasic);
