import ApiRequest from 'services/ApiRequest';

class PushNotificationApi extends ApiRequest {
  async send(param) {
    const result = await this.doPost('pushNotification/sendToAll', param);
    return result;
  }
}

export default PushNotificationApi;
