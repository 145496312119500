import React, { Component, Fragment } from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VendasListPrices from 'components/vendas/VendasListPrices';
import OrderStockList from 'components/vendas/order/new/OrderStockList';
import SearchLoading from 'components/loading/SearchLoading';
import SimpleModal from 'components/template/modal/SimpleModal';
import { withStyles } from '@material-ui/core/styles';
import { OrderAddProductAction } from './OrderAddProductAction';
import OrderAddProductForm from './OrderAddProductForm';

const styles = {
  grow: {
    flexGrow: 1,
  },
};

class OrderAddProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStock: false,
      quantity: '',
      discount: '',
    };
  }

  getStock = () => {
    const { order, stock } = this.props;
    return stock[order.idCliente];
  };

  hasStock = () => {
    const { order, stock } = this.props;
    return !!stock[order.idCliente];
  };

  handleChange = () => {
    this.setState({
      showStock: !this.state.showStock,
    });
  };

  render() {
    const {
      productSelected,
      handleProductSearchChange,
      loadingProductSearch,
      handleProductSelectToOrder,
      handleProductSelectedDataChange,
      prices,
      loading,
      productSelectedData,
      handleOrderSave,
      order,
      title,
      handleProductSelected,
      onExited,
    } = this.props;

    const showStock = this.state.showStock;
    const hasStock = this.hasStock();
    let stock = hasStock && this.getStock();
    let stockItemQuantity = 'Não informado';

    if (productSelected) {
      if (stock)
        stock.forEach(item => {
          if (productSelectedData.productId === item.productId) {
            stockItemQuantity = item.quantity + ' ' + productSelectedData.unit;
          }
        });
    }

    return (
      <Fragment>
        {loadingProductSearch && <SearchLoading />}
        <SimpleModal
          handleModalState={onExited}
          title={title}
          ActionComponent={
            <OrderAddProductAction
              hasStock={hasStock}
              showStock={showStock}
              handleChange={this.handleChange}
            />
          }
        >
          {!productSelected && (
            <Fragment>
              {!showStock && (
                <Fragment>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      onChange={handleProductSearchChange}
                      margin="normal"
                      label="Pesquisar produto"
                      placeholder="Digite o código do produto"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {prices.length > 0 && (
                      <VendasListPrices
                        prices={prices}
                        handleProductSelectToOrder={handleProductSelectToOrder}
                      />
                    )}
                  </Grid>
                </Fragment>
              )}
              {showStock && hasStock && (
                <Grid item xs={12}>
                  <OrderStockList
                    handleClick={handleProductSelectToOrder}
                    orderItems={order.items}
                    stock={stock}
                  />
                </Grid>
              )}
            </Fragment>
          )}
        </SimpleModal>
        {productSelected && (
          <SimpleModal handleModalState={() => handleProductSelected(false)}>
            <OrderAddProductForm
              stockItemQuantity={stockItemQuantity}
              title={title}
              loading={loading}
              handleProductSelectedDataChange={handleProductSelectedDataChange}
              productSelected={productSelected}
              productSelectedData={productSelectedData}
              handleProductSelected={handleProductSelected}
              handleOrderSave={handleOrderSave}
            />
          </SimpleModal>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrderAddProduct);
