import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import User from 'services/user/UserController';
import { withSnackbarContext } from 'components/messages/MySnackBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import { isAuth } from 'services/user/Auth';
import { CircularProgress, Grow } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/messaging';
import UserController from 'services/user/UserController';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { compose } from 'recompose';
import PasswordStep from 'components/login/PasswordStep';
import UsernameStep from 'components/login/UsernameStep';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#ff7008',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: '#fff',
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'rgba(255,255,255,0.23)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'white',
        },
      },
      disabled: {},
      focused: {},
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: '#fff',
        '&$focused': {
          color: '#fff',
        },
      },
      focused: {},
    },
    MuiButton: {
      root: {
        '&:hover$disabled': {
          borderColor: 'rgba(0,0,0,0.23)',
        },
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = theme => ({
  paper: {
    position: 'relative',
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 15px',
  },
  logo: {
    width: 200,
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: 50,
  },

  iconBack: {
    padding: 5,
    position: 'absolute',
    top: 15,
  },
  loadingWrap: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1001,
  },
  loading: {
    transform: 'translate(-50%)',
    left: '50%',
    position: 'absolute',
    top: '40%',
  },
  bodyBackground: {
    backgroundImage: `radial-gradient(circle, ${theme.palette.primary.main} 35%, ${theme.palette.primary.dark})`,
    backgroundRepeat: 'no-repeat',
  },
  links: {
    marginTop: 30,
  },
  appName: {
    marginTop: 7,
    color: theme.palette.primary.contrastText,
  },
  arrowBack: {
    position: 'absolute',
    top: 10,
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    document.title = process.env.REACT_APP_TITLE + ' Login';
    document.body.classList.add(classes.bodyBackground);

    this.state = {
      username: '',
      pass: '',
      loading: false,
      errLogin: false,
      showPassword: false,
      step: 'username',
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    if (this.state.step === 'username') {
      this.setState({
        step: 'pass',
      });

      return false;
    } else {
      const { mySnackbarContext, history } = this.props;

      this.setState({
        loading: true,
      });

      const result = await User.prototype.login(this.state);

      if (result.type === 'SUCCESS') {
        const { data } = result;

        localStorage.setItem('token', data.token);

        if (mySnackbarContext.open) mySnackbarContext.handleClose();

        try {
          const messaging = firebase.messaging();
          messaging
            .requestPermission()
            .then(() => {
              return messaging.getToken();
            })
            .then(token => {
              const param = {
                pushToken: token,
                device: navigator.platform,
              };

              UserController.prototype
                .saveTokenPushNotification(param)
                .then(resolved => {
                  if (resolved.type === 'SUCCESS') console.log('Token saved');
                  else if (resolved.type === 'ERROR')
                    console.log(resolved.message);
                })
                .catch(reject => {
                  console.log(reject);
                });
            })
            .catch(error => {
              console.log(error);
            });
        } catch (error) {
          console.error(error);
        }

        history.push('dashboard');
      } else if (result.type === 'ERROR') {
        this.setState({
          loading: false,
        });

        mySnackbarContext.handleOpen(result.message, result.type);
      }
    }
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handlePrior = () => {
    this.setState({
      step: 'username',
    });
  };

  componentWillUnmount() {
    const { classes } = this.props;
    document.body.classList.remove(classes.backgroundColor);
  }

  componentDidMount() {
    const { history } = this.props;
    isAuth() && history.push('/dashboard');
  }

  render() {
    const { classes } = this.props;
    const { loading, username, pass, step, showPassword } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <Grid container justify={'center'}>
          <Grid item xs={11} md={6} lg={4} xl={3}>
            <Grow in timeout={500}>
              <div className={classes.paper}>
                {step === 'pass' && (
                  <IconButton
                    color={'primary'}
                    className={classes.arrowBack}
                    onClick={this.handlePrior}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                {loading && (
                  <div className={classes.loadingWrap}>
                    <div className={classes.loading}>
                      <CircularProgress />
                    </div>
                  </div>
                )}
                <div className={classes.logoContainer}>
                  <img
                    className={classes.logo}
                    src={process.env.REACT_APP_MEDIAURL + 'images/logosf.png'}
                    alt="Logo São Francisco Utensílios de Limpeza"
                  />
                  <div id="recaptcha-verifier" />
                </div>
                <form onSubmit={this.handleSubmit}>
                  {step === 'username' ? (
                    <UsernameStep
                      loading={loading}
                      username={username}
                      handleChange={this.handleChange}
                    />
                  ) : (
                    <PasswordStep
                      loading={loading}
                      username={username}
                      pass={pass}
                      showPassword={showPassword}
                      handleClickShowPassword={this.handleClickShowPassword}
                      handleChange={this.handleChange}
                    />
                  )}
                </form>
                <div className={classes.links}>
                  <Typography
                    component={Link}
                    to={'forgot-password'}
                    color={'primary'}
                    align={'center'}
                  >
                    Esqueci minha senha
                  </Typography>
                </div>
              </div>
            </Grow>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  withSnackbarContext
)(Login);
