import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import IconSearch from '@material-ui/icons/Search';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';

const styles = {
  paper: {
    padding: 20,
    marginBottom: 10,
  },
  button: {
    display: 'none',
  },
  filterable: {},
};

class ClientesFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterable: true,
    };
  }

  handleSubmit = event => {
    const { handleCloseSearch } = this.props;
    handleCloseSearch();
    event.preventDefault();
  };

  filterableChange = () => {
    this.setState({
      filterable: !this.state.filterable,
    });
  };

  componentWillUnmount() {
    const { submit } = this.props;
    submit();
  }

  render() {
    const { filter, handleFilterChange, classes } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={2}>
            <TextField
              autoFocus
              label={'Cliente'}
              fullWidth
              autoComplete="off"
              value={filter[1].value}
              onChange={handleFilterChange('input')}
              name="search"
              placeholder={'Digite a razão ou o código do cliente'}
              margin={'normal'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <IconSearch />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Collapse in={this.state.filterable}>
          <Grid container className={classes.filterable}>
            <Grid container>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <TextField
                  fullWidth
                  label={'Cidade'}
                  value={filter[2].value}
                  onChange={handleFilterChange('input')}
                  name="cidade"
                  autoComplete="off"
                  margin={'normal'}
                  variant={'standard'}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <TextField
                  variant={'standard'}
                  select
                  fullWidth
                  label={'Situação'}
                  margin={'normal'}
                  name="situacao"
                  value={filter[0].value}
                  onChange={handleFilterChange('select')}
                >
                  <MenuItem value="T">Todas as Situções</MenuItem>
                  <MenuItem value="N">Ativo</MenuItem>
                  <MenuItem value="S">Bloqueado</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Button
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              className={classes.button}
            >
              <IconSearch /> Buscar
            </Button>
          </Grid>
        </Collapse>
      </form>
    );
  }
}

export default withStyles(styles)(withRouter(ClientesFilter));
