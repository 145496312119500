import React, { PureComponent, Fragment } from 'react';
import {
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Operacoes } from 'components/operacao/Operacaoes';
import { FormasPagamento } from 'components/formas_pagamento/FormasPagamento';
import CondicaoPagamento from 'components/condicao_pagamento/CondicaoPagamento';
import ClienteData from 'components/clientes/ClienteData';
import IconSearch from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import SearchCliente from 'components/clientes/SearchCliente';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';

const styles = {
  paper: {
    width: '100%',
    padding: 30,
  },
  chipColor: {
    backgroundColor: '#6bc720',
  },
  header: {
    margin: '10px 0',
  },
  chipRoot: {
    marginBottom: 10,
  },
};

class OrderBasic extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dialogCustomerSearch: false,
    };
  }

  handleFocus = () => {
    this.setState({
      dialogCustomerSearch: !this.state.dialogCustomerSearch,
    });
  };

  componentDidMount() {
    const { idCliente } = this.props.order;

    if (idCliente === '')
      this.setState({
        dialogCustomerSearch: true,
      });
  }

  /*    componentDidUpdate(prevProps, prevState, snapshot) {

        const {idCliente} = this.props.order;

        if (idCliente === '')
            this.setState({
                dialogCustomerSearch: true
            });
    }*/

  render() {
    const {
      idOperacao,
      idFormaPagamento,
      idCondicaoPagamento,
      idRateio,
      desconto,
      observacao,
      cliente,
      items,
    } = this.props.order;

    const {
      handleOrderChange,
      loadingCustomer,
      customerData,
      handleSetCliente,
      classes,
    } = this.props;

    const { dialogCustomerSearch } = this.state;

    return (
      <Fragment>
        {dialogCustomerSearch && (
          <SearchCliente
            handleSetCliente={handleSetCliente}
            handleClose={this.handleFocus}
          />
        )}
        <Grid container>
          {!loadingCustomer && customerData && (
            <div className={classes.header}>
              <Chip
                classes={{
                  colorPrimary: classes.chipColor,
                  root: classes.chipRoot,
                }}
                color={'primary'}
                label={'Digitação'}
              />
              <Typography variant={'h5'}>
                Pedido {customerData.companyFantasy}
              </Typography>
              <Typography variant={'caption'}>
                Emissão {customerData.currentDate}
              </Typography>
            </div>
          )}
          <Grid item xs={12}>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <TextField
                required
                disabled={items.length > 0}
                fullWidth
                label="Cliente"
                variant="standard"
                placeholder="Informe o cliente"
                value={cliente}
                onChange={
                  items.length === 0 ? handleOrderChange('cliente') : () => {}
                }
                margin="normal"
                onFocus={this.handleFocus}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      disabled={items.length > 0}
                      onClick={this.handleFocus}
                    >
                      <IconSearch />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <div>
              {loadingCustomer && <CircularProgress color={'primary'} />}
              {customerData && <ClienteData data={customerData} />}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid item xl={4} md={6} lg={5} xs={12}>
              <Operacoes
                disabled={items.length > 0}
                change={
                  items.length === 0
                    ? handleOrderChange('idOperacao')
                    : () => {}
                }
                idOperacao={idOperacao}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xl={4} md={6} lg={5} xs={12}>
              <TextField
                required
                disabled={items.length > 0}
                select
                variant="standard"
                label="Rateio"
                onChange={
                  items.length === 0 ? handleOrderChange('idRateio') : () => {}
                }
                value={idRateio}
                fullWidth
                margin="normal"
              >
                <MenuItem value="4">4 - BRASIL</MenuItem>
                <MenuItem value="PD">PD - BRADESCO</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Collapse in={idOperacao === '001'}>
              <Grid item xs={12}>
                <Grid item xl={4} md={6} lg={5} xs={12}>
                  <FormasPagamento
                    disabled={items.length > 0}
                    change={
                      items.length === 0
                        ? handleOrderChange('idFormaPagamento')
                        : () => {}
                    }
                    idFormaPagamento={idFormaPagamento}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xl={4} md={6} lg={5} xs={12}>
                  <CondicaoPagamento
                    disabled={
                      items.length > 0 ||
                      (idFormaPagamento === '002' &&
                        idCondicaoPagamento === '0044')
                    }
                    change={
                      items.length === 0 ||
                      (idFormaPagamento !== '002' &&
                        idCondicaoPagamento !== '0044')
                        ? handleOrderChange('idCondicaoPagamento')
                        : () => {}
                    }
                    idCondicaoPagamento={idCondicaoPagamento}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xl={4} md={6} lg={5} xs={12}>
                  <TextField
                    disabled={items.length > 0}
                    autoComplete={'off'}
                    variant="standard"
                    label="% Desconto"
                    name="desconto"
                    margin="normal"
                    value={desconto}
                    type={'number'}
                    onChange={
                      items.length === 0 ? handleOrderChange('desconto') : null
                    }
                    placeholder="Informe o desconto"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <TextField
                label="Observação"
                multiline
                rows="4"
                margin="normal"
                variant="standard"
                value={observacao}
                onChange={handleOrderChange('observacao')}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrderBasic);
