import React from 'react';
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { numberFormat } from 'helpers/NumberFormat';
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import { SimpleModalConsumer } from 'components/template/modal/SimpleModal';

const styles = {
  productInfo: {
    marginBottom: 20,
  },
  submit: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
};

const OrderStockForm = props => {
  const {
    productStockSelectedData,
    handleProductStockSave,
    handleProductStockSelectedChange,
    classes,
  } = props;

  const handleSubmit = e => {
    e.preventDefault();
    handleProductStockSave();
  };

  return (
    <form onSubmit={handleSubmit}>
      <SimpleModalConsumer>
        {({ handleClose }) => (
          <AppBar>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="Close"
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant={'h6'} color={'inherit'}>
                Incluir estoque
              </Typography>
              <div className={classes.grow} />
              <div>
                <IconButton
                  color={'inherit'}
                  component={'button'}
                  type={'submit'}
                >
                  <DoneIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        )}
      </SimpleModalConsumer>
      <Grid item xs={12} className={classes.productInfo}>
        <Typography variant={'h5'}>
          {productStockSelectedData.productId} -{' '}
          {productStockSelectedData.description}
        </Typography>
        <Typography variant={'caption'}>
          Embalagem:{' '}
          <strong>
            {productStockSelectedData.secondUnit}/
            {productStockSelectedData.fator}
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus={true}
            margin="normal"
            required
            helperText={
              numberFormat(
                productStockSelectedData.quantity /
                  productStockSelectedData.fator
              ) +
              ' ' +
              productStockSelectedData.secondUnit
            }
            label="Quantidade"
            variant="standard"
            fullWidth
            type={'number'}
            name={'quantity'}
            onChange={handleProductStockSelectedChange}
            value={productStockSelectedData.quantity}
          />
        </Grid>
        <Button className={classes.submit} type="submit">
          Salvar
        </Button>
      </Grid>
    </form>
  );
};

export default withStyles(styles)(OrderStockForm);
