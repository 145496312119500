import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { moneyFormat, numberFormat } from '../../helpers/NumberFormat';
import PropTypes from 'prop-types';

export const SalesChart = React.memo(props => {
  const { classes, chartHeight, loadingSalesChart, salesChartData } = props;

  return (
    <Paper className={classes.paperChart} style={{ height: chartHeight }}>
      <Typography style={{ marginBottom: 30 }}>Evolução das Vendas</Typography>
      {loadingSalesChart ? (
        <div className={classes.loadingChart}>
          <CircularProgress color={'primary'} />
        </div>
      ) : (
        <ResponsiveContainer>
          <BarChart width={730} height={250} data={salesChartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={value => numberFormat(value)} />
            <Tooltip formatter={value => moneyFormat(value)} />
            <Legend />
            <Bar dataKey="Vendas" fill="#ffc107" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
});

SalesChart.propTypes = {
  classes: PropTypes.object,
  chartHeight: PropTypes.string,
  loadingSalesChart: PropTypes.bool,
  salesChartData: PropTypes.array.isRequired,
};
