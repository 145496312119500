import React, { Component } from 'react';
import { Grid, Slide } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import OrderNoItem from 'components/vendas/order/new/OrderNoItem';
import OrderTable from 'components/vendas/order/new/OrderTable';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  fabAdd: {
    float: 'right',
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      right: '10%',
      bottom: '15%',
    },
  },
  chipPrice: {
    margin: '10px 0',
  },
  chipColorPrimary: {
    backgroundColor: '#17a2b8',
  },
});

class OrderItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleOpenMenu = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleClick = async func => {
    await this.handleCloseMenu();
    func();
  };

  render() {
    const {
      classes,
      handleModalState,
      handleModalMixState,
      handleItemDelete,
      handleItemChange,
      handleModalStockState,
      mix,
      order,
      handleListItemSelect,
    } = this.props;

    const { items } = this.props.order;

    const open = Boolean(this.state.anchorEl);

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {items.length === 0 && (
            <OrderNoItem
              idCliente={this.props.order.idCliente}
              mix={mix}
              handleModalState={handleModalState}
              handleModalMixState={handleModalMixState}
              handleModalStockState={handleModalStockState}
            />
          )}
          {items.length > 0 && (
            <OrderTable
              items={items}
              handleItemDelete={handleItemDelete}
              handleItemChange={handleItemChange}
              handleListItemSelect={handleListItemSelect}
            />
          )}
          {order.idCliente !== '' && (
            <Grid item xs={12} className={classes.fabPlus}>
              <Menu
                id="menu-OrderItem"
                anchorEl={this.state.anchorEl}
                open={open}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={() => this.handleClick(handleModalState)}>
                  Incluir um produto
                </MenuItem>
                {mix && (
                  <MenuItem
                    onClick={() => this.handleClick(handleModalMixState)}
                  >
                    Carregar mix
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => this.handleClick(handleModalStockState)}
                >
                  Incluir estoque
                </MenuItem>
              </Menu>
              <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Fab
                  className={classes.fabAdd}
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleOpenMenu}
                  color="primary"
                  size="large"
                >
                  <AddIcon />
                </Fab>
              </Slide>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(OrderItems);
