import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import IconSearch from '@material-ui/icons/Search';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import MyDatePicker from 'components/template/datepicker/MyDatePicker';

const styles = {
  paper: {
    padding: 20,
    marginBottom: 10,
  },
  button: {
    display: 'none',
  },
  buttonContainer: {
    marginTop: 15,
  },
  filterable: {
    padding: '10px 0',
  },
};

class TitulosFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterable: true,
    };
  }

  handleSubmit = event => {
    const { closeModalSearch } = this.props;
    closeModalSearch();
    event.preventDefault();
  };

  filterableChange = () => {
    this.setState({
      filterable: !this.state.filterable,
    });
  };

  componentWillUnmount() {
    const { submit } = this.props;
    submit();
  }

  render() {
    const {
      filter,
      filterChange,
      handleInitialDate,
      handleFinalDate,
      classes,
    } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} xl={2}>
            <TextField
              autoFocus
              label={'Título'}
              fullWidth
              autoComplete="off"
              value={filter[3].value}
              onChange={filterChange('input')}
              name="search"
              placeholder={
                'Digite o número do título, código ou nome do cliente'
              }
              margin={'normal'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <IconSearch />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Collapse in={this.state.filterable}>
          <Grid container className={classes.filterable}>
            <Grid container>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <MyDatePicker
                  label="Data Inicial"
                  value={filter[0].value}
                  onChange={handleInitialDate}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <MyDatePicker
                  label="Data Final"
                  value={filter[1].value}
                  onChange={handleFinalDate}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <TextField
                  variant={'standard'}
                  select
                  fullWidth
                  label={'Situação'}
                  margin={'normal'}
                  name="situacao"
                  value={filter[2].value}
                  onChange={filterChange('select')}
                >
                  <MenuItem value="T">Todas as Situções</MenuItem>
                  <MenuItem value="A">Aberto</MenuItem>
                  <MenuItem value="P">Liquidado</MenuItem>
                  <MenuItem value="V">Vencido</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Button
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              className={classes.button}
            >
              <IconSearch /> Buscar
            </Button>
          </Grid>
        </Collapse>
      </form>
    );
  }
}

export default withStyles(styles)(withRouter(TitulosFilter));
