import React, { Component, Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography/Typography';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { menuWidth } from 'App';
import { Slide } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

let scrollTop = 0;
let contentWrapper = null;

const styles = theme => ({
  appBar: {
    zIndex: 1102,
    '@media print': {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  appBarShadow: {
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
    },
  },
  appBarTitle: {
    [theme.breakpoints.down('md')]: {
      minWidth: 95,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  appBarTabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.light,
    },
    transitionDuration: '225ms',
    left: 0,
  },
  appBarTabsMenuOpen: {
    left: menuWidth,
  },
  appBarTabsSpace: {
    marginBottom: 45,
    [theme.breakpoints.down('md')]: {
      marginBottom: 48,
    },
  },
});

class MyAppBar extends Component {
  state = {
    open: false,
    anchorEl: null,
    appBarTabs: true,
  };

  handleCloseMenuAppBar = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuAppBar = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleScroll = () => {
    if (contentWrapper.scrollTop > scrollTop && scrollTop > 47)
      this.setState({
        appBarTabs: false,
      });
    else
      this.setState({
        appBarTabs: true,
      });

    scrollTop = contentWrapper.scrollTop;
  };

  componentDidMount() {
    const { Tabs } = this.props;
    if (Tabs) {
      contentWrapper = document.getElementById('content-wrapper');
      //contentWrapper.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    const { Tabs } = this.props;
    if (Tabs) {
      //contentWrapper.removeEventListener('scroll', this.handleScroll);
    }
  }

  render() {
    const opened = Boolean(this.state.anchorEl);
    const {
      classes,
      ActionComponents,
      title,
      Tabs,
      appContext,
      cancel,
      cancelAction,
    } = this.props;
    const { appBarTabs } = this.state;

    return (
      <Fragment>
        <AppBar
          className={`${classes.appBar} ${appBarTabs &&
            Tabs &&
            classes.appBarShadow}`}
          position={'fixed'}
          color={'primary'}
        >
          <Toolbar>
            <IconButton
              onClick={cancel ? cancelAction : appContext.handleOpenMenu}
              color="inherit"
            >
              {cancel ? <NavigateBeforeIcon /> : <MenuIcon />}
            </IconButton>
            <Typography
              variant={'h6'}
              color={'inherit'}
              className={classes.appBarTitle}
            >
              {title}
            </Typography>
            <div className={classes.grow} />
            {ActionComponents}
            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={opened}
              onClose={this.handleCloseMenuAppBar}
            >
              <MenuItem onClick={this.handleCloseMenuAppBar}>Perfil</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        {Tabs && (
          <Fragment>
            <Slide direction={'down'} in={appBarTabs}>
              <AppBar
                className={`${classes.appBarTabs} ${appContext.openMenu &&
                  !appContext.mobile &&
                  appContext.windowWidth > 1280 &&
                  classes.appBarTabsMenuOpen}`}
              >
                {Tabs}
              </AppBar>
            </Slide>
            <div className={Tabs && classes.appBarTabsSpace} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

MyAppBar.propTypes = {
  title: PropTypes.string.isRequired,
  ActionComponents: PropTypes.element,
  Tabs: PropTypes.element,
  appContext: PropTypes.object.isRequired,
  classes: PropTypes.object,
  cancel: PropTypes.bool,
  cancelAction: PropTypes.func,
};

export default withStyles(styles)(withRouter(MyAppBar));
