import ApiRequest from 'services/ApiRequest';

class TitulosController extends ApiRequest {
  async get(param) {
    const result = await this.doGet('titulos/getList', param);
    return result;
  }

  async getByCustomer(param) {
    const result = await this.doGet('titulos/getListByCustomer', param);
    return result;
  }
}

export default TitulosController;
