import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { moneyFormat } from '../../helpers/NumberFormat';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    paddingBottom: 0,
  },
};

const CommissionTotal = props => {
  const { classes, data } = props;

  let total = 0,
    base = 0;

  data.forEach(item => {
    total += item.F13_VALOR;
    base += item.F13_BASE;
  });

  return (
    <Grid item xl={2} xs={12}>
      <List>
        <ListItem classes={{ root: classes.root }}>
          <ListItemText>Notas</ListItemText>
          <ListItemSecondaryAction>
            <Typography variant={'body1'}>{data.length}</Typography>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ root: classes.root }}>
          <ListItemText>Base</ListItemText>
          <ListItemSecondaryAction>
            <Typography variant={'body1'}>{moneyFormat(base)}</Typography>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ root: classes.root }}>
          <ListItemText>Total</ListItemText>
          <ListItemSecondaryAction>
            <Typography variant={'body1'}>{moneyFormat(total)}</Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Grid>
  );
};

export default withStyles(styles)(CommissionTotal);
