import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route } from 'react-router';
import { BrowserRouter, Redirect } from 'react-router-dom';
import App from 'App';
import Dashboard from 'components/dashboard/Dashboard';
import NotFound from 'components/errors/NotFound';
import Home from 'components/home/Home';
import Login from 'components/login/Login';
import Vendas from 'components/vendas/Vendas';
import Clientes from 'components/clientes/Clientes';
import Titulos from 'components/titulos/Titulos';
import NovoPedido from 'components/vendas/order/new/Order';
import OrderView from 'components/vendas/order/view/Order';
import OrderPrint from 'components/vendas/order/print/OrderPrint';
import Messaging from 'components/messaging/Messaging';
import Theme from 'components/template/Theme';
import 'index.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { getLevelPermission, isAuth } from 'services/user/Auth';

import MyPass from 'components/password/MyPass';
import ClienteView from 'components/clientes/view/Cliente';
import WeeklyClosing from 'components/vendas/weekly_closing/WeeklyClosing';
import MyAccount from 'components/account/MyAccount';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { config } from 'services/FirebaseConfig';
import Snackbar from 'components/messages/MySnackBar';
import Commission from 'components/commission/Commission';

// Initiating Firebase
firebase.initializeApp(config);

const ROOTPATH = process.env.REACT_APP_ROOTPATH;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuth() === true ? (
        getLevelPermission() >= rest.levelPermission ? (
          <Component {...props} />
        ) : (
          <NotFound />
        )
      ) : (
        <Redirect to={{ pathname: ROOTPATH + 'login' }} />
      )
    }
  />
);

const Root = () => (
  <ThemeProvider theme={Theme}>
    <BrowserRouter>
      <Snackbar>
        <App>
          <Switch>
            <Route
              exact
              path={ROOTPATH + 'login/'}
              component={() => <Login />}
            />
            <Route
              exact
              path={ROOTPATH + 'forgot-password/'}
              component={() => <MyPass />}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH}
              component={Home}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'dashboard/'}
              component={Dashboard}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'customers/'}
              component={Clientes}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'customers/view/:idCliente'}
              component={ClienteView}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'orders/'}
              component={Vendas}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'invoices'}
              component={Titulos}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'orders/new'}
              component={NovoPedido}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'weeklyclosing'}
              component={WeeklyClosing}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'orders/view/:idCompany/:idOrder'}
              component={OrderView}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'orders/print/:idCompany/:idOrder'}
              component={OrderPrint}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'my-account'}
              component={MyAccount}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'dashboard/'}
              component={Dashboard}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_USERPERMISSION}
              exact
              path={ROOTPATH + 'commission/'}
              component={Commission}
            />
            <PrivateRoute
              levelPermission={process.env.REACT_APP_MANAGERPERMISSION}
              exact
              path={ROOTPATH + 'messaging'}
              component={Messaging}
            />
            <Route component={NotFound} />
          </Switch>
        </App>
      </Snackbar>
    </BrowserRouter>
  </ThemeProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
