import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import SimpleModal from 'components/template/modal/SimpleModal';
import { withStyles } from '@material-ui/core/styles';
import OrderNoItem from 'components/vendas/order/new/OrderNoItem';
import OrderTable from 'components/vendas/order/view/OrderTable';
import OrderAddProduct from 'components/vendas/order/new/OrderAddProduct';

const styles = {
  paper: {
    width: '100%',
    padding: 30,
  },
  fabPlus: {
    paddingTop: 30,
    textAlign: 'right',
  },
  productSelectedButton: {
    marginRight: 10,
    marginTop: 20,
  },
  productName: {
    marginBottom: 8,
  },
};

class OrderItems extends Component {
  render() {
    const {
      isModalOpen,
      handleModalState,
      handleItemDelete,
      handleItemChange,
    } = this.props;
    const { items } = this.props.order;
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isModalOpen && (
            <SimpleModal
              title={'Incluir produto'}
              handleModalState={handleModalState}
              content={<OrderAddProduct {...this.props} />}
            />
          )}
          {items.length === 0 && <OrderNoItem />}
          {items.length > 0 && (
            <OrderTable
              items={items}
              handleItemDelete={handleItemDelete}
              handleItemChange={handleItemChange}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(OrderItems);
