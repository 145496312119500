import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

const styles = {
  actions: {
    marginTop: 20,
    marginBottom: 20,
  },
  textField: {
    boxSizing: 'border-box',
  },
};

class PasswordStep extends Component {
  render() {
    const {
      classes,
      handleChange,
      pass,
      loading,
      showPassword,
      handleClickShowPassword,
      username,
    } = this.props;
    return (
      <Fragment>
        <Typography color={'primary'} align={'center'}>
          Olá {username}.
        </Typography>
        <TextField
          variant={'outlined'}
          autoFocus
          name="pass"
          placeholder="Agora, digite a sua senha"
          required
          onChange={handleChange}
          value={pass}
          type={showPassword ? 'text' : 'password'}
          label={'Digite sua senha'}
          fullWidth
          margin={'normal'}
          InputProps={{
            classes: { root: classes.textField },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color={'primary'} onClick={handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.actions}>
          <Button
            disabled={loading}
            fullWidth
            type="submit"
            color={'primary'}
            variant={'outlined'}
          >
            Entrar
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(PasswordStep);
