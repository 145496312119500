import React, { Component, Fragment } from 'react';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import IconCheck from '@material-ui/icons/Check';
import {
  IconButton,
  Tooltip,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VendasApi from 'services/VendasApi';
import { moneyFormat } from 'helpers/NumberFormat';
import SearchLoading from 'components/loading/SearchLoading';
import { compose } from 'recompose';
import { withUserData } from 'components/user/UserData';
import { withSnackbarContext } from '../../messages/MySnackBar';

const styles = theme => ({
  container: {
    padding: '10px 30px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  row: {
    marginTop: 20,
  },
  label: {
    width: 85,
    display: 'inline-block',
  },
});

class WeeklyClosing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      total: 0,
      quantity: 0,
      observation: '',
      isSnackbarOpen: false,
      message: '',
      messagetype: '',
    };
  }

  handleChange = event => {
    this.setState({
      observation: event.target.value,
    });
  };

  async componentDidMount() {
    const response = await VendasApi.prototype.weeklyClosing();

    if (response.type === 'SUCCESS') {
      this.setState({
        total: response.total,
        quantity: response.quantity,
        loading: false,
        isSnackOpen: true,
      });
    } else if (response.type === 'ERROR') {
      this.setState({
        message: response.message,
        messageType: response.messageType,
        isSnackOpen: true,
        loading: false,
      });
    }
  }

  sendWeeklyClosing = async () => {
    this.setState({
      loading: true,
      isSnackbarOpen: false,
    });

    const response = await VendasApi.prototype.sendWeeklyClosing(
      this.state
    );
    const { mySnackbarContext } = this.props;

    if (response.type === 'SUCCESS') {
      this.setState({
        loading: false,
      });

      mySnackbarContext.handleOpen('Fechamento enviado!', response.type);
    } else if (response.type === 'ERROR') {
      this.setState({
        loading: false,
      });

      mySnackbarContext.handleOpen(response.message, response.type);
    }
  };

  render() {
    const { classes, userData } = this.props;
    const { observation, loading, total, quantity } = this.state;

    return (
      <Fragment>
        {loading && <SearchLoading />}
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar
              title={'Fechamento'}
              appContext={context}
              ActionComponents={
                <Fragment>
                  <Tooltip title={'Enviar fechamento'}>
                    <div>
                      <IconButton
                        disabled={
                          observation === '' ||
                          observation.length <= 5 ||
                          loading
                        }
                        onClick={this.sendWeeklyClosing}
                        color={'inherit'}
                      >
                        <IconCheck />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Fragment>
              }
            />
          )}
        </AppContextConsumer>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Olá {userData.name}.</Typography>
            <Typography>
              O fechamento trata-se do envio das informações da venda semanal.
              Isso é necessário para confirmarmos seus pedidos.
              <span className={classes.italic}>
                {' '}
                Antes de enviar verifique se as informações estão corretas
              </span>
              . Uma mensagem de e-mail será enviada aos responsáveis com cópia
              para você.
              <span className={classes.italic}>
                {' '}
                Apenas pedidos do tipo venda são considerados nesse fechamento
              </span>
              .
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Typography variant={'subtitle2'}>
              <span className={classes.label}>Total:</span>{' '}
              <strong>{moneyFormat(total)}</strong>
            </Typography>
            <Typography variant={'subtitle2'}>
              <span className={classes.label}>Pedidos:</span>{' '}
              <strong>{quantity}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <TextField
              placeholder={'Digite aqui sua observação'}
              required
              helperText={'Você deve fazer uma observação'}
              autoFocus
              label="Observação"
              multiline
              rows="4"
              margin="normal"
              variant="standard"
              value={this.state.observation}
              onChange={this.handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withUserData,
  withSnackbarContext
)(WeeklyClosing);
