import React from 'react';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import ClientesTableLoading from 'components/clientes/ClientesTableLoading';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import ClientesApi from 'services/ClientesApi';
import ClientesFilter from 'components/clientes/ClientesFilter';
import ClientesTable from 'components/clientes/ClientesTable';
import TableNoData from 'components/template/table/TableNoData';
import SimpleModal from 'components/template/modal/SimpleModal';
import Filter from 'components/filter/Filter';
import { compose } from 'recompose';
import ClientesAction from './ClientesAction';
import { ClientesFilterAction } from './ClientesFilterAction';
import { withSnackbarContext } from '../messages/MySnackBar';

const styles = {};

class Clientes extends React.Component {
  constructor(props) {
    super(props);

    document.title = 'Clientes';

    let filter;

    if (localStorage.getItem('filterClientes'))
      filter = JSON.parse(localStorage.getItem('filterClientes'));
    else
      filter = [
        { name: 'situacao', value: 'N', text: '' },
        { name: 'search', value: '', text: '' },
        { name: 'cidade', value: '', text: '' },
      ];

    this.state = {
      data: [],
      loading: false,
      messageType: '',
      message: '',
      isSnackOpen: '',
      filter,
      openSearch: false,
      loaded: false,
    };
  }

  handleOpenSearch = () => {
    this.setState({
      openSearch: true,
    });
  };

  handleCloseSearch = () => {
    this.setState({
      openSearch: false,
    });
  };

  handleFilterChange = type => e => {
    const newfilter = this.state.filter.map(item => {
      const newItem = item;

      if (item.name === e.target.name) {
        newItem.value = e.target.value;
        if (type === 'select') {
          newItem.text = e.currentTarget.textContent;
        } else if (type === 'input') newItem.text = e.target.value;
      }

      return newItem;
    });

    this.setState({ filter: newfilter });
    this.savefilterAtLocalStorage();
  };

  clearSelectedFilter = async name => {
    const newfilter = this.state.filter.map(item => {
      const newItem = item;

      if (item.name === name) {
        if (item.name === 'situacao') newItem.value = 'N';
        else newItem.value = '';

        newItem.text = '';
      }

      return newItem;
    });

    await this.setState({ filter: newfilter });
    this.savefilterAtLocalStorage();
    await this.getData();
  };

  hasFilter = () => {
    let hasfilter = false;
    this.state.filter.forEach(item => {
      if (item.text !== '') hasfilter = true;
    });

    return hasfilter;
  };

  setInitialStateFilterValue = async () => {
    await this.setState({
      filter: [
        { name: 'situacao', value: 'N', text: '' },
        { name: 'search', value: '', text: '' },
        { name: 'cidade', value: '', text: '' },
      ],
    });

    this.savefilterAtLocalStorage();

    await this.getData();
  };

  savefilterAtLocalStorage = () => {
    localStorage.setItem('filterClientes', JSON.stringify(this.state.filter));
  };

  getData = async () => {
    this.setState({
      loading: true,
    });

    const response = await ClientesApi.prototype.list(this.state.filter);
    const { mySnackbarContext } = this.props;

    if (response.type === 'SUCCESS') {
      if (response.qtd > 0)
        this.setState({
          data: response.data,
          loaded: true,
          loading: false,
        });
      else
        this.setState({
          data: [],
          loaded: false,
          loading: false,
        });
    } else if (response.type === 'ERROR') {
      this.setState({
        loaded: false,
        loading: false,
      });

      mySnackbarContext.handleOpen(response.message, response.type);
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  render() {
    const { loading, openSearch, loaded, data, filter } = this.state;

    return (
      <>
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar
              title="Clientes"
              appContext={context}
              ActionComponents={
                <ClientesAction handleOpenSearch={this.handleOpenSearch} />
              }
            />
          )}
        </AppContextConsumer>
        {openSearch && (
          <SimpleModal
            displayTitle
            title="Pesquisa"
            handleModalState={this.handleCloseSearch}
            ActionComponent={
              <ClientesFilterAction
                setInitialStateFilterValue={this.setInitialStateFilterValue}
                handleCloseSearch={this.handleCloseSearch}
              />
            }
          >
            <ClientesFilter
              submit={this.getData}
              filter={filter}
              handleFilterChange={this.handleFilterChange}
              clearSelectedFilter={this.clearSelectedFilter}
              setInitialStateFilterValue={this.setInitialStateFilterValue}
              handleCloseSearch={this.handleCloseSearch}
            />
          </SimpleModal>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {this.hasFilter() && (
              <Filter filter={filter} clear={this.clearSelectedFilter} />
            )}
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <ClientesTableLoading />
            ) : !loaded ? (
              <TableNoData />
            ) : (
              <ClientesTable data={data} />
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  withSnackbarContext
)(Clientes);
