import ApiRequest from 'services/ApiRequest';

class FormasPagamentoApi extends ApiRequest {
  async get() {
    const result = await this.doGet('formapagamento');
    return result;
  }
}

export default FormasPagamentoApi;
