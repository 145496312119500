import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#dc3545',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFC107',
      contrastText: '#fff',
    },
    error: {
      main: '#ff8100',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontSize: 14,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiInput: {
      root: {
        fontWeight: 300,
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: 300,
      },
    },
  },
});

export default Theme;
