import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  iconButton: {
    padding: 0,
  },
};

class MyAccountPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: [false, false, false],
      loading: false,
      isSnackbarOpen: false,
      message: '',
      messageType: '',
    };
  }

  handleClickShowPassword = position => {
    const showPassword = this.state.showPassword;

    showPassword[position] = !this.state.showPassword[position];

    this.setState({
      showPassword: showPassword,
    });
  };

  render() {
    const {
      classes,
      handleChange,
      password,
      newPassword,
      newPasswordConfirm,
      handleSubmitUpdatePassword,
      invalidPassword,
    } = this.props;

    return (
      <form onSubmit={handleSubmitUpdatePassword}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Typography variant={'h6'}>Altere sua senha.</Typography>
            <Typography>
              Informe sua senha atual e a nova senha desejada.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item md={6} lg={4} xl={3}>
              <TextField
                margin={'normal'}
                required
                variant={'standard'}
                value={password}
                onChange={handleChange}
                name={'password'}
                type={this.state.showPassword[0] ? 'text' : 'password'}
                label={'Senha atual'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Toggle password visibility"
                        onClick={() => this.handleClickShowPassword(0)}
                      >
                        {this.state.showPassword[0] ? (
                          <Tooltip title={'Esconder senha'}>
                            <Visibility />
                          </Tooltip>
                        ) : (
                          <Tooltip title={'Mostrar senha'}>
                            <VisibilityOff />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item md={6} lg={4} xl={3}>
              <TextField
                error={invalidPassword}
                helperText={invalidPassword ? 'Nova senha não confere' : ''}
                margin={'normal'}
                required
                variant={'standard'}
                value={newPassword}
                type={this.state.showPassword[1] ? 'text' : 'password'}
                onChange={handleChange}
                label={'Nova senha'}
                name={'newPassword'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Toggle password visibility"
                        onClick={() => this.handleClickShowPassword(1)}
                      >
                        {this.state.showPassword[1] ? (
                          <Tooltip title={'Esconder senha'}>
                            <Visibility />
                          </Tooltip>
                        ) : (
                          <Tooltip title={'Mostrar senha'}>
                            <VisibilityOff />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item md={6} lg={4} xl={3}>
              <TextField
                error={invalidPassword}
                helperText={invalidPassword ? 'Nova senha não confere' : ''}
                margin={'normal'}
                required
                variant={'standard'}
                value={newPasswordConfirm}
                onChange={handleChange}
                label={'Repita a nova senha'}
                type={this.state.showPassword[2] ? 'text' : 'password'}
                name={'newPasswordConfirm'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Toggle password visibility"
                        onClick={() => this.handleClickShowPassword(2)}
                      >
                        {this.state.showPassword[2] ? (
                          <Tooltip title={'Esconder senha'}>
                            <Visibility />
                          </Tooltip>
                        ) : (
                          <Tooltip title={'Mostrar senha'}>
                            <VisibilityOff />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(MyAccountPassword);
