import ApiRequest from 'services/ApiRequest';

class DashboardApi extends ApiRequest {
  async totalOrders() {
    const result = await this.doGet('dashboard/venda');
    return result;
  }

  async totalCommission() {
    const result = await this.doGet('dashboard/comissao');
    return result;
  }

  async chart() {
    const result = await this.doGet('dashboard/chart');
    return result;
  }

  async points() {
    const result = await this.doGet('dashboard/pontos');
    return result;
  }

  async commissionChart() {
    const result = await this.doGet('comissao/chart');
    return result;
  }
}

export default DashboardApi;
