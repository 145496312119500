import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  content: {
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },
});

class OrderNoItem extends React.Component {
  render() {
    const {
      classes,
      handleModalState,
      handleModalMixState,
      handleModalStockState,
      idCliente,
      mix,
    } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.content}>
            <Typography variant={'h4'}>
              Nenhum produto foi adicionado ao pedido
            </Typography>
            {idCliente !== '' ? (
              <Fragment>
                <Typography variant={'caption'}>
                  O que você deseja fazer?
                </Typography>
                <ul>
                  <li>
                    <Button
                      onClick={() => handleModalState()}
                      variant={'text'}
                      style={{ textTransform: 'none' }}
                      color={'primary'}
                    >
                      Incluir um produto
                    </Button>
                  </li>
                  {mix && (
                    <li>
                      <Button
                        onClick={() => handleModalMixState()}
                        style={{ textTransform: 'none' }}
                        variant={'text'}
                        color={'primary'}
                      >
                        Carregar mix
                      </Button>
                    </li>
                  )}
                  <li>
                    <Button
                      onClick={() => handleModalStockState()}
                      variant={'text'}
                      style={{ textTransform: 'none' }}
                      color={'primary'}
                    >
                      Lançar estoque
                    </Button>
                  </li>
                </ul>
              </Fragment>
            ) : (
              <Typography variant={'caption'}>
                Você precisa escolher um cliente antes de incluir algum produto.
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(OrderNoItem);
