import React, { Component } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import IconPerson from '@material-ui/icons/Person';
import IconBack from '@material-ui/icons/ArrowBack';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#ff7008',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: '#fff',
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'rgba(255,255,255,0.23)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'white',
        },
      },
      disabled: {},
      focused: {},
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: '#fff',
        '&$focused': {
          color: '#fff',
        },
      },
      focused: {},
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = theme => ({
  paper: {
    position: 'relative',
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 15px',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  logo: {
    width: 200,
  },
  logoContainer: {
    marginBottom: 40,
    textAlign: 'center',
  },
  inputContainer: {
    margin: '20px 0 25px',
  },
  iconPassword: {
    padding: 0,
  },
  buttonLogin: {
    textTransform: 'none',
  },
  iconBack: {
    padding: 5,
    position: 'absolute',
    top: 15,
  },
  bodyBackground: {
    backgroundImage: `radial-gradient(circle, ${theme.palette.primary.main} 35%, ${theme.palette.primary.dark})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
  },
  appName: {
    marginTop: 7,
    color: theme.palette.primary.contrastText,
  },
  inputRoot: {
    boxSizing: 'border-box',
  },
});

class MyPass extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    document.body.classList.add(classes.bodyBackground);

    this.state = {
      username: '',
    };
  }

  componentWillUnmount() {
    const { classes } = this.props;
    document.body.classList.remove(classes.backgroundColor);
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={11} md={6} lg={4} xl={3}>
            <div className={classes.paper}>
              <IconButton
                color="primary"
                component={Link}
                to={`${process.env.REACT_APP_ROOTPATH}login`}
                className={classes.iconBack}
              >
                <IconBack />
              </IconButton>
              <div className={classes.logoContainer}>
                <Link to={`${process.env.REACT_APP_ROOTPATH}login`}>
                  <img
                    className={classes.logo}
                    src={`${process.env.REACT_APP_MEDIAURL}images/logosf.png`}
                    alt="Logo São Francisco Utensílios de Limpeza"
                  />
                </Link>
              </div>
              <div>
                <Typography color="primary" variant="h6" align="center">
                  Redefinir senha
                </Typography>
                <Typography
                  color="primary"
                  variant="caption"
                  align="center"
                  component="p"
                >
                  Uma mensagem será enviada para o e-mail do usuário.
                </Typography>
                <form>
                  <Grid item xs={12} className={classes.inputContainer}>
                    <TextField
                      onChange={this.handleChange}
                      value={this.state.username}
                      autoFocus
                      name="username"
                      placeholder="Informe seu nome de usuário"
                      required
                      label="Nome de usuário"
                      fullWidth
                      autoComplete="off"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconPerson />
                          </InputAdornment>
                        ),
                        classes: { root: classes.inputRoot },
                      }}
                    />
                  </Grid>
                  <Grid xs={12} item container justify="center">
                    <Button
                      onClick={this.handleNext}
                      fullWidth
                      type="button"
                      color="primary"
                      variant="outlined"
                    >
                      Redefinir
                    </Button>
                  </Grid>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(MyPass);
