import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import SimpleLoading from 'components/loading/SimpleLoading';
import VendasApi from 'services/VendasApi';
import { withStyles } from '@material-ui/core/styles';
import ClientesApi from 'services/ClientesApi';
import OrderBasic from 'components/vendas/order/view/OrderBasic';
import OrderItems from 'components/vendas/order/view/OrderItems';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import { withRouter } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { OrderAction } from './OrderAction';
import { OrderTabs } from './OrderTabs';
import { compose } from 'recompose';
import { withSnackbarContext } from 'components/messages/MySnackBar';
import OrderViewTotal from 'components/vendas/order/view/OrderViewTotal';

const styles = theme => ({
  action: {
    margin: '40px 0 20px',
  },
  button: {
    marginRight: 10,
  },
  content: {
    padding: '45px 30px 0',
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '40px 20px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '45px 20px 0',
    },
  },
  paper: {
    width: '100%',
    margin: '0 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0px',
    },
  },
  title: {
    padding: 20,
    borderBottom: '1px solid #ccc',
  },
  tabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
  },
  appbar: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
});

export const OrderContext = React.createContext({});

class Order extends Component {
  constructor(props) {
    super(props);

    document.title = 'Carregando...';

    this.state = {
      loadindOrder: true,
      loadingCondicaoPagamento: true,
      loadingFormaPagamento: true,
      loadingCustomer: true,
      message: '',
      messageType: '',
      order: {
        id: '',
        idCliente: '',
        cliente: '',
        operacao: '',
        idRateio: '',
        idCondicao: '',
        idFormaPagamento: '',
        observacao: '',
        desconto: '',
        idCondicaoPagamento: '',
        items: [],
      },
      tabvalue: 0,
      isModalOpen: false,
      isSnackOpen: false,
      customerData: null,
      showTotal: false,
    };
  }

  updateState = value => {
    this.setState(value);
  };

  handleTabChange = (event, value) => {
    this.setState({ tabvalue: value });
  };

  handleCloseSnack = () => {
    this.setState({
      isSnackOpen: false,
    });
  };

  handleSetCliente = async (idCliente, cliente) => {
    await this.setState({
      order: {
        ...this.state.order,
        cliente: cliente,
        idCliente: idCliente,
      },
    });

    await this.loadDataCustomer();
  };

  loadDataCustomer = async () => {
    if (
      this.state.order.idCliente === '' ||
      typeof this.state.order.idCliente === 'undefined'
    ) {
      this.setState({
        customerData: null,
      });
      return false;
    }

    await this.setState({
      loadingCustomer: true,
      customerData: null,
    });

    const { mySnackbarContext } = this.props;

    const response = await ClientesApi.prototype.load(
      this.state.order.idCliente
    );

    if (response.type === 'SUCCESS') {
      this.setState({
        loadingCustomer: false,
        customerData: {
          id: response.customer.id,
          name: response.customer.name,
          address: response.customer.address,
          cnpj: response.customer.cnpj,
          city: response.customer.city,
          companyId: response.customer.companyId,
          companyFantasy: response.customer.companyFantasy,
        },
      });
    } else {
      this.setState({
        loadingCustomer: false,
        customerData: null,
      });

      mySnackbarContext.handleOpen(response.message, response.type);
    }
  };

  handleCopy = async () => {
    const { history } = this.props;

    await this.setState({
      order: {
        ...this.state.order,
        copied: true,
      },
    });

    const orderEncoded = await jwt.sign(
      JSON.stringify(this.state.order),
      process.env.REACT_APP_SECRET
    );
    await localStorage.setItem('orderCopied', orderEncoded);

    history.push(process.env.REACT_APP_ROOTPATH + 'orders/new');
  };

  async componentDidMount() {
    const { idCompany, idOrder } = this.props.match.params;

    const param = {
      idPedido: idOrder,
      idEmpresa: idCompany,
    };

    this.setState({
      loadingOrder: true,
    });

    const { mySnackbarContext } = this.props;

    const response = await VendasApi.prototype.load(param);

    if (response.type === 'SUCCESS') {
      this.setState({
        order: response.pedido,
        loadingOrder: false,
      });

      document.title =
        'Pedido ' + this.state.order.fantasia + '/' + this.state.order.idPedido;

      await this.loadDataCustomer();
    } else if (response.type === 'ERROR') {
      this.setState({
        isSnackOpen: true,
        loadingOrder: false,
      });

      mySnackbarContext.handleOpen(response.message, response.type);
    }
  }

  getUrlForPrinting = () => {
    return (
      process.env.REACT_APP_ROOTPATH +
      'orders/print/' +
      this.state.order.idEmpresa +
      '/' +
      this.state.order.idPedido
    );
  };

  handleShowTotal = () => {
    this.setState({
      showTotal: !this.state.showTotal,
    });
  };

  render() {
    const {
      loadingOperacao,
      loadingCustomer,
      loadingOrder,
      tabvalue,
      showTotal,
      order,
    } = this.state;

    const { classes } = this.props;

    return (
      <Fragment>
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar
              title={'Vendas'}
              appContext={context}
              ActionComponents={
                <OrderAction
                  getUrlForPrinting={this.getUrlForPrinting()}
                  handleCopy={this.handleCopy}
                  handleShowTotal={this.handleShowTotal}
                />
              }
              Tabs={
                <OrderTabs
                  tabvalue={tabvalue}
                  handleTabChange={this.handleTabChange}
                />
              }
            />
          )}
        </AppContextConsumer>
        {(loadingOrder || loadingOperacao || loadingCustomer) && (
          <SimpleLoading />
        )}
        {showTotal && (
          <OrderViewTotal order={order} onExited={this.handleShowTotal} />
        )}
        <Grid container spacing={0}>
          <div className={classes.paper}>
            <div>
              <div
                style={
                  tabvalue === 0 ? { display: 'block' } : { display: 'none' }
                }
              >
                <OrderContext.Provider
                  value={{
                    updateOrderState: this.updateState,
                  }}
                >
                  <OrderBasic {...this.state} />
                </OrderContext.Provider>
              </div>
              <div
                style={
                  tabvalue === 1 ? { display: 'block' } : { display: 'none' }
                }
              >
                <OrderItems {...this.state} />
              </div>
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withSnackbarContext,
  withRouter
)(Order);
