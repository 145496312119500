import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = {
  loadingLine1: {
    backgroundColor: '#eee',
    width: '30%',
    height: 15,
    marginBottom: 14,
    marginTop: 4,
  },
  loadingLine2: {
    backgroundColor: '#eee',
    width: '100%',
    height: 26,
    marginBottom: 6,
  },
  loadingLine3: { margin: '5px 0 0', borderTop: '1px solid #eee' },
  loadingLine4: {
    backgroundColor: '#eee',
    width: '50%',
    height: 10,
    marginTop: 8,
  },
};

const DashboardLoading = props => {
  return (
    <Paper style={{ padding: 13 }}>
      <div className={`animated-background ${props.classes.loadingLine1}`} />
      <div className={`animated-background ${props.classes.loadingLine2}`} />
      <div className={props.classes.loadingLine3}>
        <div className={`animated-background ${props.classes.loadingLine4}`} />
      </div>
    </Paper>
  );
};

export default withStyles(styles)(DashboardLoading);
