import React, { Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { isAuth } from 'services/user/Auth';

const styles = {
  container: {
    margin: '0 10px',
    height: 300,
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

class NotFound extends React.Component {
  render() {
    const { classes } = this.props;
    const authenticated = isAuth();

    return (
      <Fragment>
        {authenticated && (
          <AppContextConsumer>
            {({ context }) => <MyAppBar title={'404'} appContext={context} />}
          </AppContextConsumer>
        )}
        <Grid container className={classes.container}>
          <Typography variant={'h4'} color={'primary'} align={'center'}>
            Esta página está indisponível
          </Typography>
          <Typography align={'center'} variant={'caption'}>
            Talvez queria verificar a URL digitada.
            <Typography component={Link} to={'/dashboard'} color={'primary'}>
              Voltar ao início
            </Typography>
          </Typography>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(NotFound);
