import React, { Component } from 'react';
import { AppContextConsumer } from 'App';
import MyAppBar from '../template/appbar/MyAppBar';
import HomeAction from './HomeAction';

class Home extends Component {
  render() {
    return (
      <AppContextConsumer>
        {({ context }) => (
          <MyAppBar
            title="Home"
            appContext={context}
            ActionComponents={<HomeAction />}
          />
        )}
      </AppContextConsumer>
    );
  }
}

export default Home;
