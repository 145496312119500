import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import ptBrLocale from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';

function MyDatePicker({ value, onChange, label }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
      <KeyboardDatePicker
        clearLabel={'Limpar'}
        cancelLabel={'Cancelar'}
        invalidLabel={'Data inválida'}
        invalidDateMessage={'Data inválida'}
        minDateMessage={'Essa data está correta?'}
        maxDateMessage={'Essa data está correta?'}
        clearable
        fullWidth
        margin="normal"
        format="dd/MM/yyyy"
        label={label || 'Date Picker'}
        value={value}
        mask={value =>
          value
            ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
            : []
        }
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
}

MyDatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
};

export default MyDatePicker;
