import React, { Component, Fragment } from 'react';
import { moneyFormat, percentFormat, numberFormat } from 'helpers/NumberFormat';
import Typography from '@material-ui/core/Typography/Typography';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  chipPrice: {
    marginTop: 10,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '6px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
    position: 'relative',
    border: '2px solid #fff',
  },
  productName: {
    marginBottom: 7,
  },
  label: {
    width: 100,
    display: 'inline-block',
  },
});
class OrderTable extends Component {
  render() {
    const { items, classes } = this.props;

    return (
      <Fragment>
        <List className={classes.list}>
          {items.map(item => {
            return (
              <ListItem className={classes.listItem} key={item.id}>
                <Typography variant={'h6'}>
                  {item.productId} - {item.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <span className={classes.label}>Quantidade:</span>
                  {item.quantity}
                  {item.unit} ({numberFormat(item.secondQuantity)}
                  {item.secondUnit})
                </Typography>
                {item.discount > 0 && (
                  <Typography variant="body2" color="textSecondary">
                    <span className={classes.label}>Desconto:</span>
                    {percentFormat(parseFloat(item.discount))}
                  </Typography>
                )}
                <Typography variant="body2" color="textSecondary">
                  <span className={classes.label}>Preço:</span>
                  {moneyFormat(item.price)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <span className={classes.label}>Comissão:</span>
                  {moneyFormat(item.vlCommission)} (
                  {percentFormat(item.commission)})
                </Typography>
                <Typography>
                  <span className={classes.label}>Total:</span>
                  <strong>{moneyFormat(item.total)}</strong>
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrderTable);
