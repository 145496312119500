import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = {
  paper: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 250,
  },
};

const TableNoData = props => {
  const { classes } = props;
  return (
    <div className={classes.paper}>
      <Grid item xs={12}>
        <Typography align={'center'} variant={'h4'}>
          Nenhum registro foi carregado.
        </Typography>
        <Typography align={'center'} variant={'caption'} display="block">
          Dê uma olhadinha nos filtros.
        </Typography>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(TableNoData);
