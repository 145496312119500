import ApiRequest from 'services/ApiRequest';

class OperacoesApi extends ApiRequest {
  async get() {
    const result = await this.doGet('operacao');
    return result;
  }
}

export default OperacoesApi;
