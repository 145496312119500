import React, { Fragment } from 'react';
import MyAppBar from 'components/template/appbar/MyAppBar';
import Grid from '@material-ui/core/Grid';
import { AppContextConsumer } from 'App';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core';
import DashboardApi from 'services/DashboardApi';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import Chip from '@material-ui/core/Chip';
import { withSnackbarContext } from 'components/messages/MySnackBar';
import { makeCancelable } from 'helpers/PromiseCancelable';
import DashboardLoading from 'components/dashboard/DashboardLoading';
import { compose } from 'recompose';
import { withUserData } from 'components/user/UserData';
import { CommissionChart } from './CommissionChart';
import { SalesChart } from './SalesChart';

const styles = {
  paper: {
    padding: 15,
    position: 'relative',
    height: 85,
  },
  paperLinks: {
    padding: 15,
    position: 'relative',
  },
  paperChart: {
    width: '100%',
    position: 'relative',
    padding: '20px 10px 10px 10px',
    boxSizing: 'border-box',
  },
  DashItemDescription: {
    padding: '7px 0 0 0',
  },
  chip: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  colorChip: {
    backgroundColor: '#ffc107',
  },
  chipRoot: {
    textTransform: 'lowercase',
    borderRadius: 4,
    height: 25,
  },
  chipLabel: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  linearProgress: {
    marginTop: 20,
  },
  loadingChart: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%)',
  },
  detail: {
    marginTop: 5,
    padding: '6px 0 0',
    borderTop: '1px solid #eee',
  },
  percentCommission: {
    fontSize: 18,
  },
  row: {
    marginBottom: 10,
  },
};

const date = new Date();

let ordersRequest,
  commissionRequest,
  pointsRequest,
  salesChartRequest,
  commissionChartRequest;

class Dashboard extends React.Component {
  state = {
    totalOrders: 0,
    totalCommission: 0,
    percentCommission: 0,
    gift: 0,
    points: 0,
    goalPoints: 0,
    salesChartData: [],
    commissionChartData: [],
    loadingTotalOrders: true,
    loadingTotalCommission: true,
    loadingSalesChart: true,
    loadingCommissionChart: true,
    loadingPoints: true,
    heightCommissionChart: '400px',
    heightSalesChart: '400px',
  };

  componentWillUnmount() {
    ordersRequest.cancel();
    salesChartRequest.cancel();
    ordersRequest.cancel();
    pointsRequest.cancel();
  }

  componentDidMount() {
    ordersRequest = makeCancelable(DashboardApi.prototype.totalOrders());
    commissionRequest = makeCancelable(
      DashboardApi.prototype.totalCommission()
    );
    pointsRequest = makeCancelable(DashboardApi.prototype.points());
    salesChartRequest = makeCancelable(DashboardApi.prototype.chart());
    commissionChartRequest = makeCancelable(
      DashboardApi.prototype.commissionChart()
    );

    ordersRequest.promise.then(resolved => {
      if (resolved.type === 'SUCCESS') {
        this.setState({
          totalOrders: resolved.value,
          loadingTotalOrders: false,
        });
      } else if (resolved.type === 'ERROR') {
        this.setState({
          message: resolved.message,
          messageType: resolved.type,
          isSnackbarOpen: true,
          loadingTotalOrders: false,
        });
      }
    });

    commissionRequest.promise.then(commission => {
      if (commission.type === 'SUCCESS') {
        this.setState({
          totalCommission: commission.value,
          percentCommission: commission.percent,
          loadingTotalCommission: false,
        });
      } else if (commission.type === 'ERROR') {
        this.setState({
          message: commission.message,
          messageType: commission.type,
          isSnackbarOpen: true,
          loadingTotalCommission: false,
        });
      }
    });

    salesChartRequest.promise.then(chart => {
      if (chart.type === 'SUCCESS') {
        const chartData = chart.data.map(item => {
          return {
            name: item.name,
            Vendas: parseFloat(item.value),
          };
        });

        this.setState({
          salesChartData: chartData,
          loadingSalesChart: false,
        });

        this.setState({
          heightSalesChart: 'auto',
        });
      } else if (chart.type === 'ERROR') {
        this.setState({
          message: chart.message,
          messageType: chart.type,
          isSnackbarOpen: true,
          loadingChart: false,
          loadingSalesChart: false,
        });
      }
    });

    commissionChartRequest.promise.then(chart => {
      if (chart.type === 'SUCCESS') {
        const commissionChartData = chart.data.map(item => {
          return {
            name: item.name,
            Comissao: parseFloat(item.value),
          };
        });

        this.setState({
          commissionChartData: commissionChartData,
          loadingCommissionChart: false,
        });

        this.setState({
          heightCommissionChart: 'auto',
        });
      } else if (chart.type === 'ERROR') {
        this.setState({
          message: chart.message,
          messageType: chart.type,
          isSnackbarOpen: true,
          loadingCommissionChart: false,
        });
      }
    });

    pointsRequest.promise.then(points => {
      if (points.type === 'SUCCESS') {
        this.setState({
          loadingPoints: false,
          points: points.atingido,
          gift: points.premio,
          goalPoints: points.meta,
        });
      } else if (points.type === 'ERROR') {
        this.setState({
          message: points.message,
          messageType: points.type,
          isSnackbarOpen: true,
          loadingPoints: false,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { userData } = this.props;
    const {
      loadingSalesChart,
      salesChartData,
      commissionChartData,
      loadingCommissionChart,
      gift,
      goalPoints,
      loadingPoints,
      loadingTotalCommission,
      loadingTotalOrders,
      percentCommission,
      points,
      totalCommission,
      totalOrders,
      heightCommissionChart,
      heightSalesChart,
    } = this.state;

    return (
      <Fragment>
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar title={'Dashboard'} appContext={context} />
          )}
        </AppContextConsumer>
        <Grid container spacing={0}>
          <Grid item className={classes.row}>
            <Typography variant={'h6'}>
              Olá <strong>{userData.name}.</strong>
            </Typography>
            <Typography variant={'caption'}>
              Seja bem-vindo ao PedidoWeb.
            </Typography>
          </Grid>
          <Grid className={classes.row} container spacing={2}>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              {loadingTotalOrders ? (
                <DashboardLoading />
              ) : (
                <Paper className={classes.paper}>
                  <Typography>Pedidos</Typography>
                  <Typography
                    variant={'h5'}
                    className={classes.DashItemDescription}
                  >
                    {moneyFormat(totalOrders)}
                    <Chip
                      classes={{
                        root: classes.chipRoot,
                        label: classes.chipLabel,
                        colorPrimary: classes.colorChip,
                      }}
                      label={
                        date.toLocaleDateString('pt-BR', { month: 'short' }) +
                        '/' +
                        date.getFullYear()
                      }
                      color={'primary'}
                      className={classes.chip}
                    />
                  </Typography>
                  <div className={classes.detail}>
                    <Typography variant="caption">
                      Somente <strong>venda</strong>
                    </Typography>
                  </div>
                </Paper>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              {loadingTotalCommission ? (
                <DashboardLoading />
              ) : (
                <Paper className={classes.paper}>
                  <Typography>Comissão</Typography>
                  <Typography
                    variant={'h5'}
                    className={classes.DashItemDescription}
                  >
                    {moneyFormat(totalCommission)}
                    <span className={classes.percentCommission}>
                      {' '}
                      ({percentFormat(percentCommission)})
                    </span>
                    <Chip
                      classes={{
                        root: classes.chipRoot,
                        label: classes.chipLabel,
                        colorPrimary: classes.colorChip,
                      }}
                      label={
                        date.toLocaleDateString('pt-BR', { month: 'short' }) +
                        '/' +
                        date.getFullYear()
                      }
                      color={'primary'}
                      className={classes.chip}
                    />
                  </Typography>
                  <div className={classes.detail}>
                    <Typography variant="caption">
                      Faturados no mês corrente
                    </Typography>
                  </div>
                </Paper>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              {loadingPoints ? (
                <DashboardLoading />
              ) : (
                <Paper className={classes.paper}>
                  <Typography>Pontos</Typography>
                  <Typography
                    variant={'h5'}
                    className={classes.DashItemDescription}
                  >
                    {goalPoints + ' / ' + points}
                    <Chip
                      classes={{
                        root: classes.chipRoot,
                        label: classes.chipLabel,
                        colorPrimary: classes.colorChip,
                      }}
                      label={
                        date.toLocaleDateString('pt-BR', { month: 'short' }) +
                        '/' +
                        date.getFullYear()
                      }
                      color={'primary'}
                      className={classes.chip}
                    />
                  </Typography>
                  <div className={classes.detail}>
                    <Typography variant="caption">Meta / Atingido</Typography>
                  </div>
                </Paper>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3} xl={3}>
              {loadingPoints ? (
                <DashboardLoading />
              ) : (
                <Paper className={classes.paper}>
                  <Typography>Prêmio</Typography>
                  <Typography
                    variant={'h5'}
                    className={classes.DashItemDescription}
                  >
                    {moneyFormat(gift)}
                    <Chip
                      classes={{
                        root: classes.chipRoot,
                        label: classes.chipLabel,
                        colorPrimary: classes.colorChip,
                      }}
                      label={
                        date.toLocaleDateString('pt-BR', { month: 'short' }) +
                        '/' +
                        date.getFullYear()
                      }
                      color={'primary'}
                      className={classes.chip}
                    />
                  </Typography>
                  <div className={classes.detail}>
                    <Typography variant="caption">Metas atingidas</Typography>
                  </div>
                </Paper>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <SalesChart
                classes={classes}
                chartHeight={heightSalesChart}
                salesChartData={salesChartData}
                loadingSalesChart={loadingSalesChart}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <CommissionChart
                classes={classes}
                chartHeight={heightCommissionChart}
                loadingCommissionChart={loadingCommissionChart}
                commissionChartData={commissionChartData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withSnackbarContext,
  withUserData
)(Dashboard);
