import React, { Component } from 'react';
import {
  Dialog,
  Grid,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  modal: {
    overflowY: 'auto',
    padding: '0 30px 40px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px 40px !important',
    },
  },
  buttonClose: {
    position: 'absolute',
    top: 15,
    right: 10,
    zIndex: 1100,
  },
  root: {
    paddingRight: '0!important',
  },
  appbarSpace: {
    marginBottom: 64,
    [theme.breakpoints.down('md')]: {
      marginBottom: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      marginBottom: 51,
    },
  },
  grow: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: '20px 24px 24px',
  },
});

const SimpleModalContext = React.createContext({
  handleClose: () => {},
});

class SimpleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const {
      handleModalState,
      classes,
      title,
      ActionComponent,
      children,
      onClose,
    } = this.props;

    return (
      <Dialog
        classes={{ root: classes.root }}
        open={this.state.open}
        onClose={this.handleClose}
        fullScreen
        fullWidth
        onExited={() => handleModalState()}
      >
        {title && (
          <AppBar>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={onClose ? onClose : this.handleClose}
                aria-label="Close"
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant={'h6'} color={'inherit'} noWrap>
                {title}
              </Typography>
              <div className={classes.grow} />
              <div>{ActionComponent}</div>
            </Toolbar>
          </AppBar>
        )}
        <div className={classes.appbarSpace} />
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Grid item xs={12}>
            <SimpleModalContext.Provider
              value={{ handleClose: this.handleClose }}
            >
              {children}
            </SimpleModalContext.Provider>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

SimpleModal.propTypes = {
  handleModalState: PropTypes.func.isRequired,
  title: PropTypes.string,
  ActionComponent: PropTypes.element,
};

export default withStyles(styles)(SimpleModal);
export const SimpleModalConsumer = SimpleModalContext.Consumer;
export { SimpleModalContext };
