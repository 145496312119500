import React, { Fragment } from 'react';
import { withAppContext } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import Grid from '@material-ui/core/Grid';
import CommissionTableLoading from 'components/commission/CommisionTableLoading';
import TableNoData from 'components/template/table/TableNoData';
import SimpleModal from 'components/template/modal/SimpleModal';
import CommissionFilter from 'components/commission/CommissionFilter';
import Filter from 'components/filter/Filter';
import { dateFormat } from 'helpers/DateFormat';
import { CommissionAction } from './CommissionAction';
import { withSnackbarContext } from 'components/messages/MySnackBar';
import { compose } from 'recompose';
import { CommissionFilterAction } from './CommisionFilterAction';
import CommissionTable from './CommissionTable';
import CommissionApi from 'services/CommissionApi';
import CommissionTotal from './CommissionTotal';
import PropTypes from 'prop-types';

class Commission extends React.Component {
  constructor(props) {
    super(props);

    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();

    let initialDate = new Date(y, m, 1);
    let finalDate = new Date(y, m + 1, 0);

    let filter;

    if (localStorage.getItem('commission_filter'))
      filter = JSON.parse(localStorage.getItem('commission_filter'));
    else
      filter = [
        {
          name: 'dataInicial',
          value: initialDate,
          text: dateFormat(initialDate),
          type: 'date',
        },
        {
          name: 'dataFinal',
          value: finalDate,
          text: dateFormat(finalDate),
          type: 'date',
        },
        { name: 'search', value: '', text: '', type: 'normal' },
      ];

    this.state = {
      loading: false,
      filter: filter,
      openSearch: false,
      openTotals: false,
      loaded: false,
      data: [],
    };

    document.title = 'Comissão';
  }

  handleOpenSearch = () => {
    this.setState({
      openSearch: true,
    });
  };

  handleCloseSearch = () => {
    this.setState({
      openSearch: false,
    });
  };

  handleInitialDate = initialDate => {
    let filter = this.state.filter;
    filter[0].value = initialDate;
    filter[0].text = dateFormat(initialDate);
    this.setState({
      filter: filter,
    });

    this.savefilterAtLocalStorage();
  };

  handleFinalDate = finalDate => {
    let filter = this.state.filter;
    filter[1].value = finalDate;
    filter[1].text = dateFormat(finalDate);
    this.setState({
      filter: filter,
    });

    this.savefilterAtLocalStorage();
  };

  handleFilterChange = type => e => {
    let newfilter = this.state.filter.map(item => {
      let newItem = item;

      if (item.name === e.target.name) {
        newItem.value = e.target.value;
        if (type === 'select') {
          newItem.text = e.currentTarget.textContent;
        } else if (type === 'input') newItem.text = e.target.value;
      }

      return newItem;
    });

    this.setState({
      filter: newfilter,
    });

    this.savefilterAtLocalStorage();
  };

  clearSelectedFilter = async name => {
    let newfilter = this.state.filter.map(item => {
      var newItem = item;

      if (item.name === name) {
        if (item.type === 'date') {
          newItem.value = null;
        } else newItem.value = '';

        newItem.text = '';
      }

      return newItem;
    });

    await this.setState({ filter: newfilter });
    this.savefilterAtLocalStorage();
    await this.getData();
  };

  hasFilter = () => {
    let hasfilter = false;
    this.state.filter.forEach(item => {
      if (item.text !== '') hasfilter = true;
    });

    return hasfilter;
  };

  setInitialStateFilterValue = async () => {
    await this.setState({
      filter: [
        { name: 'dataInicial', value: null, text: '', type: 'date' },
        { name: 'dataFinal', value: null, text: '', type: 'date' },
        { name: 'search', value: '', text: '', type: 'normal' },
      ],
    });

    await this.getData();
    this.savefilterAtLocalStorage();
  };

  savefilterAtLocalStorage = () => {
    localStorage.setItem(
      'commission_filter',
      JSON.stringify(this.state.filter)
    );
  };

  componentDidMount() {
    this.getData();
  }

  prepareFilterToRequest = () => {
    return this.state.filter.map(item => {
      if (item.type === 'date') return { name: item.name, value: item.text };
      else return { name: item.name, value: item.value };
    });
  };

  getData = () => {
    this.setState({
      loading: true,
    });

    const { mySnackbarContext } = this.props;

    CommissionApi.prototype
      .get(this.prepareFilterToRequest())
      .then(response => {
        if (response.type === 'SUCCESS') {
          if (response.data.length > 0) {
            this.setState({
              loading: false,
              data: response.data,
              loaded: true,
            });
          }
        } else if (response.type === 'ERROR') {
          this.setState({
            loading: false,
            loaded: false,
          });

          mySnackbarContext.handleOpen(response.message, response.type);
        }
      })
      .catch(() => {
        this.setState({
          loaded: false,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleOpenTotals = () => {
    this.setState({
      openTotals: !this.state.openTotals,
    });
  };

  render() {
    const {
      loading,
      data,
      loaded,
      openSearch,
      filter,
      openTotals,
    } = this.state;

    const { context } = this.props;

    return (
      <Fragment>
        <MyAppBar
          title={'Comissão'}
          appContext={context}
          ActionComponents={
            <CommissionAction
              handleOpenSearch={this.handleOpenSearch}
              handleOpenTotal={this.handleOpenTotals}
            />
          }
        />
        {openSearch && (
          <SimpleModal
            title={'Pesquisa'}
            handleModalState={this.handleCloseSearch}
            ActionComponent={
              <CommissionFilterAction
                handleCloseSearch={this.handleCloseSearch}
                setInitialStateFilterValue={this.setInitialStateFilterValue}
              />
            }
          >
            <CommissionFilter
              submit={this.getData}
              filter={filter}
              filterChange={this.handleFilterChange}
              filterClear={this.clearSelectedFilter}
              handleFinalDate={this.handleFinalDate}
              handleInitialDate={this.handleInitialDate}
              filterInitialState={this.setInitialStateFilterValue}
              closeModalSearch={this.handleCloseSearch}
            />
          </SimpleModal>
        )}
        {openTotals && (
          <SimpleModal
            title={'Totais'}
            handleModalState={this.handleOpenTotals}
          >
            <CommissionTotal data={data} />
          </SimpleModal>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {this.hasFilter() && (
              <Filter filter={filter} clear={this.clearSelectedFilter} />
            )}
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <CommissionTableLoading />
            ) : !loaded ? (
              <TableNoData />
            ) : (
              <CommissionTable data={data} />
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

Commission.propTypes = {
  context: PropTypes.object,
  mySnackbarContext: PropTypes.object,
};

export default compose(
  withSnackbarContext,
  withAppContext
)(Commission);
