import React, { Fragment } from 'react';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';

export const OrderAddProductAction = props => {
  const { hasStock, showStock, handleChange } = props;

  return (
    <Fragment>
      {hasStock && (
        <FormControlLabel
          control={
            <Switch
              color={'secondary'}
              value={showStock}
              checked={showStock}
              onChange={handleChange}
            />
          }
          label={
            <Typography style={{ color: '#fff' }} variant={'caption'}>
              Estoque
            </Typography>
          }
        />
      )}
    </Fragment>
  );
};
