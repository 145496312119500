import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SimpleModal from '../template/modal/SimpleModal';
import { moneyFormat } from '../../helpers/NumberFormat';

const styles = {
  listItem: {
    paddingBottom: 0,
  },
};

const VendasTotal = props => {
  const { classes, data, handleShowTotal } = props;

  let total = 0;
  let commission = 0;

  data.forEach(item => {
    total += item.valor;
    commission += item.vrComissao;
  });

  return (
    <SimpleModal handleModalState={handleShowTotal} title="Total">
      <Grid item xs={12} xl={3} md={5} lg={3}>
        <List>
          <ListItem classes={{ root: classes.listItem }}>
            <ListItemText>Pedidos</ListItemText>
            <ListItemSecondaryAction>
              <Typography variant="body1">{data.length}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem classes={{ root: classes.listItem }}>
            <ListItemText>Comissão</ListItemText>
            <ListItemSecondaryAction>
              <Typography variant="body1">{moneyFormat(commission)}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem classes={{ root: classes.listItem }}>
            <ListItemText>Total</ListItemText>
            <ListItemSecondaryAction>
              <Typography variant="body1">
                <strong>{moneyFormat(total)}</strong>
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
    </SimpleModal>
  );
};

export default withStyles(styles)(VendasTotal);
