import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { moneyFormat } from 'helpers/NumberFormat';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  mb0: {
    marginBottom: 0,
  },
  productName: {
    marginBottom: 10,
  },
  listItem: {
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  star: {
    color: '#ffc107',
  },
  chipColor: {
    backgroundColor: '#17a2b8',
  },
};

class VendasListPrices extends Component {
  render() {
    const { prices, handleProductSelectToOrder, classes } = this.props;
    return (
      <>
        <Typography variant="caption">Escolha um produto abaixo</Typography>
        <List component="nav">
          {prices.map((item, index) => {
            return (
              <ListItem
                dense
                classes={{ root: classes.listItem }}
                onClick={() => handleProductSelectToOrder(item)}
                button
                key={index}
              >
                <Grid item xs={12}>
                  <Typography variant="caption">
                    Tabela {item.tablePriceId}
                  </Typography>
                  <Typography className={classes.productName} variant="h6">
                    {item.productId} - {item.description}
                  </Typography>
                  <Typography>
                    Preço: <strong>{moneyFormat(item.price)}</strong>
                  </Typography>
                  <Typography className={this.props.classes.mb0}>
                    Embalagem: {item.secondUnit}/{item.fator}
                  </Typography>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </>
    );
  }
}

export default withStyles(styles)(VendasListPrices);
