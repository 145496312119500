import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  chip: {
    marginRight: 5,
    marginBottom: 5,
  },
  container: {
    margin: '0 0 5px 0',
  },
};

class Filter extends React.Component {
  render() {
    const { filter, classes } = this.props;

    const filters = filter.map((item, index) => {
      if (item.text === '' || (item.value === '' || item.value === 'T'))
        return null;
      else
        return (
          <Chip
            color={'secondary'}
            className={classes.chip}
            label={item.text}
            key={index}
            onDelete={() => this.props.clear(item.name)}
          />
        );
    });

    return <div className={classes.container}>{filters}</div>;
  }
}

export default withStyles(styles)(Filter);
