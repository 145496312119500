import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CopyIcon from '@material-ui/icons/FileCopy';
import { Link } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';

export const OrderAction = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { handleShowTotal } = props;

  const showTotal = () => {
    handleShowTotal();
    setAnchorEl(null);
  };

  const { getUrlForPrinting, handleCopy } = props;
  return (
    <>
      <Tooltip title="Copiar">
        <IconButton onClick={handleCopy} color="inherit">
          <CopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Novo pedido">
        <IconButton
          component={Link}
          to={`${process.env.REACT_APP_ROOTPATH}orders/new`}
          color="inherit"
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Menu
        onClose={() => setAnchorEl(null)}
        id="menu-order-action"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={showTotal}>Ver total</MenuItem>
        <MenuItem component={Link} to={getUrlForPrinting}>
          Imprimir
        </MenuItem>
      </Menu>
      <IconButton
        color="inherit"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <MoreIcon />
      </IconButton>
    </>
  );
};
