import ApiRequest from 'services/ApiRequest';

class User extends ApiRequest {
  async login(param) {
    const result = await this.doPost('login', param);
    return result;
  }

  async updatePassword(param) {
    const result = await this.doPost('user/updatePassword', param);
    return result;
  }

  async updateEmail(param) {
    const result = await this.doGet('user/updateEmail', param);
    return result;
  }

  /**
   * Save token at the server
   * @param param
   * @returns {Promise<void>}
   */
  async saveTokenPushNotification(param) {
    const result = await this.doPost('pushnotification/saveToken', param);
    return result;
  }
}

export default User;
