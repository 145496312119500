import React, { Fragment } from 'react';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import { TablePagination } from '@material-ui/core';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { withRouter, Link } from 'react-router-dom';
import { dateFormat } from '../../helpers/DateFormat';

const styles = theme => ({
  tableHeader: {
    backgroundColor: theme.palette.secondary.dark,
    padding: 15,
    borderRadius: '4px 4px 0 0 ',
  },
  tableHeaderText: {
    color: '#fff',
  },
  list: {
    paddingTop: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 1px 1px #eee',
    borderRadius: 4,
  },
  listItemRazao: {
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '280px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  chipPrice: {
    marginTop: 10,
  },
  paper: {
    padding: '0px 10px',
  },
  A: {
    backgroundColor: '#6bc720',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
  },
  V: {
    backgroundColor: '#ff8100',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
  },
  P: {
    backgroundColor: '#17a2b8',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
  },
  label: {
    width: 80,
    display: 'inline-block',
  },
  dev: {
    color: theme.palette.error.main,
  },
});

const status = {
  A: 'Aberto',
};

class CommissionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      page: 0,
    };
  }

  handleChange = event => {
    this.setState({
      checked: event.target.checked,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getUrlOrderView = (idCompany, idOrder) => {
    return (
      process.env.REACT_APP_ROOTPATH +
      'orders/view/' +
      idCompany +
      '/' +
      idOrder
    );
  };

  render() {
    const { data } = this.props;
    const { page, rowsPerPage } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <List classes={{ root: classes.list }} component={'nav'}>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(item => {
              return (
                <ListItem
                  button
                  component={Link}
                  to={this.getUrlOrderView(item.F13_EMPRESA, item.F13_PEDIDO)}
                  className={classes.listItem}
                  item={item}
                  key={item.F13_ID}
                >
                  <Typography component={'p'} variant={'caption'}>
                    {item.X04_FANTASIA} / {item.F13_TIPOTITULO} /{' '}
                    <strong className={classes[item.F13_SITUACAO]}>
                      {status[item.F13_SITUACAO]}
                    </strong>
                  </Typography>
                  <Typography className={classes.listItemRazao} variant={'h6'}>
                    {item.G05_RAZAO}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <span className={classes.label}>Pedido:</span>
                    {item.F13_PEDIDO}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <span className={classes.label}>Data:</span>
                    {dateFormat(new Date(item.F13_DTCOMISSAO))}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <span className={classes.label}>Base:</span>
                    {moneyFormat(item.F13_BASE)}
                  </Typography>
                  <Typography>
                    <span className={classes.label}>Valor:</span>
                    <strong className={item.F13_VALOR < 0 ? classes.dev : null}>
                      {moneyFormat(item.F13_VALOR)}
                    </strong>{' '}
                    ({percentFormat(item.F13_ALIQUOTA)})
                  </Typography>
                </ListItem>
              );
            })}
        </List>
        <TablePagination
          labelRowsPerPage={'Registros'}
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Página anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'Próxima Página',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(CommissionTable));
