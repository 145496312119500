import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';

class SimpleDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { title, disableBackdropClick, displayTitle, children } = this.props;
    return (
      <Dialog
        disableBackdropClick={disableBackdropClick}
        open={this.state.open}
        onClose={this.handleClose}
      >
        {displayTitle && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  title: PropTypes.string,
  displayTitle: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
};

export default SimpleDialog;
