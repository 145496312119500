import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconPerson from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  actions: {
    marginTop: 20,
    marginBottom: 20,
  },
  textField: {
    boxSizing: 'border-box',
  },
};

class UsernameStep extends Component {
  render() {
    const { classes, handleChange, username, loading } = this.props;
    return (
      <Fragment>
        <Typography color={'primary'} align={'center'}>
          Seja bem-vindo ao PedidoWeb.
        </Typography>
        <TextField
          variant={'outlined'}
          autoFocus
          autoComplete={'off'}
          name="username"
          placeholder="Digite o nome de usuário"
          required
          onChange={handleChange}
          value={username}
          label={'Nome de usuário'}
          fullWidth
          margin={'normal'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconPerson color={'primary'} />
              </InputAdornment>
            ),
            classes: { root: classes.textField },
          }}
        />

        <div className={classes.actions}>
          <Button
            disabled={loading}
            fullWidth
            type="submit"
            color={'primary'}
            variant={'outlined'}
          >
            Próxima
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(UsernameStep);
