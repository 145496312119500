import React, { useState, Fragment } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem } from '@material-ui/core';

export const CommissionAction = React.memo(props => {
  const { handleOpenSearch, handleOpenTotal } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  function handleMenuOpen() {
    handleOpenTotal();
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <Tooltip title={'Pesquisa'}>
        <IconButton onClick={handleOpenSearch} color={'inherit'}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <IconButton color={'inherit'} onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleMenuOpen}>Ver total</MenuItem>
      </Menu>
    </Fragment>
  );
});
