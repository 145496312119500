import React, { Component, Fragment } from 'react';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
  },
};

class OrderStockSearchList extends Component {
  render() {
    const { handleProductStockSelect, list, classes } = this.props;

    return (
      <Fragment>
        <Typography variant={'caption'}>Escolha um produto abaixo</Typography>
        <List>
          {list.map(item => {
            return (
              <ListItem
                className={classes.listItem}
                onClick={() => handleProductStockSelect(item)}
                button
                key={item.productId}
              >
                <Typography variant={'h5'}>
                  {item.productId} - {item.description}
                </Typography>
                <Typography variant={'caption'}>
                  Embalagem:{' '}
                  <strong>
                    {item.secondUnit}/{item.fator}
                  </strong>
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrderStockSearchList);
