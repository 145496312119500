import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  listItemActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
};

class MyNavLink extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <NavLink {...this.props} exact activeClassName={classes.listItemActive} />
    );
  }
}

export default withStyles(styles)(MyNavLink);
