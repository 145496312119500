import ApiRequest from 'services/ApiRequest';

class ClientesApi extends ApiRequest {
  async load(id) {
    const param = {
      id: id,
    };

    const result = await this.doGet('cliente/load', param);
    return result;
  }

  async list(param) {
    const result = await this.doGet('cliente/getList', param);
    return result;
  }

  async setStatus(param) {
    const result = await this.doGet('cliente/setStatus', param);
    return result;
  }
}

export default ClientesApi;
