import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  buttonContent: {
    marginTop: 20,
    marginBottom: 20,
  },
  button: {
    marginRight: 7,
  },
};

class OrderDraft extends Component {
  render() {
    const { classes, handleDraft } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>
            Deseja recuperar o rascunho?
          </Typography>
          <div className={classes.buttonContent}>
            <Button
              onClick={() => handleDraft('D')}
              variant="text"
              color="secondary"
              className={classes.button}
            >
              Descartar
            </Button>
            <Button
              onClick={() => handleDraft('Y')}
              variant={'contained'}
              color={'primary'}
            >
              Sim
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(OrderDraft);
