import React, { Fragment } from 'react';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TitulosTableLoading from 'components/titulos/TitulosTableLoading';
import TitulosApi from 'services/TitulosApi';
import TitulosTable from 'components/titulos/TitulosTable';
import TableNoData from 'components/template/table/TableNoData';
import SimpleModal from 'components/template/modal/SimpleModal';
import TitulosFilter from 'components/titulos/TitulosFilter';
import Filter from 'components/filter/Filter';
import { dateFormat } from 'helpers/DateFormat';
import { TitulosActions } from './TitulosAction';
import { withSnackbarContext } from 'components/messages/MySnackBar';
import { compose } from 'recompose';
import { TitulosFilterAction } from './TitulosFilterAction';

const styles = {
  container: {
    padding: '0 10px',
  },
};

class Titulos extends React.Component {
  constructor(props) {
    super(props);

    let initialDate = new Date(),
      finalDate = new Date();

    finalDate.setDate(finalDate.getDate() + 60);
    initialDate.setDate(initialDate.getDate() - 90);

    let filter;

    if (localStorage.getItem('filterTitulos'))
      filter = JSON.parse(localStorage.getItem('filterTitulos'));
    else
      filter = [
        {
          name: 'dataInicial',
          value: initialDate,
          text: dateFormat(initialDate),
          type: 'date',
        },
        {
          name: 'dataFinal',
          value: finalDate,
          text: dateFormat(finalDate),
          type: 'date',
        },
        { name: 'situacao', value: 'T', text: '', type: 'normal' },
        { name: 'search', value: '', text: '', type: 'normal' },
        { name: 'empresa', value: '', text: '', type: 'normal' },
      ];

    this.state = {
      loading: false,
      filter: filter,
      openSearch: false,
      loaded: false,
    };

    document.title = 'Títulos';
  }

  handleOpenSearch = () => {
    this.setState({
      openSearch: true,
    });
  };

  handleCloseSearch = () => {
    this.setState({
      openSearch: false,
    });
  };

  handleInitialDate = initialDate => {
    let filter = this.state.filter;
    filter[0].value = initialDate;
    filter[0].text = dateFormat(initialDate);
    this.setState({
      filter: filter,
    });

    this.savefilterAtLocalStorage();
  };

  handleFinalDate = finalDate => {
    let filter = this.state.filter;
    filter[1].value = finalDate;
    filter[1].text = dateFormat(finalDate);
    this.setState({
      filter: filter,
    });

    this.savefilterAtLocalStorage();
  };

  handleFilterChange = type => async e => {
    let newfilter = this.state.filter.map(item => {
      let newItem = item;

      if (item.name === e.target.name) {
        newItem.value = e.target.value;
        if (type === 'select') {
          newItem.text = e.currentTarget.textContent;
        } else if (type === 'input') newItem.text = e.target.value;
      }

      return newItem;
    });

    await this.setState({ filter: newfilter });
    this.savefilterAtLocalStorage();
  };

  clearSelectedFilter = async name => {
    let newfilter = this.state.filter.map(item => {
      var newItem = item;

      if (item.name === name) {
        if (item.type === 'date') {
          newItem.value = null;
        } else if (item.name === 'situacao') newItem.value = 'T';
        else newItem.value = '';

        newItem.text = '';
      }

      return newItem;
    });

    await this.setState({ filter: newfilter });
    this.savefilterAtLocalStorage();
    await this.getData();
  };

  hasFilter = () => {
    let hasfilter = false;
    this.state.filter.forEach(item => {
      if (item.text !== '') hasfilter = true;
    });

    return hasfilter;
  };

  setInitialStateFilterValue = async () => {
    await this.setState({
      filter: [
        { name: 'dataInicial', value: null, text: '', type: 'date' },
        { name: 'dataFinal', value: null, text: '', type: 'date' },
        { name: 'situacao', value: 'T', text: '', type: 'normal' },
        { name: 'search', value: '', text: '', type: 'normal' },
        { name: 'empresa', value: '', text: '', type: 'normal' },
      ],
    });

    await this.getData();
    this.savefilterAtLocalStorage();
  };

  savefilterAtLocalStorage = () => {
    localStorage.setItem('filterTitulos', JSON.stringify(this.state.filter));
  };

  async componentDidMount() {
    await this.getData();
  }

  prepareFilterToRequest = () => {
    return this.state.filter.map(item => {
      if (item.type === 'date') return { name: item.name, value: item.text };
      else return { name: item.name, value: item.value };
    });
  };

  getData = async () => {
    await this.setState({
      loading: true,
    });

    const titulos = await TitulosApi.prototype.get(
      this.prepareFilterToRequest()
    );
    const { mySnackbarContext } = this.props;

    if (titulos.type === 'SUCCESS') {
      if (titulos.qtd > 0) {
        this.setState({
          loading: false,
          data: titulos.data,
          loaded: true,
        });
      } else
        this.setState({
          loading: false,
          loaded: false,
        });
    } else if (titulos.type === 'ERROR') {
      this.setState({
        loading: false,
        loaded: false,
      });

      mySnackbarContext.handleOpen(titulos.message, titulos.type);
    }
  };

  render() {
    const { loading, data, loaded, openSearch, filter } = this.state;

    return (
      <Fragment>
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar
              title={'Títulos'}
              appContext={context}
              ActionComponents={
                <TitulosActions handleOpenSearch={this.handleOpenSearch} />
              }
            />
          )}
        </AppContextConsumer>
        {openSearch && (
          <SimpleModal
            displayTitle
            title={'Pesquisa'}
            handleModalState={this.handleCloseSearch}
            ActionComponent={
              <TitulosFilterAction
                handleCloseSearch={this.handleCloseSearch}
                setInitialStateFilterValue={this.setInitialStateFilterValue}
              />
            }
          >
            <TitulosFilter
              submit={this.getData}
              filter={filter}
              filterChange={this.handleFilterChange}
              filterClear={this.clearSelectedFilter}
              handleFinalDate={this.handleFinalDate}
              handleInitialDate={this.handleInitialDate}
              filterInitialState={this.setInitialStateFilterValue}
              closeModalSearch={this.handleCloseSearch}
            />
          </SimpleModal>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {this.hasFilter() && (
              <Filter filter={filter} clear={this.clearSelectedFilter} />
            )}
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <TitulosTableLoading />
            ) : !loaded ? (
              <TableNoData />
            ) : (
              <TitulosTable data={data} />
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default compose(
  withSnackbarContext,
  withStyles(styles)
)(Titulos);
