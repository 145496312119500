import React, { useEffect, useState } from 'react';
import OperacoesApi from 'services/OperacoesApi';
import { TextField, MenuItem } from '@material-ui/core';

export const Operacoes = React.memo(props => {
  const [data, setData] = useState([]);
  const { change, idOperacao, disabled } = props;

  useEffect(() => {
    OperacoesApi.prototype.get().then(result => {
      if (result.type === 'SUCCESS') {
        setData(result.operacao);
      }
    });
  }, []);

  return (
    <TextField
      disabled={disabled}
      required
      select
      label="Tipo"
      value={data.length > 0 ? idOperacao : ''}
      onChange={e => change(e)}
      margin="normal"
      variant="standard"
      name="idOperacao"
      fullWidth={true}
    >
      {data.map(item => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.id} - {item.descricao}
          </MenuItem>
        );
      })}
    </TextField>
  );
});
