import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import CondicoesPagamentoApi from 'services/CondicoesPagamentosApi';

export const CondicaoPagamento = React.memo(props => {
  const [data, setData] = useState([]);
  const { change, idCondicaoPagamento, disabled } = props;

  /*const {updateOrderState} = useContext(ViewOrderBasicContext);*/

  useEffect(() => {
    CondicoesPagamentoApi.prototype.get().then(response => {
      if (response.type === 'SUCCESS') {
        setData(response.condicaopagamento);
      }
    });
  }, []);

  return (
    <TextField
      disabled={disabled}
      required
      select
      label="Condições de Pagamento"
      value={data.length > 0 ? idCondicaoPagamento : ''}
      onChange={change ? e => change(e) : null}
      margin="normal"
      variant="standard"
      fullWidth={true}
    >
      {data.map(item => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.id} - {item.descricao}
          </MenuItem>
        );
      })}
    </TextField>
  );
});

CondicaoPagamento.propTypes = {
  idCondicaoPagamento: PropTypes.string.isRequired,
  updateParentState: PropTypes.func,
};

export default CondicaoPagamento;
