import React, { Component, Fragment } from 'react';
import {
  CircularProgress,
  Collapse,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { FormasPagamento } from 'components/formas_pagamento/FormasPagamento';
import CondicaoPagamento from 'components/condicao_pagamento/CondicaoPagamento';
import ClienteData from 'components/clientes/ClienteData';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const styles = {
  paper: {
    width: '100%',
    padding: 30,
  },
  Aberto: {
    backgroundColor: '#6bc720',
  },
  Encerrado: {
    backgroundColor: '#17a2b8',
  },
  header: {
    margin: '10px 0',
  },
  chipRoot: {
    marginBottom: 10,
  },
};

class OrderBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogCustomerSearch: false,
    };
  }

  handleFocus = () => {
    this.setState({
      dialogCustomerSearch: !this.state.dialogCustomerSearch,
    });
  };

  render = () => {
    const {
      idOperacao,
      operacao,
      idFormaPagamento,
      idCondicaoPagamento,
      idRateio,
      desconto,
      observacao,
      cliente,
      idPedido,
      fantasia,
      situacao,
      dtEmissao,
    } = this.props.order;

    const { loadingCustomer, customerData, classes, loadingOrder } = this.props;

    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              {!loadingOrder && (
                <div className={classes.header}>
                  <Chip
                    classes={{
                      colorPrimary: classes[situacao],
                      root: classes.chipRoot,
                    }}
                    color={'primary'}
                    label={situacao}
                  />
                  <Typography variant={'h5'}>
                    Pedido {fantasia} / {idPedido}
                  </Typography>
                  <Typography variant={'caption'}>
                    Emitido em {dtEmissao}
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <TextField
                disabled
                required
                fullWidth
                label="Cliente"
                variant="standard"
                placeholder="Informe o cliente"
                value={cliente}
                margin="normal"
              />
            </Grid>
            <div>
              {loadingCustomer && <CircularProgress color={'primary'} />}
              {customerData && <ClienteData data={customerData} />}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid item xl={4} md={6} lg={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label={'Tipo'}
                value={operacao}
                margin={'normal'}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xl={4} md={6} lg={4} xs={12}>
              <TextField
                disabled
                required
                select
                variant="standard"
                label="Rateio"
                value={idRateio}
                fullWidth
                margin="normal"
              >
                <MenuItem value="4">4 - BRASIL</MenuItem>
                <MenuItem value="PD">PD - BRADESCO</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={idOperacao === '001' || idOperacao === '005'}>
              <Grid item xs={12}>
                <Grid item xl={4} md={6} lg={4} xs={12}>
                  <FormasPagamento
                    disabled
                    idFormaPagamento={idFormaPagamento}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xl={4} md={6} lg={4} xs={12}>
                  <CondicaoPagamento
                    disabled
                    idCondicaoPagamento={idCondicaoPagamento}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xl={4} md={6} lg={4} xs={12}>
                  <TextField
                    disabled
                    autoComplete={'off'}
                    variant="standard"
                    label="% Desconto"
                    name="desconto"
                    margin="normal"
                    value={desconto}
                    type={'number'}
                    placeholder="Informe o desconto"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <TextField
                disabled
                label="Observação"
                multiline
                rows="4"
                margin="normal"
                variant="standard"
                value={observacao}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };
}

export default withStyles(styles)(OrderBasic);
