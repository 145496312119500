import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import VendasApi from 'services/VendasApi';
import { Link, withRouter } from 'react-router-dom';
import SimpleLoading from 'components/loading/SimpleLoading';
import EmpresaApi from 'services/EmpresaApi';
import ClientesApi from 'services/ClientesApi';
import { moneyFormat, numberFormat, percentFormat } from 'helpers/NumberFormat';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/Add';

const styles = {
  container: {
    minWidth: 680,
    font: "12px 'trebuchet ms', sans-serif",
  },
  m0: {
    margin: 0,
  },
  image: {
    width: 150,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '0',
  },
  title: {
    fontSize: 18,
    margin: '12px 0',
    textAlign: 'center',
  },
  tableHeader: {
    padding: 10,
    backgroundColor: '#eee',
    border: '1px solid #000',
  },
  tableRow: {
    padding: 5,
    border: '1px solid #000',
  },
};

class OrderPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadindOrder: true,
      message: '',
      messageType: '',
      order: {
        id: '',
        idCliente: '',
        cliente: '',
        operacao: '',
        idRateio: '',
        idCondicao: '',
        idFormaPagamento: '',
        observacao: '',
        desconto: '',
        idCondicaoPagamento: '',
        items: [],
        invoice: [],
      },
      company: {
        fantasy: '',
        cep: '',
        neighboorHood: '',
        address: '',
        city: '',
        state: '',
        document: '',
      },
      customer: {},
      isSnackOpen: false,
    };
  }

  async componentDidMount() {
    document.title = 'Carregando...';

    const { idCompany, idOrder } = this.props.match.params;

    const paramOrder = {
      idPedido: idOrder,
      idEmpresa: idCompany,
    };

    const paramCompany = {
      id: idCompany,
    };

    this.setState({
      loadingOrder: true,
    });

    const responseOrder = await VendasApi.prototype.load(paramOrder);
    const responseCompany = await EmpresaApi.prototype.load(paramCompany);

    if (responseOrder.type === 'SUCCESS') {
      await this.setState({
        order: responseOrder.pedido,
      });

      document.title =
        'Pedido ' + this.state.order.fantasia + '/' + this.state.order.idPedido;
    } else if (responseOrder.type === 'ERROR') {
      this.setState({
        message: responseOrder.message,
        messageType: responseOrder.type,
        isSnackOpen: true,
        loadingOrder: false,
      });
    }

    if (responseCompany.type === 'SUCCESS') {
      this.setState({
        company: responseCompany.company,
      });
    } else if (responseCompany.type === 'ERROR') {
      this.setState({
        message: responseCompany.message,
        messageType: responseCompany.type,
        isSnackOpen: true,
        loadingOrder: false,
      });
    }

    const responseCustomer = await ClientesApi.prototype.load(
      this.state.order.idCliente
    );

    if (responseCustomer.type === 'SUCCESS') {
      this.setState({
        loadingOrder: false,
        customer: responseCustomer.customer,
      });
    } else {
      this.setState({
        loadingOrder: false,
        message: responseCustomer.message,
        messageType: responseCustomer.type,
        isSnackOpen: true,
      });
    }

    window.print();
  }

  render() {
    const { classes } = this.props;
    const { order, company, customer } = this.state;
    let total = 0,
      totalFinal = 0,
      discount,
      icmsSt = 0;

    order.items.forEach(item => {
      total += item.totalbruto;
      totalFinal += item.totalFinal;
      icmsSt += item.icmsSt;
    });

    discount = total - totalFinal;

    return (
      <Fragment>
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar
              title={'Vendas'}
              appContext={context}
              ActionComponents={
                <Fragment>
                  <IconButton onClick={window.print} color={'inherit'}>
                    <PrintIcon />
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={process.env.REACT_APP_ROOTPATH + 'orders/new'}
                    color={'inherit'}
                  >
                    <AddIcon />
                  </IconButton>
                </Fragment>
              }
            />
          )}
        </AppContextConsumer>
        <div className={classes.container}>
          {this.state.loadingOrder && <SimpleLoading />}
          <table className={classes.table}>
            <tbody>
              <tr>
                <td>
                  <img
                    alt={'Logo São Francisco'}
                    className={classes.image}
                    src={process.env.REACT_APP_MEDIAURL + 'images/logosf.png'}
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <p className={classes.m0}>{company.fantasy}</p>
                  <p className={classes.m0}>
                    {company.address}, {company.number}
                  </p>
                  <p className={classes.m0}>
                    {company.cep} - {company.state}
                  </p>
                  <p className={classes.m0}>{company.document}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className={classes.title}>
            <strong>
              Pedido {this.state.order.idPedido} - {this.state.order.dtEmissao}
            </strong>
          </p>
          <p style={{ paddingBottom: 7, marginBottom: 0, marginTop: 20 }}>
            <strong>Cliente ({this.state.order.idCliente})</strong>
          </p>
          <table style={{ border: '1px solid #000' }} className={classes.table}>
            <tbody>
              <tr>
                <td style={{ width: 80, padding: '5px 0 0 5px' }}>
                  <strong>Razão social</strong>
                </td>
                <td>{customer.name}</td>
              </tr>
              <tr>
                <td style={{ width: 80, paddingLeft: 5 }}>
                  <strong>Endereço</strong>
                </td>
                <td>
                  {customer.address}, {customer.addressNumber} / {customer.city}{' '}
                  - {customer.state}
                </td>
              </tr>
              <tr>
                <td style={{ width: 80, paddingLeft: 5 }}>
                  <strong>CNPJ</strong>
                </td>
                <td>{customer.cnpj}</td>
              </tr>
              <tr>
                <td style={{ width: 80, padding: '0 0 5px 5px' }}>
                  <strong>Email</strong>
                </td>
                <td>{customer.mail ? customer.mail : 'Não informado'}</td>
              </tr>
            </tbody>
          </table>
          <p style={{ paddingBottom: 7, marginTop: 20, marginBottom: 0 }}>
            <strong>Condições</strong>
          </p>
          <table
            style={{ paddingBottom: 7, border: '1px solid #000' }}
            className={classes.table}
          >
            <tbody>
              <tr>
                <td style={{ width: 150, padding: '5px 0 0 5px' }}>
                  <strong>Tipo:</strong>
                </td>
                <td>{order.operacao}</td>
              </tr>
              <tr>
                <td style={{ width: 150, paddingLeft: 5 }}>
                  <strong>Série:</strong>
                </td>
                <td>{order.idRateio}</td>
              </tr>
              {order.desconto > 0 && (
                <tr>
                  <td style={{ width: 150, paddingLeft: 5 }}>
                    <strong>Desconto:</strong>
                  </td>
                  <td>{percentFormat(order.desconto)}</td>
                </tr>
              )}
              {order.descricaoCondicaoPagamento && (
                <tr>
                  <td style={{ width: 150, paddingLeft: 5 }}>
                    <strong>Condição de pagamento:</strong>
                  </td>
                  <td>{order.descricaoCondicaoPagamento}</td>
                </tr>
              )}
              {order.invoice.length > 0 && (
                <tr>
                  <td style={{ width: 150, padding: '0 0 5px 5px' }}>
                    <strong>Fatura:</strong>
                  </td>
                  <td>
                    {order.invoice.map((item, index) => {
                      return (
                        <p key={index} className={classes.m0}>
                          {item.paymentDay} -{' '}
                          <strong>{moneyFormat(item.value)}</strong> -{' '}
                          {item.description}
                        </p>
                      );
                    })}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <p style={{ paddingBottom: 7, marginTop: 20, marginBottom: 0 }}>
            <strong>Itens do Pedido</strong>
          </p>
          <table className={classes.table} style={{ border: '1px solid #000' }}>
            <tbody>
              <tr>
                <td className={classes.tableHeader}>Código</td>
                <td className={classes.tableHeader}>Descrição</td>
                <td className={classes.tableHeader}>Quantidade</td>
                <td className={classes.tableHeader}>Unidade</td>
                <td className={classes.tableHeader}>Valor</td>
                <td className={classes.tableHeader}>Desconto</td>
                <td className={classes.tableHeader}>Subtotal</td>
              </tr>
              {order.items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={classes.tableRow}>{item.productId}</td>
                    <td className={classes.tableRow}>{item.description}</td>
                    <td
                      style={{ textAlign: 'right' }}
                      className={classes.tableRow}
                    >
                      {numberFormat(item.quantity)}
                    </td>
                    <td
                      style={{ textAlign: 'center' }}
                      className={classes.tableRow}
                    >
                      {item.unit}
                    </td>
                    <td
                      style={{ textAlign: 'right' }}
                      className={classes.tableRow}
                    >
                      {moneyFormat(item.price)}
                    </td>
                    <td
                      style={{ textAlign: 'right' }}
                      className={classes.tableRow}
                    >
                      {percentFormat(item.discount)}
                    </td>
                    <td
                      style={{ textAlign: 'right' }}
                      className={classes.tableRow}
                    >
                      {moneyFormat(item.total)}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td
                  style={{
                    marginTop: 10,
                    backgroundColor: '#eee',
                    textAlign: 'right',
                    border: '1px solid #000',
                    padding: 5,
                  }}
                  colSpan={'6'}
                >
                  <p className={classes.m0}>Total produtos</p>
                  <p className={classes.m0}>Desconto</p>
                  <p className={classes.m0}>ICMS ST</p>
                  <p className={classes.m0}>Total</p>
                </td>
                <td style={{ textAlign: 'right', padding: 5 }}>
                  <p className={classes.m0}>{moneyFormat(total)}</p>
                  <p className={classes.m0}>{moneyFormat(discount)}</p>
                  <p className={classes.m0}>{moneyFormat(icmsSt)}</p>
                  <p className={classes.m0}>
                    {moneyFormat(totalFinal + icmsSt)}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(OrderPrint));
