import React, { Fragment } from 'react';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconCheck from '@material-ui/icons/Check';
import PropTypes from 'prop-types';

const styles = theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
  },
  listItemProductInserted: {
    backgroundColor: theme.palette.secondary.main,
    display: 'block',
    marginBottom: 7,
    boxShadow: '1px 1px 1px 1px #eee',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  icon: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
});

class OrderStockList extends React.Component {
  render() {
    const { stock, classes, handleClick } = this.props;
    const { orderItems } = this.props;
    return (
      <Fragment>
        <Typography variant={'caption'}>Escolha um produto abaixo</Typography>
        <List>
          {stock.map(item => {
            let productInserted = false;
            let productInsertedQuantity = 0;

            orderItems.forEach(_item => {
              if (item.productId === _item.productId) {
                productInserted = true;
                productInsertedQuantity = _item.quantity;
              }
            });

            return (
              <ListItem
                className={
                  productInserted
                    ? classes.listItemProductInserted
                    : classes.listItem
                }
                button
                key={item.productId}
                onClick={() => handleClick(item)}
              >
                <Typography variant={'h5'}>
                  {item.productId} - {item.description}
                </Typography>
                <Typography variant={'caption'}>
                  Estoque: {item.quantity} {item.unit}
                </Typography>
                {productInserted && (
                  <Fragment>
                    <Typography variant={'caption'}>
                      Pedido:{' '}
                      <strong>
                        {productInsertedQuantity} {item.unit}
                      </strong>
                    </Typography>
                    <Typography style={{ marginTop: 10 }} variant={'caption'}>
                      <strong>Produto no pedido</strong>
                    </Typography>
                    <IconCheck color={'primary'} className={classes.icon} />
                  </Fragment>
                )}
              </ListItem>
            );
          })}
        </List>
      </Fragment>
    );
  }
}

OrderStockList.propTypes = {
  handleClick: PropTypes.func,
  stock: PropTypes.array,
  orderItems: PropTypes.array,
};

export default withStyles(styles)(OrderStockList);
