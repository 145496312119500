import ApiRequest from 'services/ApiRequest';

class CondicoesPagamentosApi extends ApiRequest {
  async get() {
    const result = await this.doGet('condicaopagamento');
    return result;
  }
}

export default CondicoesPagamentosApi;
