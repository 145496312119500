import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import OrderStockSearchList from 'components/vendas/order/new/OrderStockSearchList';
import SearchLoading from 'components/loading/SearchLoading';
import SimpleModal from 'components/template/modal/SimpleModal';
import OrderStockList from './OrderStockList';
import { OrderStockAction } from './OrderStockAction';
import OrderStockForm from './OrderStockForm';

const styles = {
  buttons: {
    marginTop: 25,
    marginRight: 10,
  },
  productInfo: {
    marginBottom: 20,
  },
  button: {
    marginRight: 7,
  },
};

class OrderStock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStock: false,
    };
  }

  getStock = () => {
    const { order, stock } = this.props;
    return stock[order.idCliente];
  };

  hasStock = () => {
    const { order, stock } = this.props;
    return !!stock[order.idCliente];
  };

  handleChange = () => {
    this.setState({
      showStock: !this.state.showStock,
    });
  };

  render() {
    const {
      productStockSelected,
      handleSearchProductStockChange,
      loadingProductSearch,
      handleProductStockSelect,
      handleProductStockSave,
      handleProductStockSelected,
      stockSearch,
      order,
      onExited,
      productStockSelectedData,
      handleProductStockSelectedChange,
    } = this.props;

    const showStock = this.state.showStock;
    const hasStock = this.hasStock();
    let stock = hasStock && this.getStock();

    return (
      <Fragment>
        <SimpleModal
          handleModalState={onExited}
          title={'Incluir estoque'}
          ActionComponent={
            <OrderStockAction
              productStockSelected={productStockSelected}
              hasStock={hasStock}
              handleChange={this.handleChange}
              showStock={showStock}
            />
          }
        >
          {!productStockSelected && (
            <Fragment>
              {!showStock && (
                <Fragment>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      onChange={handleSearchProductStockChange}
                      margin="normal"
                      label="Pesquisar produto"
                      placeholder="Digite o código do produto"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {loadingProductSearch && <SearchLoading />}
                  <Grid item xs={12}>
                    {stockSearch.length > 0 && (
                      <OrderStockSearchList
                        list={stockSearch}
                        handleProductStockSelect={handleProductStockSelect}
                      />
                    )}
                  </Grid>
                </Fragment>
              )}
              {showStock && hasStock && (
                <OrderStockList
                  handleClick={handleProductStockSelect}
                  orderItems={order.items}
                  stock={stock}
                />
              )}
            </Fragment>
          )}
        </SimpleModal>
        {productStockSelected && (
          <SimpleModal
            handleModalState={() => handleProductStockSelected(false)}
          >
            <OrderStockForm
              productStockSelectedData={productStockSelectedData}
              handleProductStockSave={handleProductStockSave}
              handleProductStockSelectedChange={
                handleProductStockSelectedChange
              }
            />
          </SimpleModal>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(OrderStock);
