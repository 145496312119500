import React, { Fragment } from 'react';
import { moneyFormat } from 'helpers/NumberFormat';
import { TablePagination } from '@material-ui/core';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  tableHeader: {
    backgroundColor: theme.palette.secondary.dark,
    padding: 15,
    borderRadius: '4px 4px 0 0 ',
  },
  tableHeaderText: {
    color: '#fff',
  },
  list: {
    paddingTop: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 1px 1px #eee',
    borderRadius: 4,
  },
  listItemRazao: {
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '280px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  chipPrice: {
    marginTop: 10,
  },
  paper: {
    padding: '0px 10px',
  },
  A: {
    backgroundColor: '#6bc720',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
  },
  V: {
    backgroundColor: '#ff8100',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
  },
  P: {
    backgroundColor: '#17a2b8',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
  },
  label: {
    width: 80,
    display: 'inline-block',
  },
});

class TitulosTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      itemChecked: [],
      rowsPerPage: 10,
      page: 0,
    };
  }

  handleChange = event => {
    this.setState({
      checked: event.target.checked,
      itemChecked: this.setAllItemChecked(event.target.checked),
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { data } = this.props;
    const { page, rowsPerPage } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <List classes={{ root: classes.list }} component={'nav'}>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(item => {
              return (
                <ListItem
                  button
                  className={classes.listItem}
                  item={item}
                  key={item.id}
                >
                  <Typography variant={'caption'} display="block">
                    {item.fantasia} / {item.numeroDocumento}-{item.parcela} /{' '}
                    <strong className={classes[item.codSituacao]}>
                      {item.situacao}
                    </strong>
                  </Typography>
                  <Typography className={classes.listItemRazao} variant={'h6'}>
                    {item.idCliente} - {item.razao}
                  </Typography>
                  {item.saldo > 0 && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      display="block"
                    >
                      <span className={classes.label}>Saldo:</span>
                      {moneyFormat(item.saldo)}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    display="block"
                  >
                    <span className={classes.label}>Vencimento:</span>
                    {item.vencimento}
                  </Typography>
                  <Typography>
                    <span className={classes.label}>Valor:</span>
                    <strong>{moneyFormat(item.valor)}</strong>
                  </Typography>
                </ListItem>
              );
            })}
        </List>
        <TablePagination
          labelRowsPerPage={'Registros'}
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Página anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'Próxima Página',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(TitulosTable));
