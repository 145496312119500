import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  loading: {
    top: '30%',
    transform: 'translate(-50%)',
    fontSize: 16,
    color: '#fff',
    zIndex: 1301,
    padding: 15,
    textAlign: 'center',
    position: 'fixed',
    left: '50%',
    width: 200,
  },
};

const SearchLoading = props => {
  const { classes } = props;
  return (
    <div className={classes.loading}>
      <CircularProgress color={'primary'} />
    </div>
  );
};

export default withStyles(styles)(SearchLoading);
