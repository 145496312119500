import React from 'react';
import { Tab, Tabs } from '@material-ui/core';

export const MyAccountTabs = props => {
  const { tabvalue, handleTabChange } = props;
  return (
    <Tabs value={tabvalue} onChange={handleTabChange}>
      <Tab label="Conta" />
      <Tab label="Senha" />
    </Tabs>
  );
};
