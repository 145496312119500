import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh';

export const TitulosFilterAction = props => {
  const { handleCloseSearch, setInitialStateFilterValue } = props;
  return (
    <div>
      <Tooltip title={'Limpar'}>
        <IconButton onClick={setInitialStateFilterValue} color={'inherit'}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Pesquisa'}>
        <IconButton onClick={handleCloseSearch} color={'inherit'}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
