import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import IconDone from '@material-ui/icons/Done';

export const MyAccountAction = props => {
  const {
    tabvalue,
    loading,
    currentEmail,
    email,
    handleSubmitUpdateEmail,
    invalidPassword,
    handleSubmitUpdatePassword,
    password,
    newPassword,
    newPasswordConfirm,
  } = props;

  return (
    <div>
      {tabvalue === 0 && (
        <IconButton
          disabled={loading || currentEmail === email}
          onClick={handleSubmitUpdateEmail}
          color={'inherit'}
        >
          <IconDone />
        </IconButton>
      )}
      {tabvalue === 1 && (
        <IconButton
          disabled={
            loading ||
            (invalidPassword ||
              !password ||
              !newPassword ||
              !newPasswordConfirm)
          }
          onClick={handleSubmitUpdatePassword}
          color={'inherit'}
        >
          <IconDone />
        </IconButton>
      )}
    </div>
  );
};
