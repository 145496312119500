import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CustomerIcon from '@material-ui/icons/SupervisorAccount';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import StoreIcon from '@material-ui/icons/Store';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import MessageIcon from '@material-ui/icons/Message';
import { useHistory } from 'react-router-dom';
import MyNavLink from 'components/template/navlink/MyNavLink';
import UserDataToken from 'helpers/UserDataToken';

const ROOTPATH = process.env.REACT_APP_ROOTPATH;

const drawerSize = 239;

const useStyles = makeStyles(theme => ({
  listItemActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerSize,
    backgroundColor: theme.palette.secondary.main,
    '@media print': {
      display: 'none',
    },
  },
  listItemIcon: {
    color: theme.palette.primary.contrastText,
  },
  listItemText: {},
  drawerHeader: {
    paddingTop: 65,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.primary.main,
      padding: 18,
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.primary.main,
      padding: 22,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.secondary.main,
      padding: 32,
    },
  },
  drawerHeaderTitle: {
    color: theme.palette.primary.contrastText,
  },
  logo: {
    width: '150px',
  },
  expandColor: {
    color: '#fff',
  },
  nested: {
    paddingLeft: 40,
  },
  entered: {
    minHeight: '160px!important',
  },
}));

function SideBar({ mobile, handleOpen, open, windowWidth }) {
  const userDataToken = new UserDataToken();
  const [openNavOrders, setOpenNavOrder] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  function handleClick() {
    if (mobile || windowWidth <= 1280) handleOpen();
  }

  function handleOpenNavOrders() {
    setOpenNavOrder(!openNavOrders);
  }

  function logout() {
    localStorage.clear();
    history.push(process.env.REACT_APP_ROOTPATH + 'login');
    document.body.classList.remove('zoom');
  }

  return (
    <Drawer
      variant={mobile || windowWidth <= 1280 ? 'temporary' : 'persistent'}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      open={open}
      onClose={handleOpen}
    >
      <div className={classes.drawerHeader}>
        {(mobile || windowWidth <= 1280) && (
          <Typography className={classes.drawerHeaderTitle} variant={'caption'}>
            {process.env.REACT_APP_TITLE}
          </Typography>
        )}
      </div>
      <ListItem
        component={MyNavLink}
        to={ROOTPATH + 'dashboard'}
        onClick={handleClick}
        button
      >
        <ListItemIcon className={classes.listItemIcon}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Dashboard"
        />
      </ListItem>
      <ListItem button onClick={handleOpenNavOrders}>
        <ListItemIcon className={classes.listItemIcon}>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Vendas"
        />
        {openNavOrders ? (
          <ExpandLess className={classes.expandColor} />
        ) : (
          <ExpandMore className={classes.expandColor} />
        )}
      </ListItem>
      <Collapse
        classes={{ entered: classes.entered }}
        in={openNavOrders}
        timeout="auto"
      >
        <List>
          <ListItem
            disableGutters
            component={MyNavLink}
            to={ROOTPATH + 'orders'}
            onClick={handleClick}
            className={classes.nested}
            button
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={'Pedidos'}
            />
          </ListItem>
          <ListItem
            disableGutters
            component={MyNavLink}
            to={ROOTPATH + 'weeklyclosing'}
            onClick={handleClick}
            className={classes.nested}
            button
          >
            <ListItemIcon>
              <DoneIcon className={classes.listItemIcon} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={'Fechamento'}
            />
          </ListItem>
          <ListItem
            disableGutters
            component={MyNavLink}
            to={ROOTPATH + 'orders/new'}
            onClick={handleClick}
            className={classes.nested}
            button
          >
            <ListItemIcon>
              <AddIcon className={classes.listItemIcon} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={'Novo pedido'}
            />
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        component={MyNavLink}
        to={ROOTPATH + 'customers'}
        onClick={handleClick}
        button
      >
        <ListItemIcon className={classes.listItemIcon}>
          <CustomerIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Clientes"
        />
      </ListItem>
      <ListItem
        component={MyNavLink}
        to={ROOTPATH + 'invoices'}
        onClick={handleClick}
        button
      >
        <ListItemIcon className={classes.listItemIcon}>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Títulos"
        />
      </ListItem>
      <ListItem
        component={MyNavLink}
        to={ROOTPATH + 'commission'}
        onClick={handleClick}
        button
      >
        <ListItemIcon className={classes.listItemIcon}>
          <MoneyIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Comissão"
        />
      </ListItem>
      {userDataToken.getPermission() >= 5 && (
        <ListItem
          component={MyNavLink}
          to={ROOTPATH + 'messaging'}
          onClick={handleClick}
          button
        >
          <ListItemIcon className={classes.listItemIcon}>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Mensagens"
          />
        </ListItem>
      )}
      <ListItem
        component={MyNavLink}
        to={ROOTPATH + 'my-account'}
        onClick={handleClick}
        button
      >
        <ListItemIcon className={classes.listItemIcon}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Minha conta"
        />
      </ListItem>
      <ListItem onClick={logout} button>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Sair"
        />
      </ListItem>
    </Drawer>
  );
}

export default SideBar;
