import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import SimpleLoading from 'components/loading/SimpleLoading';
import VendasApi from 'services/VendasApi';
import { withStyles } from '@material-ui/core/styles';
import ClientesApi from 'services/ClientesApi';
import OrderBasic from 'components/vendas/order/new/OrderBasic';
import OrderItems from 'components/vendas/order/new/OrderItems';
import OrderDraft from 'components/vendas/order/new/OrderDraft';
import SimpleDialog from 'components/template/dialog/SimpleDialog';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import OrderStock from 'components/vendas/order/new/OrderStock';
import SearchLoading from 'components/loading/SearchLoading';
import jwt from 'jsonwebtoken';
import { compose } from 'recompose';
import { OrderAction } from 'components/vendas/order/new/OrderAction';
import { OrderTabs } from 'components/vendas/order/new/OrderTabs';
import { withSnackbarContext } from 'components/messages/MySnackBar';
import OrderViewTotal from './OrderViewTotal';
import MixModal from './OrderMixModal';
import OrderAddProduct from './OrderAddProduct';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  action: {
    margin: '40px 0 20px',
  },
  button: {
    marginRight: 10,
  },
  paper: {
    width: '100%',
    margin: '0 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0px',
    },
  },
  title: {
    padding: 20,
    borderBottom: '1px solid #ccc',
  },
  tabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
  },
  appbar: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
});

let timer;
const interval = 500;

const OrderContext = React.createContext({});

class Order extends Component {
  constructor(props) {
    super(props);

    let draft = 'off';
    let stock = {};

    if (localStorage.getItem('order') && !localStorage.getItem('orderCopied'))
      draft = 'on';

    if (localStorage.getItem('stock'))
      stock = JSON.parse(localStorage.getItem('stock'));

    this.state = {
      loadingProductSearch: false,
      loadingCustomer: false,
      loadingSendOrder: false,
      loadingDiscountCheck: false,
      loadingMix: false,
      loadSaving: true,
      message: '',
      messageType: '',
      order: {
        id: '',
        idCliente: '',
        cliente: '',
        idOperacao: '001',
        idRateio: '4',
        idFormaPagamento: '001',
        observacao: '',
        desconto: '',
        idCondicaoPagamento: '0010',
        items: [],
        historyItem: [],
      },
      historyOrder: {},
      mix: [],
      stock: stock,
      stockSearch: [],
      tabvalue: 0,
      isModalStockOpen: false,
      isModalOpen: false,
      isModalMixOpen: false,
      isSnackOpen: false,
      prices: [],
      productSelected: false,
      productSelectedData: {
        productId: '',
        price: '',
        commission: '',
        quantity: 0,
        discount: 0,
        description: '',
        vlCommission: 0,
        mode: '',
      },
      productStockSelected: false,
      productStockSelectedData: {
        productId: '',
        quantity: 0,
        description: '',
        fator: '',
        secondUnit: '',
        unit: '',
        mode: 'new',
      },
      customerData: null,
      draft: draft,
      tabs: true,
      listItemSelected: false,
      showTotal: false,
    };

    /**
     *  Venda idOperacao in ('001','005')
     *  Bonificação idOperacao in ('002','006')
     *  Troca idOperacao in ('004')
     **/

    if (localStorage.getItem('orderCopied')) {
      try {
        const orderEncoded = localStorage.getItem('orderCopied');
        jwt.verify(orderEncoded, process.env.REACT_APP_SECRET);
        const orderCopied = jwt.decode(orderEncoded);

        if (orderCopied.idOperacao === '005') orderCopied.idOperacao = '001';
        else if (orderCopied.idOperacao === '006')
          orderCopied.idOperacao = '002';

        this.state = {
          ...this.state,
          order: orderCopied,
        };

        localStorage.removeItem('orderCopied');

        this.saveAtLocalStorage();
      } catch (e) {
        this.state = {
          ...this.state,
          message: 'Rascunho inválido',
          isSnackOpen: true,
          messageType: 'ERROR',
        };
      }
    }

    this.contentWrapper = null;

    document.title = 'PedidoWeb - Novo Pedido';

    this.mySnackbarContext = this.props.mySnackbarContext;
  }

  setOrderInitialState = () => {
    const { order } = this.state;

    this.setState({
      order: {
        id: '',
        idCliente: '',
        cliente: '',
        idOperacao: '001',
        idRateio: '4',
        idFormaPagamento: '001',
        observacao: '',
        desconto: '',
        idCondicaoPagamento: '0010',
        items: [],
      },
      historyOrder: order,
      draft: 'off',
      customerData: null,
      tabvalue: 0,
    });

    this.mySnackbarContext.handleOpen(
      'Rascunho excluído',
      'SUCCESS',
      this.handleUndoDraftDelete
    );
  };

  handleUndoDraftDelete = () => {
    const { historyOrder } = this.state;

    this.setState(
      {
        order: historyOrder,
      },
      this.loadDataCustomer
    );
  };

  handleModalState = () => {
    if (this.state.order.cliente === '')
      this.mySnackbarContext.handleOpen(
        'É necessário informar o cliente antes de incluir um produto'
      );
    else
      this.setState({
        isModalOpen: !this.state.isModalOpen,
        productSelected: false,
      });
  };

  handleModalMixState = () => {
    this.setState({
      isModalMixOpen: !this.state.isModalMixOpen,
    });
  };

  handleModalStockState = () => {
    this.setState({
      isModalStockOpen: !this.state.isModalStockOpen,
    });
  };

  handleOrderChange = field => async event => {
    const value = event.target.value;

    await this.setState({
      order: { ...this.state.order, [field]: value },
    });

    if (field === 'cliente') {
      if (this.state.order.cliente === '') {
        this.setState(
          {
            order: {
              ...this.state.order,
              idCliente: '',
            },
          },
          this.loadDataCustomer
        );
      }
    } else if (field === 'desconto' || field === 'idCondicaoPagamento') {
      await this.checkDiscountPayment(this.state.order.desconto);
    } else if (field === 'idRateio') {
      this.setState({
        prices: [],
      });
    } else if (field === 'idFormaPagamento' && value === '002') {
      this.setState({
        order: {
          ...this.state.order,
          idCondicaoPagamento: '0044',
        },
      });
    }

    this.saveAtLocalStorage();
  };

  checkDiscountPayment = async value => {
    const param = {
      idCondicaoPagamento: this.state.order.idCondicaoPagamento,
      desconto: value,
    };

    clearTimeout(timer);

    if (value || value !== '') {
      this.setState({
        loadingDiscountCheck: true,
      });

      timer = setTimeout(async () => {
        const response = await VendasApi.prototype.checkDiscountPayment(param);
        if (response.type === 'ERROR') {
          this.setState({
            loadingDiscountCheck: false,
            order: {
              ...this.state.order,
              desconto: '',
            },
          });

          this.mySnackbarContext.handleOpen(response.message, response.type);
        } else {
          this.setState(
            {
              order: {
                ...this.state.order,
                desconto: value,
              },
              loadingDiscountCheck: false,
            },
            this.saveAtLocalStorage
          );

          if (this.props.mySnackbarContext.open)
            this.mySnackbarContext.handleClose();
        }
      }, interval);
    }
  };

  handleProductSearchChange = async event => {
    let param = {
      idCliente: this.state.order.idCliente,
      serie: this.state.order.idRateio,
      search: event.target.value,
    };

    clearTimeout(timer);

    if (event.target.value === '') {
      this.setState({
        prices: [],
      });
      return false;
    }

    timer = setTimeout(async () => {
      this.setState({
        loadingProductSearch: true,
        message: '',
        isSnackOpen: false,
        prices: [],
      });

      const response = await VendasApi.prototype.searchProduct(param);

      if (response.type === 'ERROR') {
        this.setState({
          loadingProductSearch: false,
        });

        this.mySnackbarContext.handleOpen(response.message, response.type);
      } else if (response.type === 'SUCCESS') {
        if (response.qtd > 0)
          this.setState({
            loadingProductSearch: false,
            prices: response.data,
            lastProductSearch: param.search,
          });
        else
          this.setState({
            loadingProductSearch: false,
            prices: [],
          });
      }
    }, interval);
  };

  checkIfIsInOrder = item => {
    let inserted = false;

    this.state.order.items.forEach(_item => {
      if (item.productId === _item.productId) {
        this.mySnackbarContext.handleOpen(
          'Este produto já foi incluído no pedido',
          'ERROR'
        );

        inserted = true;
      }
    });

    return inserted;
  };

  handleProductSelectToOrder = item => {
    if (!this.checkIfIsInOrder(item)) {
      this.setState({
        loadingProductSearch: true,
      });

      const param = {
        idProduto: item.productId,
        idCliente: this.state.order.idCliente,
        serie: this.state.order.idRateio,
      };

      VendasApi.prototype
        .getPrice(param)
        .then(response => {
          if (response.type === 'SUCCESS') {
            this.setState({
              isModalOpen: true,
              productSelected: true,
              productSelectedData: {
                id: response.id,
                productId: response.productId,
                price: response.price,
                commission: 0,
                description: response.description,
                quantity: '',
                discount: '',
                unit: response.unit,
                secondUnit: response.secondUnit,
                fator: response.fator,
                mode: 'new',
                discounts: response.discounts,
              },
            });
          } else if (response.type === 'ERROR') {
            this.mySnackbarContext.handleOpen(response.message, response.type);
          }
        })
        .finally(() => {
          this.setState({
            loadingProductSearch: false,
          });
        });
    }
  };

  handleChange = field => event => {
    this.setState({
      [field]: event.target.value,
    });
  };

  handleProductSelectedDataChange = (quantity, discount) => {
    this.setState({
      productSelectedData: {
        ...this.state.productSelectedData,
        quantity: quantity,
        discount: discount,
      },
    });
  };

  handleTabChange = (event, value) => {
    this.setState({ tabvalue: value });
  };

  handleCloseSnack = () => {
    this.setState({
      isSnackOpen: false,
    });
  };

  updateState = value => {
    this.setState(value);
  };

  handleProductSelected = state => {
    if (this.state.productSelectedData.mode === 'edit') {
      this.setState({
        productSelected: false,
        isModalOpen: false,
      });
    } else
      this.setState({
        productSelected: state,
        isModalOpen: !this.state.isModalMixOpen,
      });
  };

  handleProductStockSave = async () => {
    const { order } = this.state;

    if (this.state.productStockSelectedData.mode === 'new') {
      let stock = Object.assign({}, this.state.stock);

      if (!stock[order.idCliente]) {
        stock[order.idCliente] = [];
        this.setState({
          stock: stock,
        });
      }

      this.setState(
        {
          productStockSelected: false,
          stock: {
            ...this.state.stock,
            [order.idCliente]: [
              ...stock[order.idCliente],
              this.state.productStockSelectedData,
            ],
          },
          stockSearch: [],
        },
        this.saveStockAtLocalStorage
      );

      // this.mySnackbarContext.handleOpen('Saldo de estoque registrado', 'SUCCESS');
    } else if (this.state.productStockSelectedData.mode === 'edit') {
      const stockCopy = Object.assign({}, this.state.stock);
      const index = this.state.productStockSelectedData.index;

      stockCopy[order.idCliente][index] = this.state.productStockSelectedData;

      this.setState(
        {
          productStockSelected: false,
          stock: stockCopy,
          stockSearch: [],
        },
        this.saveStockAtLocalStorage
      );

      // this.mySnackbarContext.handleOpen('Saldo de estoque atualizado', 'SUCCESS');
    }
  };

  saveStockAtLocalStorage = () => {
    localStorage.setItem('stock', JSON.stringify(this.state.stock));
  };

  handleProductStockSelected = state => {
    this.setState({
      productStockSelected: state,
    });
  };

  handleProductStockSelect = item => {
    let included = false;
    let includedIndex = 0;
    let itemCopied;

    const { stock, order } = this.state;

    if (stock[order.idCliente])
      this.state.stock[order.idCliente].forEach((_item, index) => {
        if (item.productId === _item.productId) {
          included = true;
          includedIndex = index;
        }
      });

    if (!included)
      this.setState({
        productStockSelected: true,
        productStockSelectedData: {
          productId: item.productId,
          description: item.description,
          secondUnit: item.secondUnit,
          unit: item.unit,
          fator: item.fator,
          quantity: '',
          mode: 'new',
        },
      });
    else {
      itemCopied = JSON.parse(JSON.stringify(this.state.stock));
      itemCopied = itemCopied[order.idCliente][includedIndex];

      this.setState({
        productStockSelected: true,
        productStockSelectedData: {
          ...itemCopied,
          mode: 'edit',
          index: includedIndex,
        },
      });
    }
  };

  handleSearchProductStockChange = async event => {
    clearTimeout(timer);

    if (event.target.value === '') {
      this.setState({
        stockSearch: [],
      });
      return false;
    }

    const param = {
      search: event.target.value,
      idCliente: this.state.order.idCliente,
      idRateio: this.state.order.idRateio,
    };

    timer = setTimeout(async () => {
      this.setState({
        loadingProductSearch: true,
        stockSearch: [],
      });

      const response = await VendasApi.prototype.searchProduct(param);

      if (response.type === 'SUCCESS') {
        if (response.qtd > 0)
          this.setState({
            stockSearch: response.data,
            loadingProductSearch: false,
          });
        else
          this.setState({
            loadingProductSearch: false,
          });
      } else if (response.type === 'ERROR') {
        this.setState({
          loadingProductSearch: false,
        });

        this.mySnackbarContext.handleOpen(response.message, response.type);
      }
    }, interval);
  };

  handleProductStockSelectedChange = event => {
    this.setState({
      productStockSelectedData: {
        ...this.state.productStockSelectedData,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleOrderSave = async (quantity, discount) => {
    this.setState({
      loading: true,
    });

    let param = {
      codigo: this.state.productSelectedData.productId,
      desconto: discount,
      qtd: quantity,
      preco: this.state.productSelectedData.price,
      idCliente: this.state.order.idCliente,
      serie: this.state.order.idRateio,
      descontoPedido: this.state.order.desconto,
      idOperacao: this.state.order.idOperacao,
      idCondicaoPagamento: this.state.order.idCondicaoPagamento,
    };

    const checkComission = await VendasApi.prototype.checkDiscount(param);

    if (checkComission.type === 'SUCCESS') {
      let item = this.state.productSelectedData;

      let orderDiscount =
        this.state.order.desconto === '' ? 0 : this.state.order.desconto;

      item.quantity = param.qtd;
      item.discount = param.desconto === '' ? 0 : param.desconto;

      item.commission = checkComission.commission;
      item.total = (item.quantity * item.price * (100 - item.discount)) / 100;
      item.totalFinal =
        (((item.quantity * item.price * (100 - item.discount)) / 100) *
          (100 - orderDiscount)) /
        100;
      item.totalbruto = item.quantity * item.price;
      item.vlCommission = item.totalFinal * (item.commission / 100);
      item.vlDiscount = (item.totalbruto * item.discount) / 100;
      item.secondQuantity = item.quantity / item.fator;

      if (this.state.productSelectedData.mode === 'new') {
        this.setState({
          productSelected: false,
          isModalOpen: !this.state.isModalMixOpen,
          loading: false,
          prices: [],
          order: {
            ...this.state.order,
            items: [...this.state.order.items, item],
          },
        });
      } else if (this.state.productSelectedData.mode === 'edit') {
        let copyItems = this.state.order.items.slice();
        copyItems[item.index] = item;
        this.setState({
          productSelected: false,
          message: 'Produto alterado!',
          messageType: 'SUCCESS',
          isSnackOpen: false,
          isModalOpen: false,
          loading: false,
          prices: [],
          order: {
            ...this.state.order,
            items: copyItems,
          },
        });
      }

      this.saveAtLocalStorage();
      this.clearListItemSelected();
    } else if (checkComission.type === 'ERROR') {
      this.setState({
        loading: false,
      });

      this.mySnackbarContext.handleOpen(
        checkComission.message,
        checkComission.type
      );
    }
  };

  handleItemChange = async index => {
    const item = this.state.order.items[index];

    const param = {
      idProduto: item.productId,
      idCliente: this.state.order.idCliente,
      serie: this.state.order.idRateio,
    };

    const response = await VendasApi.prototype.getPrice(param);

    if (response.type === 'SUCCESS') {
      this.setState({
        isModalOpen: true,
        productSelected: true,
        productSelectedData: {
          ...item,
          fator: response.fator,
          price: response.price,
          discounts: response.discounts,
          secondUnit: response.secondUnit,
          mode: 'edit',
          index: index,
        },
      });
    } else if (response.type === 'ERROR') {
      this.mySnackbarContext.handleOpen(response.message, response.type);
    }
  };

  handleItemDelete = () => {
    const history = this.state.order.items.slice();

    const items = this.state.order.items.filter(item => {
      return !item.selected;
    });

    this.setState(
      {
        order: {
          ...this.state.order,
          items: items,
          history: history,
        },
      },
      () => {
        this.saveAtLocalStorage();
        this.clearListItemSelected();
      }
    );

    this.mySnackbarContext.handleOpen(
      'Item removido',
      'SUCCESS',
      this.handleUndoDelete
    );
  };

  handleUndoDelete = () => {
    const history = this.state.order.history;

    this.setState(
      {
        order: {
          ...this.state.order,
          items: history,
        },
      },
      this.saveAtLocalStorage
    );
  };

  handleSetCliente = async (idCliente, cliente) => {
    this.setState(
      {
        order: {
          ...this.state.order,
          cliente: cliente,
          idCliente: idCliente,
        },
      },
      this.loadDataCustomer
    );
  };

  saveAtLocalStorage = () => {
    const orderEncoded = jwt.sign(
      JSON.stringify(this.state.order),
      process.env.REACT_APP_SECRET
    );
    localStorage.setItem('order', orderEncoded);
  };

  removeFromLocalStorage = () => {
    this.setState({
      loadingSendOrder: true,
    });

    localStorage.removeItem('order');
    localStorage.removeItem('stock');

    this.setState({
      loadingSendOrder: false,
    });
  };

  loadDataCustomer = () => {
    if (
      this.state.order.idCliente === '' ||
      typeof this.state.order.idCliente === 'undefined'
    ) {
      this.setState({
        customerData: null,
      });
      return false;
    }

    this.setState({
      loadingCustomer: true,
      customerData: null,
    });

    ClientesApi.prototype.load(this.state.order.idCliente).then(response => {
      if (response.type === 'SUCCESS') {
        this.setState(
          {
            loadingCustomer: false,
            customerData: {
              id: response.customer.id,
              name: response.customer.name,
              address: response.customer.address,
              cnpj: response.customer.cnpj,
              city: response.customer.city,
              companyId: response.customer.companyId,
              companyFantasy: response.customer.companyFantasy,
              currentDate: response.customer.currentDate,
            },
          },
          this.saveAtLocalStorage
        );
      } else {
        this.setState({
          loadingCustomer: false,
          customerData: null,
          message: response.message,
          messageType: response.type,
          isSnackOpen: true,
        });

        this.mySnackbarContext.handleOpen(response.message, response.type);
      }
    });

    this.loadProductMix();
  };

  handleDraft = async response => {
    if (response === 'Y') {
      try {
        const orderEncoded = localStorage.getItem('order');
        jwt.verify(orderEncoded, process.env.REACT_APP_SECRET);
        const order = jwt.decode(orderEncoded);

        this.setState(
          {
            order: order,
            draft: 'off',
          },
          this.loadDataCustomer
        );
      } catch (e) {
        this.setState({
          message: 'Rascunho inválido',
          isSnackOpen: true,
          messageType: 'ERROR',
          draft: 'off',
        });

        this.mySnackbarContext.handleOpen('Rascunho inválido', 'ERROR');
      }
    } else if (response === 'N') {
      this.setOrderInitialState();
    } else if (response === 'D') {
      this.setOrderInitialState();
      this.removeFromLocalStorage();
    }
  };

  handleSubmit = async event => {
    const { history } = this.props;

    event.preventDefault();

    this.setState({
      loadingSendOrder: true,
    });

    let total = 0,
      totalprodutos = 0;

    this.state.order.items.forEach(item => {
      total += item.totalFinal;
      totalprodutos += item.totalbruto;
    });

    let newItems = [];
    this.state.order.items.forEach(item => {
      newItems = [
        ...newItems,
        {
          id: item.productId,
          qtd: item.quantity,
          segundaQtd: item.secondQuantity,
          desconto: item.discount,
          unidade: item.unit,
          segundaUnidade: item.secondUnit,
          comissao: item.commission,
          vlDesconto: item.discount,
          preco: item.price,
        },
      ];
    });

    const param = {
      idPedido: new Date().getTime(),
      idCliente: this.state.order.idCliente,
      idRateio: this.state.order.idRateio,
      percentualDesconto: this.state.order.desconto,
      idOperacao: this.state.order.idOperacao,
      idFormaPagamento: this.state.order.idFormaPagamento,
      idCondicaoPagamento: this.state.order.idCondicaoPagamento,
      observacao: this.state.order.observacao,
      totalProdutos: totalprodutos,
      total: total,
      items: JSON.stringify(newItems),
      idRepresentante: localStorage.getItem('idRepresentante'),
    };

    const response = await VendasApi.prototype.send(param);

    if (response.type === 'SUCCESS') {
      /*this.setState(
        {
          loadingSendOrder: false,
        },
        this.setOrderInitialState
      );*/

      this.mySnackbarContext.handleOpen('Pedido foi enviado!', 'SUCCESS');
      this.removeFromLocalStorage();
      history.push('/orders');
    } else if (response.type === 'ERROR') {
      this.setState({
        message: response.message,
        messageType: response.type,
        isSnackOpen: true,
        loadingSendOrder: false,
      });

      this.mySnackbarContext.handleOpen(response.message, response.type);
    }
  };

  loadProductMix = () => {
    this.setState({
      loadingMix: true,
    });

    const param = {
      idCliente: this.state.order.idCliente,
    };

    VendasApi.prototype.mix(param).then(mix => {
      if (mix.type === 'SUCCESS') {
        this.setState({
          loadingMix: false,
          mix: mix.data,
        });
      } else if (mix.type === 'ERROR') {
        this.setState({
          loadingMix: false,
        });
      }
    });
  };

  handleDraftDelete = () => {
    this.removeFromLocalStorage();
    this.setOrderInitialState();
  };

  handleNext = () => {
    this.setState({
      tabvalue: 1,
    });
  };

  handleListItemSelect = index => {
    const items = this.state.order.items.slice();

    items[index].selected = !items[index].selected;

    this.setState(
      {
        order: {
          ...this.state.order,
          items: items,
        },
      },
      this.saveAtLocalStorage
    );
  };

  handleListItemSelectAll = () => {
    let items = this.state.order.items.slice();

    items.map(item => {
      item.selected = true;
      return item;
    });

    this.setState(
      {
        order: {
          ...this.state.order,
          items: items,
        },
      },
      this.saveAtLocalStorage
    );
  };

  clearListItemSelected = () => {
    let items = this.state.order.items.slice();

    items.map(item => {
      item.selected = false;
      return item;
    });

    this.setState(
      {
        order: {
          ...this.state.order,
          items: items,
        },

        listItemSelected: false,
      },
      this.saveAtLocalStorage
    );
  };

  handleShowTotal = () => {
    this.setState({
      showTotal: !this.state.showTotal,
    });
  };

  componentDidMount() {
    if (this.state.order.idCliente !== '') {
      this.loadDataCustomer();
    }
  }

  render() {
    const {
      loadingSendOrder,
      loadingCustomer,
      loadingDiscountCheck,
      mix,
      loading,
      isModalStockOpen,
      order,
      tabvalue,
      customerData,
      showTotal,
      isModalOpen,
      isModalMixOpen,
      productSelected,
      productSelectedData,
    } = this.state;

    const { classes } = this.props;

    let listItemSelected = false,
      listItemQuantitySelected = 0;

    order.items.forEach(item => {
      if (item.selected) {
        listItemSelected = true;
        listItemQuantitySelected++;
      }
    });

    if (this.state.draft === 'on') {
      return (
        <Fragment>
          <SimpleDialog disableBackdropClick displayTitle title={'Rascunho'}>
            <OrderDraft handleDraft={this.handleDraft} />
          </SimpleDialog>
        </Fragment>
      );
    } else
      return (
        <Fragment>
          <AppContextConsumer>
            {({ context }) => (
              <MyAppBar
                cancel={listItemSelected}
                cancelAction={this.clearListItemSelected}
                title={
                  listItemSelected
                    ? listItemQuantitySelected > 1
                      ? listItemQuantitySelected + ' produtos'
                      : listItemQuantitySelected + ' produto'
                    : 'Vendas'
                }
                appContext={context}
                ActionComponents={
                  <OrderAction
                    listItemSelected={listItemSelected}
                    handleSubmit={this.handleSubmit}
                    handleDraftDelete={this.handleDraftDelete}
                    handleItemDelete={this.handleItemDelete}
                    handleItemSelected={this.handleProductSelected}
                    handleItemChange={this.handleItemChange}
                    handleListItemSelectAll={this.handleListItemSelectAll}
                    handleShowTotal={this.handleShowTotal}
                    order={order}
                  />
                }
                Tabs={
                  <OrderTabs
                    tabvalue={tabvalue}
                    handleTabChange={this.handleTabChange}
                  />
                }
              />
            )}
          </AppContextConsumer>
          {(loadingDiscountCheck || loading) && <SearchLoading />}
          {(loadingSendOrder || loadingCustomer) && <SimpleLoading />}
          {isModalStockOpen && (
            <OrderStock
              {...this.state}
              onExited={this.handleModalStockState}
              title={'Incluir estoque'}
              handleProductSelectToOrder={this.handleProductSelectToOrder}
              handleProductStockSelectedChange={
                this.handleProductStockSelectedChange
              }
              handleProductStockSelected={this.handleProductStockSelected}
              handleProductStockSave={this.handleProductStockSave}
              handleSearchProductStockChange={
                this.handleSearchProductStockChange
              }
              handleProductStockSelect={this.handleProductStockSelect}
            />
          )}
          {showTotal && (
            <OrderViewTotal order={order} onExited={this.handleShowTotal} />
          )}
          {isModalOpen && (
            <OrderAddProduct
              {...this.state}
              onExited={this.handleModalState}
              handleProductSearchChange={this.handleProductSearchChange}
              handleItemChange={this.handleItemChange}
              handleOrderSave={this.handleOrderSave}
              handleProductSelectedDataChange={
                this.handleProductSelectedDataChange
              }
              handleProductSelected={this.handleProductSelected}
              handleProductSelectToOrder={this.handleProductSelectToOrder}
              title={
                productSelected && productSelectedData.mode === 'edit'
                  ? 'Alterar produto'
                  : 'Incluir produto'
              }
            />
          )}
          {isModalMixOpen && (
            <MixModal
              onExited={this.handleModalMixState}
              order={order}
              mix={mix}
              handleProductSelectToOrder={this.handleProductSelectToOrder}
              handleModalMixState={this.handleModalMixState}
              handleModalStockState={this.handleModalStockState}
            />
          )}
          <Grid container spacing={0}>
            <div className={classes.paper}>
              <div>
                <div
                  style={
                    tabvalue === 0 ? { display: 'block' } : { display: 'none' }
                  }
                >
                  <OrderContext.Provider
                    value={{
                      updateOrderState: this.updateState,
                    }}
                  >
                    <OrderBasic
                      order={order}
                      loadindCustomer={loadingCustomer}
                      customerData={customerData}
                      handleSetCliente={this.handleSetCliente}
                      handleOrderChange={this.handleOrderChange}
                    />
                  </OrderContext.Provider>
                </div>
                <div
                  style={
                    tabvalue === 1 ? { display: 'block' } : { display: 'none' }
                  }
                >
                  <OrderItems
                    {...this.state}
                    handleModalState={this.handleModalState}
                    handleModalMixState={this.handleModalMixState}
                    handleModalStockState={this.handleModalStockState}
                    handleListItemSelect={this.handleListItemSelect}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Fragment>
      );
  }
}

export default compose(
  withStyles(styles),
  withSnackbarContext,
  withRouter
)(Order);
