import React, { Fragment } from 'react';
import Sidebar from 'components/template/sidebar/SideBar';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { isAuth } from 'services/user/Auth';
import { mobileCheck } from 'helpers/MobileCheck';
import jwt from 'jsonwebtoken';
import io from 'socket.io-client';
import { compose } from 'recompose';
import { withSnackbarContext } from 'components/messages/MySnackBar';
import PropTypes from 'prop-types';

const menuWidth = 240;

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  appBar: {
    marginBottom: 20,
    width: 'calc(100% - ' + menuWidth + 'px)',
    right: 0,
    left: 'auto',
    top: 0,
    position: 'fixed',
    transition: 'width 225ms',
  },
  appBarExtended: {
    marginBottom: 20,
    width: '100%',
    right: 0,
    left: 'auto',
    top: 0,
    position: 'fixed',
    transition: 'width 225ms',
  },
  sidebarWrapper: {
    position: 'fixed',
    height: '100%',
    zIndex: 1101,
  },
  wrapperContainer: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  contentWrapper: {
    width: '100%',
    margin: '64px 0 0',
    transitionDuration: '225ms',
    paddingLeft: 0,
    overflowY: 'auto',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      margin: '57px 0 0',
    },
  },
  contentWrapperReduced: {
    width: '100%',
    margin: '64px 0 0',
    transitionDuration: '225ms',
    paddingLeft: menuWidth,
    overflowY: 'auto',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      margin: '57px 0 0',
    },
  },
  contentWrapperLogin: {
    width: '100%',
    transitionDuration: '225ms',
    paddingLeft: 0,
    overflowY: 'auto',
    position: 'relative',
    paddingTop: 15,
  },
  content: {
    padding: '20px 30px 80px',
    [theme.breakpoints.down('md')]: {
      padding: '15px 20px 80px',
    },
  },
});

const AppContext = React.createContext({
  username: '',
  handleOpenMenu: () => {},
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: false,
      windowWidth: window.innerWidth,
    };

    if (mobileCheck())
      this.state = {
        ...this.state,
        openMenu: false,
        mobile: true,
      };
    else
      this.state = {
        ...this.state,
        openMenu: true,
        mobile: false,
      };
  }

  setUsername = () => {
    const datatoken = jwt.decode(localStorage.getItem('token'));
    const username = datatoken.data.username.toLowerCase();
    this.setState({
      userdata: {
        name: username,
      },
    });
  };

  handleOpenMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  };

  handleResize = () => {
    const mobile = mobileCheck();
    this.setState({
      mobile: mobile,
      windowWidth: window.innerWidth,
    });
  };

  registerToSocket = () => {
    const { mySnackbarContext } = this.props;

    const socket = io('http://localhost:3333');

    socket.on('message', message => {
      mySnackbarContext.handleOpen(message);
    });
  };

  componentDidMount() {
    // this.registerToSocket();
    window.addEventListener('resize', this.handleResize);
  }

  render() {
    const { children, classes } = this.props;
    const authenticated = isAuth();

    if (authenticated) document.body.classList.add('zoom');

    const { openMenu, mobile, windowWidth } = this.state;

    return (
      <div className={classes.wrapperContainer}>
        <Fragment>
          {authenticated && (
            <div className={classes.sidebarWrapper}>
              <Sidebar
                mobile={mobile}
                open={openMenu}
                handleOpen={this.handleOpenMenu}
                windowWidth={windowWidth}
              />
            </div>
          )}

          <div
            id="content-wrapper"
            className={
              !authenticated
                ? classes.contentWrapperLogin
                : openMenu
                ? classes.contentWrapperReduced
                : classes.contentWrapper
            }
          >
            <AppContext.Provider
              value={{
                context: {
                  handleOpenMenu: this.handleOpenMenu,
                  openMenu: openMenu,
                  mobile: mobile,
                  windowWidth: windowWidth,
                },
              }}
            >
              <div className={classes.content}>{children}</div>
            </AppContext.Provider>
          </div>
          {/* authenticated && <Footer /> */}
        </Fragment>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object,
  mySnackbarContext: PropTypes.object,
};

// eslint-disable-next-line react/display-name
export const withAppContext = Component => props => (
  <AppContext.Consumer>
    {context => <Component {...props} {...context} />}
  </AppContext.Consumer>
);

export default compose(
  withRouter,
  withStyles(styles),
  withSnackbarContext
)(App);

export const AppContextConsumer = AppContext.Consumer;
export { AppContext };
export { menuWidth };
