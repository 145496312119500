import React, { Component } from 'react';
import {
  Grid,
  TextField,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import { moneyFormat, numberFormat } from 'helpers/NumberFormat';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SimpleModalConsumer } from 'components/template/modal/SimpleModal';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import DoneIcon from '@material-ui/icons/Done';
import OrderListDiscounts from './OrderListDiscounts';

const styles = {
  grow: {
    flexGrow: 1,
  },
};

class OrderAddProductForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: '',
      discount: '',
    };
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentWillUnmount() {
    this.setState({
      quantity: '',
      discount: '',
    });
  }

  componentDidMount() {
    const { productSelectedData, productSelected } = this.props;

    let discount =
      productSelectedData.discount === 0 ? '' : productSelectedData.discount;

    if (productSelectedData.mode === 'edit' && productSelected)
      this.setState({
        quantity: productSelectedData.quantity,
        discount: discount,
      });
    else
      this.setState({
        quantity: '',
        discount: '',
      });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.handleOrderSave(this.state.quantity, this.state.discount);
  };

  render() {
    const {
      productSelectedData,
      classes,
      loading,
      title,
      stockItemQuantity,
    } = this.props;

    const { quantity, discount } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <SimpleModalConsumer>
          {({ handleClose }) => (
            <AppBar>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <Typography variant={'h6'} color={'inherit'}>
                  {title}
                </Typography>
                <div className={classes.grow} />
                <div>
                  <IconButton
                    disabled={loading}
                    color={'inherit'}
                    component={'button'}
                    type={'submit'}
                  >
                    <DoneIcon />
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
          )}
        </SimpleModalConsumer>
        <Grid item xs={12}>
          <div>
            <Typography variant={'h5'}>
              {productSelectedData.productId} -{' '}
              {productSelectedData.description}
            </Typography>
          </div>
          <div>
            {productSelectedData.discounts && (
              <OrderListDiscounts
                productSelectedData={productSelectedData}
                stockItemQuantity={stockItemQuantity}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <Grid item xs={12}>
            <TextField
              value={quantity}
              onChange={this.handleInputChange}
              autoFocus
              helperText={
                numberFormat(this.state.quantity / productSelectedData.fator) +
                ' ' +
                productSelectedData.secondUnit
              }
              margin="normal"
              required
              placeholder={'Quantidade unitária'}
              label="Quantidade"
              name="quantity"
              variant="standard"
              fullWidth
              type={'number'}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <TextField
              value={discount}
              onChange={this.handleInputChange}
              margin="normal"
              label="% Desconto"
              placeholder={'Percentual de desconto'}
              variant="standard"
              name="discount"
              inputProps={{
                pattern: '^+(?:{1,2})?$',
                step: '0.01',
              }}
              fullWidth
              helperText={
                'Preço com desconto: ' +
                moneyFormat(
                  productSelectedData.price * ((100 - discount) / 100)
                )
              }
              type={'number'}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

OrderAddProductForm.propTypes = {
  handleOrderSave: PropTypes.func,
  handleProductSelected: PropTypes.func,
  productSelectedData: PropTypes.object,
  productSelected: PropTypes.bool,
  classes: PropTypes.object,
  loading: PropTypes.bool,
  title: PropTypes.string,
  stockItemQuantity: PropTypes.string,
};

export default withStyles(styles)(OrderAddProductForm);
