import React, { Component } from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { mobileCheck } from '../../helpers/MobileCheck';

const styles = {
  SUCCESS: {
    backgroundColor: '#4cd252',
  },
  ERROR: {
    backgroundColor: '#ff8100',
  },
  WARNING: {
    backgroundColor: '#ffc107',
  },
  INFO: {
    backgroundColor: '#17a2b8',
  },
  PRIMARY: {
    backgroundColor: '#007bff',
  },
  message: {
    marginLeft: 10,
    paddingTop: 3,
  },
  messageContent: {
    display: 'flex',
  },
  snackbar: {
    bottom: 10,
  },
};

let MySnackbarContext = React.createContext({
  open: false,
});

class MySnackBar extends Component {
  state = {
    open: false,
    message: '',
    variant: '',
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return false;

    this.setState({
      open: false,
    });
  };

  handleOpen = (message, variant, action) => {
    if (this.state.open) {
      this.setState({
        open: false,
      });

      setTimeout(() => {
        this.setState({
          open: true,
          message: message,
          variant: variant,
          action: action,
        });
      }, 350);
    } else
      this.setState({
        open: true,
        message: message,
        variant: variant,
        action: action,
      });
  };

  callAction = () => {
    const action = this.state.action;

    if (typeof action === 'function') {
      action();
      this.handleClose();
    }
  };

  render() {
    const { children, classes } = this.props;
    const mobile = mobileCheck();
    return (
      <MySnackbarContext.Provider
        value={{
          handleClose: this.handleClose,
          handleOpen: this.handleOpen,
          open: this.state.open,
        }}
      >
        <Snackbar
          classes={{
            anchorOriginBottomCenter: !mobile ? classes.snackbar : null,
          }}
          open={this.state.open}
          onClose={this.handleClose}
          key={this.state.message}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: mobile ? 'left' : 'center',
          }}
        >
          <SnackbarContent
            /*className={classes[variant]}*/
            message={this.state.message}
            action={
              this.state.action ? (
                <Button
                  key="undo"
                  size={'small'}
                  color={'secondary'}
                  onClick={this.callAction}
                >
                  Desfazer
                </Button>
              ) : (
                <Button
                  key="close"
                  size={'small'}
                  aria-label="Close"
                  color={'secondary'}
                  onClick={this.handleClose}
                >
                  Fechar
                </Button>
              )
            }
          />
        </Snackbar>
        {children}
      </MySnackbarContext.Provider>
    );
  }
}

// eslint-disable-next-line react/display-name
const withSnackbarContext = Component => props => (
  <MySnackbarContext.Consumer>
    {context => <Component {...props} mySnackbarContext={context} />}
  </MySnackbarContext.Consumer>
);

MySnackBar.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(MySnackBar);
export { withSnackbarContext };
