import React, { Fragment } from 'react';
import SimpleModal from 'components/template/modal/SimpleModal';
import Typography from '@material-ui/core/Typography';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    paddingBottom: 0,
  },
};

export const OrderViewTotal = props => {
  const { order, onExited, classes } = props;

  /*
  let total = 0,
    finalTotal = 0,
    discount = 0,
    commission = 0; /*, icmsSt = 0

  order.items.forEach(item => {
    total += item.totalbruto;
    finalTotal += item.totalFinal;
    discount = total - finalTotal;
    commission += item.vlCommission;
    icmsSt += item.icmsSt;
  });
  */

  return (
    <SimpleModal title={'Total do pedido'} handleModalState={onExited}>
      <Fragment>
        <Grid item xl={3} xs={12} md={6} lg={4}>
          <List>
            <ListItem classes={{ root: classes.root }}>
              <ListItemText>Produtos</ListItemText>
              <ListItemSecondaryAction>
                <Typography variant={'body1'}>{order.items.length}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem classes={{ root: classes.root }}>
              <ListItemText>Subtotal</ListItemText>
              <ListItemSecondaryAction>
                <Typography variant={'body1'}>
                  {moneyFormat(order.productTotal)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            {order.discount > 0 && (
              <ListItem classes={{ root: classes.root }}>
                <ListItemText>Desconto</ListItemText>
                <ListItemSecondaryAction>
                  <Typography variant={'body1'}>
                    {moneyFormat(order.discount)} (
                    {percentFormat((order.discount / order.productTotal) * 100)}
                    )
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            )}
            {order.vrIcmsSt > 0 && (
              <ListItem classes={{ root: classes.root }}>
                <ListItemText>ICMS ST</ListItemText>
                <ListItemSecondaryAction>
                  <Typography variant={'body1'}>
                    {moneyFormat(order.vrIcmsSt)}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            )}
            <ListItem classes={{ root: classes.root }}>
              <ListItemText>Comissão</ListItemText>
              <ListItemSecondaryAction>
                <Typography variant={'body1'}>
                  {moneyFormat(order.commission)} (
                  {percentFormat((order.commission / order.finalTotal) * 100)})
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem classes={{ root: classes.root }}>
              <ListItemText>Total</ListItemText>
              <ListItemSecondaryAction>
                <Typography variant={'h6'}>
                  <strong>{moneyFormat(order.finalTotal)}</strong>
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </Fragment>
    </SimpleModal>
  );
};

export default withStyles(styles)(OrderViewTotal);
