import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  listItem: {
    padding: '5px 0',
    display: 'block',
  },
};

const OrderListDiscounts = props => {
  const { productSelectedData, stockItemQuantity, classes } = props;

  return (
    <List dense={false}>
      <ListItem className={classes.listItem}>
        <Typography>
          Preço: <strong>{moneyFormat(productSelectedData.price)}</strong>
        </Typography>
        <Typography>
          Embalagem:{' '}
          <strong>
            {productSelectedData.secondUnit}/{productSelectedData.fator}
          </strong>
        </Typography>
      </ListItem>
      {productSelectedData.discounts.map((item, index) => {
        return (
          <ListItem className={classes.listItem} key={index}>
            <Typography>
              Desconto de {percentFormat(item.minDiscount)} até{' '}
              {percentFormat(item.maxDiscount)}
            </Typography>
            <Typography>Comissão: {percentFormat(item.commission)}</Typography>
            {item.minQuantity > 0 && (
              <Typography>
                Quantidade mínima:{' '}
                <strong>
                  {item.minQuantity / productSelectedData.fator}
                  {productSelectedData.secondUnit}
                </strong>
              </Typography>
            )}
          </ListItem>
        );
      })}
      <ListItem className={classes.listItem}>
        <Typography>
          Estoque: <strong>{stockItemQuantity}</strong>
        </Typography>
      </ListItem>
    </List>
  );
};

export default withStyles(styles)(OrderListDiscounts);
