import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import IconSearch from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import ClientesApi from 'services/ClientesApi';
import Typography from '@material-ui/core/Typography';
import SearchLoading from 'components/loading/SearchLoading';
import SimpleModal from '../template/modal/SimpleModal';
import { compose } from 'recompose';
import { SimpleModalConsumer } from '../template/modal/SimpleModal';

const styles = theme => ({
  modal: {
    padding: '0!important',
  },
  listItem: {
    marginBottom: 7,
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  loading: {
    top: '30%',
    transform: 'translate(-50%)',
    fontSize: 16,
    color: '#fff',
    zIndex: 1100,
    padding: 15,
    textAlign: 'center',
    position: 'fixed',
    left: '50%',
    width: 200,
  },
  buttonClose: {
    position: 'absolute',
    top: 15,
    right: 10,
    zIndex: 1100,
  },
  appbarSpace: {
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      marginBottom: 75,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      marginBottom: 70,
    },
  },
});

let timer;
let interval = 500;

class SearchCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      searching: false,
      data: [],
      message: '',
      messagetype: '',
      isSnackBarOpen: '',
    };
  }

  handleChange = e => {
    const param = {
      search: e.target.value,
    };

    clearTimeout(timer);

    if (e.target.value === '' || e.target.value.length <= 2) {
      this.setState({
        data: [],
      });

      return false;
    }

    timer = setTimeout(async () => {
      this.setState({
        searching: true,
        data: [],
      });

      const response = await ClientesApi.prototype.list(param);

      if (response.type === 'SUCCESS') {
        if (response.qtd > 0)
          this.setState({
            data: response.data,
            searching: false,
          });
        else
          this.setState({
            data: [],
            searching: false,
          });
      } else if (response.type === 'ERROR') {
        this.setState({
          data: [],
          searching: false,
          message: response.message,
          messageType: response.type,
          isSnackBarOpen: true,
        });
      }
    }, interval);
  };

  render() {
    const { handleClose, classes, handleSetCliente } = this.props;
    const { data, searching } = this.state;

    return (
      <Fragment>
        <SimpleModal
          title={'Localize o cliente'}
          handleModalState={handleClose}
        >
          <TextField
            onChange={this.handleChange}
            value={this.state.searchText}
            autoFocus
            margin={'normal'}
            fullWidth
            variant={'standard'}
            label={'Digite o código ou razão do cliente'}
            InputProps={{
              startAdornment: <IconSearch />,
            }}
          />
          {searching && <SearchLoading />}
          {data.length > 0 && (
            <Fragment>
              <Typography variant={'caption'}>
                Escolha um cliente abaixo
              </Typography>
              <List component={'nav'}>
                {this.state.data.map(cliente => {
                  return (
                    <SimpleModalConsumer key={cliente.id}>
                      {({ handleClose }) => (
                        <ListItem
                          key={cliente.id}
                          onClick={() => {
                            handleSetCliente(cliente.idCliente, cliente.razao);
                            handleClose();
                          }}
                          className={classes.listItem}
                          button
                        >
                          <Typography variant={'h6'}>
                            {cliente.idCliente} - {cliente.razao}
                          </Typography>
                          <Typography variant={'caption'} display="block">
                            CNPJ: {cliente.cnpj}
                          </Typography>
                          <Typography variant={'caption'} display="block">
                            Cidade: {cliente.cidade}
                          </Typography>
                          <Typography variant={'caption'} display="block">
                            Endereço: {cliente.endereco}
                          </Typography>
                        </ListItem>
                      )}
                    </SimpleModalConsumer>
                  );
                })}
              </List>
            </Fragment>
          )}
        </SimpleModal>
      </Fragment>
    );
  }
}

export default compose(withStyles(styles))(SearchCliente);
