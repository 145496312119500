import React, { Fragment } from 'react';
import ClientesApi from 'services/ClientesApi';
import SimpleLoading from 'components/loading/SimpleLoading';
import MySnackBar from 'components/messages/MySnackBar';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import { makeCancelable } from 'helpers/PromiseCancelable';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ClienteBasic from 'components/clientes/view/ClienteBasic';
import VendasApi from 'services/VendasApi';
import VendasTable from 'components/vendas/VendasTable';
import TableNoData from 'components/template/table/TableNoData';
import TitulosTable from 'components/titulos/TitulosTable';
import TitulosApi from 'services/TitulosApi';
import SearchLoading from 'components/loading/SearchLoading';
import { ClienteTabs } from './ClienteTabs';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  N: {
    backgroundColor: '#6bc720',
  },
  S: {
    backgroundColor: '#ff8100',
  },
  chipRoot: {
    marginBottom: 10,
  },
  paper: {
    width: '100%',
    margin: '0 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0px',
    },
  },
  vendasTable: {
    marginTop: 10,
  },
  tabs: {
    top: 64,
    [theme.breakpoints.down('md')]: {
      top: 56,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 48,
    },
  },
  appbar: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  header: {
    padding: '30px 20px 35px',
    backgroundColor: fade(theme.palette.secondary.light, 0.7),
    borderRadius: 4,
  },
  headerButton: {
    marginTop: 13,
  },
});

let customerRequest, paymentsRequest, ordersRequest;

class Cliente extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: {
        address: '',
        addressNumber: '',
        city: '',
        cnpj: '',
        companyFantasy: '',
        companyId: '',
        fantasy: '',
        id: '',
        idStatus: '',
        mail: '',
        name: '',
        neighboorHood: '',
        phone: '',
        state: '',
        status: '',
        tablePrice: '',
        tablePriceName: '',
        zipcode: '',
      },
      orders: [],
      message: '',
      messageType: '',
      isSnackbarOpen: false,
      loading: false,
      loaded: false,
      tabvalue: 0,
      payments: [],
      salving: false,
    };
  }

  componentWillUnmount() {
    customerRequest.cancel();
  }

  async componentDidMount() {
    const { idCliente } = this.props.match.params;

    this.setState({
      loading: true,
    });

    const param = {
      idCliente: idCliente,
    };

    customerRequest = makeCancelable(ClientesApi.prototype.load(idCliente));
    ordersRequest = makeCancelable(VendasApi.prototype.getByCustomer(param));
    paymentsRequest = makeCancelable(TitulosApi.prototype.getByCustomer(param));

    const response = await customerRequest.promise;
    const responseOrders = await ordersRequest.promise;
    const responsePayments = await paymentsRequest.promise;

    if (response.type === 'SUCCESS') {
      this.setState({
        customer: response.customer,
        loading: false,
        loaded: true,
      });

      document.title = response.customer.name;
    } else if (response.type === 'ERROR') {
      this.setState({
        customer: {},
        loading: false,
        message: response.message,
        messageType: response.messageType,
        isSnackbarOpen: true,
        loaded: false,
      });
    }

    if (responseOrders.type === 'SUCCESS') {
      if (responseOrders.qtd > 0)
        this.setState({
          orders: responseOrders.orders,
          loadingOrders: false,
        });
      else
        this.setState({
          orders: [],
          loadingOrders: false,
        });
    } else if (responseOrders.type === 'ERROR') {
      this.setState({
        loadingOrders: false,
        message: responseOrders.messages,
        messageType: responseOrders.messageType,
        isSnackbarOpen: true,
      });
    }

    if (responsePayments.type === 'SUCCESS') {
      if (responsePayments.qtd > 0)
        this.setState({
          payments: responsePayments.data,
          loadingPayments: false,
        });
      else
        this.setState({
          payments: [],
          loadingPayments: false,
        });
    } else if (responsePayments.type === 'ERROR') {
      this.setState({
        loadingPayments: false,
        message: responseOrders.messages,
        messageType: responseOrders.messageType,
        isSnackbarOpen: true,
      });
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ tabvalue: value });
  };

  setCustomerStatus = async () => {
    const customer = this.state.customer;
    this.setState({
      saving: true,
      customer: {
        ...this.state.customer,
        idStatus:
          customer.idStatus === 'S' ? 'N' : customer.idStatus === 'N' && 'S',
        status:
          customer.idStatus === 'S'
            ? 'Ativo'
            : customer.idStatus === 'N' && 'Bloqueado',
      },
    });

    const response = await ClientesApi.prototype.setStatus(this.state.customer);

    if (response.type === 'SUCCESS') {
      this.setState({
        saving: false,
      });
    } else if (response.type === 'ERROR') {
      this.setState({
        customer: customer,
        message: response.message,
        messageType: response.messageType,
        isSnackbarOpen: true,
      });
    }
  };

  render() {
    const {
      loading,
      loadingOrders,
      isSnackbarOpen,
      message,
      messageType,
      customer,
      orders,
      payments,
      saving,
      loaded,
    } = this.state;

    const { classes } = this.props;

    return (
      <Fragment>
        <AppContextConsumer>
          {({ context }) => (
            <MyAppBar
              title={'Clientes'}
              appContext={context}
              Tabs={
                <ClienteTabs
                  tabvalue={this.state.tabvalue}
                  handleTabChange={this.handleTabChange}
                />
              }
            />
          )}
        </AppContextConsumer>
        {saving && <SearchLoading />}
        {(loading || loadingOrders) && <SimpleLoading />}
        {isSnackbarOpen && (
          <MySnackBar
            messageType={messageType}
            message={message}
            open={isSnackbarOpen}
          />
        )}
        {loaded && (
          <div className={classes.paper}>
            {this.state.tabvalue === 0 && (
              <Grid item xs={12}>
                <div className={classes.header}>
                  <Typography variant={'h5'}>{customer.name}</Typography>
                  <Typography variant={'caption'} display="block">
                    {customer.fantasy}
                  </Typography>
                  <Typography variant={'caption'} display="block">
                    <strong>{customer.status}</strong>
                  </Typography>
                  <div className={classes.headerButton}>
                    <Button
                      disabled={this.state.saving}
                      onClick={this.setCustomerStatus}
                      variant={'contained'}
                      color={'primary'}
                    >
                      {this.state.customer.idStatus === 'S'
                        ? 'Ativar'
                        : 'Bloquear'}
                    </Button>
                  </div>
                </div>
              </Grid>
            )}
            <div>
              {this.state.tabvalue === 0 && (
                <ClienteBasic customer={customer} />
              )}
              {this.state.tabvalue === 1 &&
                (this.state.orders.length > 0 ? (
                  <div className={classes.vendasTable}>
                    <VendasTable data={orders} />
                  </div>
                ) : (
                  <TableNoData />
                ))}
              {this.state.tabvalue === 2 &&
                (this.state.payments.length > 0 ? (
                  <div className={classes.vendasTable}>
                    <TitulosTable data={payments} />
                  </div>
                ) : (
                  <TableNoData />
                ))}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(Cliente);
