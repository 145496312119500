import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  container: {
    padding: '10px 5px',
    borderRadius: '4px',
    backgroundColor: fade(theme.palette.secondary.main, 0.7),
  },
});

class ClienteData extends Component {
  render() {
    const { data, classes } = this.props;
    return (
      <Grid item xs={12} md={6} lg={5} xl={4}>
        <div className={classes.container}>
          <Typography variant={'caption'}>
            {data.id}, {data.name}, {data.cnpj}, {data.address}, {data.city}
          </Typography>
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(ClienteData);
