import React, { Fragment } from 'react';
import { TablePagination } from '@material-ui/core';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { withRouter, Link } from 'react-router-dom';

const styles = theme => ({
  tableHeader: {
    backgroundColor: theme.palette.secondary.dark,
    padding: 15,
    borderRadius: '4px 4px 0 0 ',
  },
  tableHeaderText: {
    color: '#fff',
  },
  list: {
    paddingTop: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  listItemRazao: {
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '280px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  chipPrice: {
    marginTop: 10,
  },
  paper: {
    padding: '0px 10px',
  },
  N: {
    backgroundColor: '#6bc720',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
    fontSize: 12,
    marginLeft: 5,
  },
  S: {
    backgroundColor: '#ff8100',
    padding: 3,
    borderRadius: 4,
    color: '#fff',
    fontSize: 12,
    marginLeft: 5,
  },
  paginationActions: {
    margin: '0 7px',
  },
  labelFirstColunm: {
    width: 80,
    display: 'inline-block',
  },
  clienteState: {
    textAlign: 'left',
  },
  labelSecondColunm: {
    [theme.breakpoints.down('xs')]: {
      width: 230,
    },
  },
  typographyFlex: {
    display: 'flex',
  },
});

const situation = {
  N: 'Ativo',
  S: 'Bloqueado',
};

class ClientesTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { data } = this.props;
    const { page, rowsPerPage } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <List classes={{ root: classes.list }} component={'nav'}>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(item => {
              return (
                <ListItem
                  component={Link}
                  to={
                    process.env.REACT_APP_ROOTPATH +
                    'customers/view/' +
                    item.idCliente
                  }
                  button
                  className={classes.listItem}
                  item={item}
                  key={item.id}
                >
                  <Typography variant={'caption'}>
                    {item.idCliente} /{' '}
                    <strong className={classes[item.situacao]}>
                      {situation[item.situacao]}
                    </strong>
                  </Typography>
                  <Typography className={classes.listItemRazao} variant={'h6'}>
                    {item.razao}
                  </Typography>
                  <Typography
                    className={classes.typographyFlex}
                    variant="body2"
                    color="textSecondary"
                  >
                    <span className={classes.labelFirstColunm}>Fantasia:</span>
                    <span className={classes.labelSecondColunm}>
                      {item.fantasia}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.typographyFlex}
                  >
                    <span className={classes.labelFirstColunm}>Cidade:</span>
                    <span className={classes.labelSecondColunm}>
                      {item.cidade}
                    </span>
                  </Typography>
                  <Typography
                    className={classes.typographyFlex}
                    variant="body2"
                    color="textSecondary"
                  >
                    <span className={classes.labelFirstColunm}>Endereço:</span>
                    <span className={classes.labelSecondColunm}>
                      {item.endereco}, {item.numero && item.numero + ','}{' '}
                      {item.bairro}
                    </span>
                  </Typography>
                </ListItem>
              );
            })}
        </List>
        <TablePagination
          classes={{ actions: classes.paginationActions }}
          labelRowsPerPage={'Registros'}
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Página anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'Próxima Página',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(ClientesTable));
