import ApiRequest from 'services/ApiRequest';

class VendasApi extends ApiRequest {
  async get(param) {
    const result = await this.doGet('vendas', param);
    return result;
  }

  async getByCustomer(param) {
    const result = await this.doGet('vendas/getOrderListByCustomer', param);
    return result;
  }

  async getPrices(param) {
    const result = await this.doGet('vendas/getPrices', param);
    return result;
  }

  async getPrice(param) {
    const result = await this.doGet('vendas/getPrice', param);
    return result;
  }

  async checkDiscount(param) {
    const result = await this.doGet('vendas/checkDesconto', param);
    return result;
  }

  async checkDiscountPayment(param) {
    const result = await this.doGet('vendas/checkDiscountPayment', param);
    return result;
  }

  async send(param) {
    const result = await this.doPost('vendas/send', param);
    return result;
  }

  async load(param) {
    const result = await this.doGet('vendas/loadPedido', param);
    return result;
  }

  async mix(param) {
    const result = await this.doGet('vendas/mix', param);
    return result;
  }

  async searchProduct(param) {
    const result = await this.doGet('vendas/searchProduct', param);
    return result;
  }

  async weeklyClosing() {
    const result = await this.doGet('vendas/weeklyClosing');
    return result;
  }

  async sendWeeklyClosing(param) {
    const result = await this.doGet('vendas/sendWeeklyClosing', param);
    return result;
  }
}

export default VendasApi;
