import React from 'react';
import { Tab, Tabs } from '@material-ui/core';

export const OrderTabs = props => {
  const { tabvalue, handleTabChange } = props;
  return (
    <Tabs value={tabvalue} onChange={handleTabChange}>
      <Tab label="Cadastro" />
      <Tab label="Produtos" />
    </Tabs>
  );
};
