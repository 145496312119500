import React, { Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconCheck from '@material-ui/icons/Check';
import SimpleModal from 'components/template/modal/SimpleModal';

const styles = theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    marginBottom: 7,
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: '4px',
  },
  listItemProductInserted: {
    backgroundColor: theme.palette.secondary.main,
    display: 'block',
    marginBottom: 7,
    boxShadow: '1px 1px 1px 1px #eee',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  icon: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
});

class OrderMixModal extends React.Component {
  render() {
    const { mix, classes, handleProductSelectToOrder, onExited } = this.props;
    const { items } = this.props.order;

    return (
      <SimpleModal title="Produto do mix" handleModalState={onExited}>
        <List>
          {mix.map(item => {
            let productInserted = false;
            items.forEach(_item => {
              if (item.productId === _item.productId) {
                productInserted = true;
              }
            });
            return (
              <ListItem
                onClick={() => handleProductSelectToOrder(item)}
                className={
                  productInserted
                    ? classes.listItemProductInserted
                    : classes.listItem
                }
                button
                key={item.productId}
              >
                <Typography variant={'h6'}>
                  {item.productId} - {item.description}
                </Typography>
                {productInserted ? (
                  <Fragment>
                    <IconCheck className={classes.icon} />
                    <Typography variant={'caption'} display="block">
                      <strong>Produto no pedido</strong>
                    </Typography>
                  </Fragment>
                ) : (
                  <div>
                    <Typography variant={'caption'} display="block">
                      Maior quantidade: {item.quantity}
                      {item.unit}
                    </Typography>
                    <Typography variant={'caption'} display="block">
                      Última compra em <strong>{item.lastDate}</strong>
                    </Typography>
                  </div>
                )}
              </ListItem>
            );
          })}
        </List>
      </SimpleModal>
    );
  }
}

export default withStyles(styles)(OrderMixModal);
