import React, { Fragment, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import IconCheck from '@material-ui/icons/Check';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export const OrderAction = props => {
  const {
    order,
    handleSubmit,
    handleDraftDelete,
    listItemSelected,
    handleItemDelete,
    handleItemChange,
    handleListItemSelectAll,
    handleShowTotal,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const draftDelete = () => {
    handleDraftDelete();
    setAnchorEl(null);
  };

  const selectAll = () => {
    handleListItemSelectAll();
    setAnchorEl(null);
  };

  const showTotal = () => {
    handleShowTotal();
    setAnchorEl(null);
  };

  let indexSelected = null,
    quantity = 0;

  order.items.map((item, index) => {
    if (item.selected) {
      indexSelected = index;
      quantity++;
    }
    return item;
  });

  return (
    <div>
      {order.items.length > 0 && !listItemSelected && (
        <Tooltip title={'Enviar pedido'}>
          <IconButton color={'inherit'} onClick={handleSubmit}>
            <IconCheck />
          </IconButton>
        </Tooltip>
      )}
      {listItemSelected && (
        <Fragment>
          {quantity === 1 && (
            <Tooltip title={'Editar'}>
              <IconButton
                color={'inherit'}
                onClick={() => handleItemChange(indexSelected)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={'Excluir selecionados'}>
            <IconButton color={'inherit'} onClick={handleItemDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Fragment>
      )}
      <Menu
        onClose={() => setAnchorEl(null)}
        id="menu-order-action"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={showTotal}>Ver total</MenuItem>
        {localStorage.getItem('order') && !listItemSelected && (
          <MenuItem onClick={draftDelete}>Excluir rascunho</MenuItem>
        )}
        {listItemSelected && (
          <MenuItem onClick={selectAll}>Selecionar todos</MenuItem>
        )}
      </Menu>
      <IconButton
        color={'inherit'}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <MoreIcon />
      </IconButton>
    </div>
  );
};
