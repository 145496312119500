/**
 * Date format with locale options
 * @param {Date} date
 * @param {Object} options
 * @param {string} locale
 * @returns {string}
 */
function dateFormatWithOptions(
  date,
  options = { year: 'numeric', month: '2-digit', day: '2-digit' },
  locale = 'pt-BR'
) {
  if (date) return date.toLocaleDateString(locale, options);
  else return '';
}

/**
 * Basic date format
 * @param {Date} date
 * @param {string} locale
 */
function dateFormat(date, locale = 'pt-BR') {
  if (date) return date.toLocaleDateString(locale);
  else return '';
}

export { dateFormat, dateFormatWithOptions };
