import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  backgroundAnimated1: {
    padding: 6,
    width: 150,
    marginBottom: 4,
    borderRadius: 2,
  },
  backgroundAnimated2: {
    padding: 14,
    width: 250,
    marginBottom: 7,
    borderRadius: 2,
    marginTop: 10,
  },
  backgroundAnimated3: {
    padding: 16,
    marginBottom: 10,
    width: 50,
    borderRadius: 16,
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 1px 1px #eee',
    borderRadius: '4px',
  },
  list: {
    paddingTop: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '6px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
});

class VendasTableLoading extends React.Component {
  render() {
    const { classes } = this.props;
    let cont;
    let content = [];

    for (cont = 0; cont < 9; cont++) {
      content[cont] = (
        <ListItem key={cont} className={classes.listItem}>
          <div
            className={`animated-background ${classes.backgroundAnimated1}`}
          />
          <div
            className={`animated-background ${classes.backgroundAnimated2}`}
          />
          <div
            className={`animated-background ${classes.backgroundAnimated1}`}
          />
          <div
            className={`animated-background ${classes.backgroundAnimated1}`}
          />
          <div
            className={`animated-background ${classes.backgroundAnimated1}`}
          />
        </ListItem>
      );
    }

    return (
      <List classes={{ root: classes.list }} component={'nav'}>
        {content}
      </List>
    );
  }
}

export default withStyles(styles)(VendasTableLoading);
