import jwt from 'jsonwebtoken';

function isAuth() {
  let verifyToken;

  if (localStorage.getItem('token')) {
    try {
      jwt.verify(localStorage.getItem('token'), process.env.REACT_APP_SECRET, {
        ignoreNotBefore: true,
      });
      verifyToken = true;
    } catch (e) {
      verifyToken = false;
    }
  } else verifyToken = false;

  return verifyToken;
}

function getLevelPermission() {
  let datatoken = localStorage.getItem('token');
  if (datatoken) {
    try {
      datatoken = jwt.decode(localStorage.getItem('token'));
      return datatoken.data.levelPermission;
    } catch (e) {
      console.log(e);
    }
  }
}

export { isAuth, getLevelPermission };
