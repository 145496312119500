import ApiRequest from 'services/ApiRequest';

class EmpresaApi extends ApiRequest {
  async load(param) {
    const result = await this.doGet('empresa/load', param);
    return result;
  }
}

export default EmpresaApi;
