import React, { Fragment } from 'react';
import { AppContextConsumer } from 'App';
import MyAppBar from 'components/template/appbar/MyAppBar';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Username from 'components/user/Username';
import PushNotificationApi from '../../services/PushNotificationApi';
import SearchLoading from '../loading/SearchLoading';
import MySnackBar from 'components/messages/MySnackBar';

const styles = theme => ({
  container: {
    padding: '10px 30px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  form: {
    width: '100%',
  },
  buttonContainer: {
    marginTop: 40,
  },
});

class Messaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      isSnackbarOpen: false,
      loading: false,
      messageBody: '',
      message: '',
      messageType: '',
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCloseSnackbar = () => {
    this.setState({
      isSnackbarOpen: false,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({
      loading: true,
      isSnackbarOpen: false,
    });

    const param = this.state;

    const response = await PushNotificationApi.prototype.send(param);

    if (response.type === 'SUCCESS') {
      this.setState({
        loading: false,
        message: 'Mensagem enviada',
        messageType: response.type,
      });
    } else if (response.type === 'ERROR') {
      this.setState({
        loading: false,
        message: response.message,
        messageType: response.type,
        isSnackbarOpen: true,
      });
    }
  };

  renderComponentsAppbar = () => {
    return (
      <Fragment>
        {!this.state.loading && (this.state.messageBody && this.state.title) && (
          <IconButton onClick={this.handleSubmit} color={'inherit'}>
            <DoneIcon />
          </IconButton>
        )}
      </Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <AppContextConsumer>
          {({ handleOpenMenu }) => (
            <MyAppBar
              title={'Mensagens'}
              handleOpenMenu={handleOpenMenu}
              actionsComponents={this.renderComponentsAppbar}
            />
          )}
        </AppContextConsumer>
        {this.state.isSnackbarOpen && (
          <MySnackBar
            handleClose={this.handleCloseSnackbar}
            open={this.state.isSnackbarOpen}
            message={this.state.message}
            variant={this.state.messageType}
          />
        )}
        {this.state.loading && <SearchLoading />}
        <Grid container spacing={0} className={classes.container}>
          <form onSubmit={this.handleSubmit} className={classes.form}>
            <Grid item xs={12}>
              <Typography variant={'h6'}>
                Olá{' '}
                <strong>
                  <Username />
                </strong>
                .
              </Typography>
              <Typography variant={'caption'}>
                Envie notificações push para os aplicativos registrados.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} md={6} lg={5} xl={4}>
                <TextField
                  variant={'standard'}
                  autoFocus
                  placeholder={'Digite o título da mensagem'}
                  required
                  label={'Título'}
                  margin={'normal'}
                  name={'title'}
                  onChange={this.handleChange}
                  value={this.state.title}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} md={6} lg={5} xl={4}>
                <TextField
                  placeholder={'Digite sua mensagem'}
                  required
                  label="Mensagem"
                  multiline
                  rows="4"
                  margin="normal"
                  variant="standard"
                  name={'messageBody'}
                  value={this.state.messageBody}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                disabled={
                  this.state.loading ||
                  (this.state.messageBody === '' || this.state.title === '')
                }
                variant={'contained'}
                color={'primary'}
                type={'submit'}
              >
                <DoneIcon />
                Enviar
              </Button>
            </Grid>
          </form>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Messaging);
