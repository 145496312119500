import React, { useState, useEffect } from 'react';
import FormasPagamentoApi from 'services/FormasPagamentoApi';
import { TextField, MenuItem } from '@material-ui/core';
import * as PropTypes from 'prop-types';

export const FormasPagamento = React.memo(props => {
  const [data, setData] = useState([]);
  const { change, idFormaPagamento, disabled } = props;

  /*const {updateOrderState} = useContext(ViewOrderBasicContext);*/

  useEffect(() => {
    FormasPagamentoApi.prototype.get().then(result => {
      if (result.type === 'SUCCESS') {
        setData(result.formapagamento);
      }
    });
  }, []);

  return (
    <TextField
      select
      label="Forma de Pagamento"
      required
      disabled={disabled}
      value={data.length > 0 ? idFormaPagamento : ''}
      onChange={change ? e => change(e) : null}
      margin="normal"
      variant="standard"
      fullWidth={true}
    >
      {data.map(item => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.id} - {item.descricao}
          </MenuItem>
        );
      })}
    </TextField>
  );
});

FormasPagamento.propTypes = {
  change: PropTypes.func,
  idFormaPagamento: PropTypes.string,
  updateParentState: PropTypes.func,
};
