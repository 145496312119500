import React, { PureComponent } from 'react';
import UserDataToken from 'helpers/UserDataToken';

const capitalize = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

class Username extends PureComponent {
  render() {
    const userDataToken = new UserDataToken();
    return <span>{capitalize(userDataToken.getUsername().toLowerCase())}</span>;
  }
}

export default Username;
